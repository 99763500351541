import React from 'react';

import {
  Box,
  Container,
  Typography,
} from '@mui/material';

import Hire from '../../../Component/Image/Hire.svg';
import { ExpectData } from '../../../utils/Constent/Career/CareerConstent';
import Typographyview from '../../CommonSectionweb/Typographyview';
import CanExpect from '../../Image/CanExpect.png';

const Expect = () => {
  return (
    <Container sx={{maxWidth:{xs:"xs",sm:"md",md:"md",lg:"lg",xl:"xl"}}}>
    <Box
      id="WhatYouCanExpect@Equitysoft"
      sx={{ marginTop: { xs: "4rem", sm: "5rem" } }}
    >
      <Box>
        <Typographyview
          value=" What You Can Expect @ Equitysoft:"
          subtitle="Take up the pleasures of a company that continually works, making you a better individual."
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Box>
            {ExpectData.map((n,i) => {
              return (
                <Box key={i}>
                  <Equitysoftview {...n} />
                </Box>
              );
            })}
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            <img src={CanExpect} alt="" width="100%" />
          </Box>
        </Box>
      </Box>
    </Box>
    </Container>
  );
};

export default Expect;

function Equitysoftview({ value, subtitle }) {
  return (
    <Box
      sx={{
        marginBottom: "1rem",
        display: "flex",
        pr: { md: 1, lg: 2, xl: 0 },
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Box sx={{}}>
          <img src={Hire} alt="" width="20px" />
        </Box>
        <Box sx={{ paddingLeft: "0.5rem" }}>
          <Typography
            variant="h2"
            color="#252525"
            fontWeight="700"
            sx={{
              paddingBottom: "1rem",
            }}
          >
            {value}
          </Typography>
          <Typography
            variant="body2"
            color="#484848"
            fontWeight="400"
            sx={{
              width: { xs: "100%", md: "95%" },
            }}
          >
            {subtitle}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
