import { Box, Typography, Button, Container } from "@mui/material";
import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import BANNER_GIF from "../../Image/bannerVideo.mp4";
import BANNER_SHAPE_STAR from "../../Image/banner_shpas_star.png";
import BANNER_SHAPE_ROUND from "../../Image/banner_box_rounded.png";
import BANNER_SHAPE_ROUND1 from "../../Image/banner_shape_small.png";
const HomeScreen = ({ url }) => {
  const textRef = useRef(null);
  const navigator = () => {
    window.open(url);
  };
  useEffect(() => {
    const animateText = () => {
      if (textRef.current) {
        const lines = ["We Shape Your ", "Dreams Into Reality"];

        const charElements = lines.map((line, lineIndex) => {
          const words = line.split(" ");
          const wordElements = words.map((word, wordIndex) => {
            const characters = word.split("");
            const charColors = characters
              .map((char, index) => {
                let color;
                if (
                  (lineIndex === 0 &&
                    wordIndex === 0 &&
                    index >= 0 &&
                    index <= 1) ||
                  (lineIndex === 1 &&
                    wordIndex === 1 &&
                    index >= 0 &&
                    index <= 3) || // "Your"
                  (lineIndex === 2 &&
                    wordIndex === 2 &&
                    index >= 0 &&
                    index <= 3)
                ) {
                  // "Into"
                  color = "#000";
                } else if (
                  (lineIndex === 0 &&
                    wordIndex === 2 &&
                    index >= 0 &&
                    index <= 5) || // "color"
                  (lineIndex === 1 &&
                    wordIndex === 3 &&
                    index >= 0 &&
                    index <= 5) || // "is"
                  (lineIndex === 2 &&
                    wordIndex === 5 &&
                    index >= 0 &&
                    index <= 6)
                ) {
                  // "Shape"
                  color = "#000";
                } else {
                  color = "#338DB0"; // Default color
                }
                const animationDelay = char === " " ? 0 : index * 0.06;
                const charClass =
                  char === " " ? "char-space" : `char char-${index}`;
                return `<span class="${charClass}" style="animation-delay: ${animationDelay}s; color: ${color};">${char}</span>`;
              })
              .join(""); // Join the characters into a single string
            return `<span class="word">${charColors}</span>`; // Wrap the word in a span with class "word"
          });
          return wordElements.join("<span class='word-space'>&nbsp;</span>"); // Join the word elements with word spacing
        });

        textRef.current.innerHTML = charElements.join("<br>");

        // Apply the bouncing animation to each character
        const chars = textRef.current.querySelectorAll(".char");
        chars.forEach((char, index) => {
          char.style.animation = `charBounce 0.5s ease-in-out ${
            index * 0.06
          }s forwards`;
        });

        // Fade out animation
        gsap.to(textRef.current, {
          opacity: 0,
          duration: 1.5,
          delay: 3,
          zIndex: 3,
          onComplete: () => {
            setTimeout(() => {
              gsap.set(textRef.current, { opacity: 1 });
              animateText();
            }, 1000);
          },
        });
      }
    };

    animateText();

    return () => {};
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: `calc(100vh - 80px)`,
        backgroundColor: "#E6F8FB",
        mt: "80px",
        pb: "1rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Box
        sx={{
          width: "70px",
          height: "70px",
          position: "absolute",
          left: "10px",
          bottom: "50px",
          zIndex: "1",
        }}
      >
        <img
          src={BANNER_SHAPE_STAR}
          alt="BANNER_SHAPE_STAR-1"
          className="BANNER_SHAPE_STAR"
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </Box>
      <Box
        className="BANNER_SHAPS_LEFT_BOUNCE"
        sx={{
          width: "80px",
          height: "158px",
          position: "absolute",
          left: "0px",
          top: "50px",
          zIndex: "2",
        }}
      >
        <img
          src={
            window.innerWidth > 768 ? BANNER_SHAPE_ROUND : BANNER_SHAPE_ROUND1
          }
          alt="BANNER_SHAPE_ROUND"
          className="BANNER_SHAPE_ROUND"
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </Box>
      <Container
        sx={{
          maxWidth: { xs: "xs", sm: "md", md: "md", lg: "lg", xl: "xl" },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            paddingTop: "7rem",
            p: { xs: "0px", md: "0px", lg: "1rem" },
            gap: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              flexDirection: "column",
              width: { xs: "100%", md: "100%", lg: "55%" },
              flexWrap: "wrap",
              paddingTop: { xs: "3rem", md: 0 },
            }}
          >
            {/* <Typography
              sx={{
                color: "#000000",
                fontSize: "65px !important",
                fontWeight: "700",
                // paddingTop:'12px'
              }}
            >
              
            </Typography> */}
            <h1 className="bannerTopText">
              Mobile App & Web Development Company
            </h1>
            {/* <h1 className="bannerTopText">
              Leading digital transformation agency to help you lead in this
              digital age
            </h1> */}
            <Typography
              ref={textRef}
              sx={{
                padding: "5px 0px",
              }}
            ></Typography>

            <Typography
              sx={{
                color: "#000000",
                fontWeight: "600",
                fontSize: { sm: "20px", lg: "22px", xl: "20px" },

                paddingTop: "12px",
              }}
            >
              Your ideas, our innovation, a journey to success together.
            </Typography>
            <Button
              onClick={() => navigator((url = "portfolio"))}
              sx={{
                color: "#fff",
                backgroundColor: "#295768",
                backdropFilter: "blur(25px)",
                borderRadius: "50px",
                border: "1px solid #295768",
                textTransform: "none",
                fontWeight: "600",
                fontSize: { xs: "12px", sm: "15px" },
                px: { xs: 3, sm: 4.5 },
                py: { xs: 1, sm: 1 },

                "&:hover": {
                  border: "1px solid #295768",
                  backdropFilter: "blur(25px)",
                  backgroundColor: "#295768",
                },
                margin: {
                  xs: "15px 0px 0px 0px",
                  lg: "25px 0px 0px 0px",
                  xl: "30px 0px 0px 0px",
                },
              }}
            >
              See Portfolio
            </Button>
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "100%", lg: "45%" },
              p: "1rem",
            }}
          >
            <video
              autoPlay
              muted
              loop
              style={{
                width: "100%",
                height: "100%",
                // aspectRatio: "570 / 570",
                aspectRatio: "1/1",
                objectFit: "contain",
              }}
            >
              <source src={BANNER_GIF} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default HomeScreen;
