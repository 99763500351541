import { Box } from "@mui/material";
import Ui from "../../../Image/Uidesign.png";
import Swift from "../../../Image/Swift.png";
import Apple1 from "../../../Image/Apple1.png";
import React from "../../../Image/React.png";
import ChildBanner from "../../CommonSection/ChildBanner";

const IOS = () => {
  return (
    <Box>
    <ChildBanner
      value={"Apple Device Application"}
      title={"IOS Development "}
      subtitle={
        "It is an Operating System for Apple's mobile named iPhone and is utilized solely for Apple's hardware. Custom iPhone app development offers numerous benefits, such as cost-effectiveness, functionality, impressive UI/UX, user-friendliness, efficiency, and optimization. For your customized iOS application development, consider hiring iOS developers from Equitysoft. "
      }
      subtitle1={
        "Opting for iOS has several benefits, including a more significant profit margin, reduced app development costs resulting in a more robust return on investment, a more seamless and engaging user experience, high-end security, a low rate of fragmentation, and easier app testing due to fewer versions of the app."
      }
      media={Apple1}
      media1={Swift}
      media2={React}
      image={Ui}
      none2={"none"}
      i={"row-reverse"}
      none={"none"}
      ex={{pl:{xs:"0",md:4,xl:6}}}
      width="150%"
      aos={"fade-down-right"}
    />
    </Box>
  );
};

export default IOS;

