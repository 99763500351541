import React, { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { Group, HoverCard, Text } from "@mantine/core";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import {
  AppBar,
  Box,
  Button,
  Container,
  Drawer,
  Typography,
} from "@mui/material";

import logo from "../../../Component/Image/Logo.png";
import { NavbarData } from "../../../utils/Constent/Home/HomeConstent";
import NavbarDrawer from "./NavbarDrawer";

const Navbar = ({ url }) => {
  const navigator = useNavigate("");
  const navigator1 = () => {
    window.open(url);
  };

  const [state, setState] = useState(false);

  const handleDrawerOpen = () => {
    setState(true);
  };

  const handleDrawerClose = () => {
    console.log("call");
    setState(false);
  };
  const location = useLocation();
  return (
    <AppBar sx={{
      boxShadow: "none",
    }}>
      <Box sx={{ background: "#E0F6F9" }}>
        <Container
          sx={{
            maxWidth: { xs: "xs", sm: "md", md: "md", lg: "lg", xl: "xl" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              py: 1.5,
            }}
          >
            <Box
              sx={{ cursor: "pointer", width: { xs: "190px", lg: "210px" } }}
            >
              <img
                src={logo}
                alt=""
                width="100%"
                onClick={() => navigator("/")}
              />
            </Box>
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              {NavbarData.map((n, i) => {
                return (
                  <Box key={i}>
                    <Typographyview {...n} />
                  </Box>
                );
              })}
            </Box>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
              }}
            >
              <Button
                onClick={() => navigator1((url = "/contact-us"))}
                sx={{
                  border: "1px solid #295768",
                  backgroundColor: "#295768",
                  borderRadius: "50px",
                  px: { xs: 2, lg: 3 },
                  textTransform: "none",
                  "&:hover": {
                    color: "#0E3347",
                    border: "1px solid #0E3347",
                  },
                }}
              >
                Get a Quote
              </Button>
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" }, py: 1 }}>
              <Box>
                <MenuOpenIcon fontSize="large" onClick={handleDrawerOpen} />
              </Box>
              <Drawer
                onClose={() => setState(false)}
                // variant="persistent"
                anchor={"right"}
                open={state}
                PaperProps={{
                  sx: {
                    backgroundColor: "#E0F6F9",
                    width: { xs: "250px", sm: "280px" },
                    display: "flex",
                    alignItems: "center",
                  },
                }}
              >
                <Box onMouseLeave={() => handleDrawerClose()}>
                  <NavbarDrawer setState={setState} />
                </Box>
              </Drawer>
            </Box>
          </Box>
        </Container>
      </Box>
    </AppBar>
  );
};

export default Navbar;

function Typographyview(props) {
  const {
    value,
    url1,
    navigate,
    data,
    none,
    image,
    border,
    none1,
    backgroundSize,
  } = props;
  const navigator = useNavigate("");
  const location = useLocation();
  const [url, setUrl] = useState("");
  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);

  const [Hoveropen, setHoveropen] = useState(true);
  return (
    <Box>
      <Box sx={{ px: { xs: "0", md: " 0.2rem", lg: "0.6rem" } }}>
        <Group onMouseEnter={() => setHoveropen(true)}>
          <HoverCard
            offset={0}
            onClose={() => setHoveropen(true)}
            closeDelay={0}
          >
            <HoverCard.Target>
              <Box>
                <Typography
                  className="containerbox"
                  variant="body1"
                  color="#252525"
                  fontWeight="700"
                  sx={{
                    cursor: "pointer",
                    py: 2,
                    ":hover": {
                      color:
                        url === "/" && url1 === "home"
                          ? "#20B69F"
                          : url.includes(url1)
                          ? "#20B69F"
                          : "#20B69F",
                    },
                    color:
                      url === "/" && url1 === "home"
                        ? "#20B69F"
                        : url.includes(url1)
                        ? "#20B69F"
                        : "#252525",
                    textDecoration: "none",
                  }}
                  onClick={() => navigator(navigate)}
                  to="/"
                >
                  {" "}
                  {value}
                </Typography>
              </Box>
            </HoverCard.Target>
            <Box>
              {Hoveropen && (
                <HoverCard.Dropdown
                  sx={{
                    backgroundImage: "url(" + image + ")",
                    backgroundPosition: "bottom right",
                    backgroundRepeat: "no-repeat",
                    display: none,
                    backgroundSize: backgroundSize,
                    objectFit: "cover",
                    fontWeight: "600",
                  }}
                >
                  <Box>
                    {data?.map((i, e) => (
                      <Box key={e}>
                        <MenuView
                          navigate={navigate}
                          {...i}
                          border={border}
                          none1={none1}
                          setHoveropen={setHoveropen}
                        />
                      </Box>
                    ))}
                  </Box>
                </HoverCard.Dropdown>
              )}
            </Box>
          </HoverCard>
        </Group>
      </Box>
    </Box>
  );
}

function MenuView({ icon, subtitle, href, navigate, setHoveropen }) {
  const navigator = useNavigate("");
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          py: 0.5,
        }}
      >
        <Box sx={{ pr: 1.5 }}>
          <img src={icon} alt="" width="35px" style={{ borderRadius: "5px" }} />
        </Box>
        <Text size="sm">
          <Typography
            onClick={() => {
              //alert(href)
              setHoveropen(false);
              if (href) {
                navigator(navigate + `/${href}`);
                setTimeout(() => {
                  var my_element = document.getElementById(
                    href.replace("#", "")
                  );

                  if (my_element) {
                    my_element.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                      inline: "nearest",
                    });
                  }
                }, 200);
              } else {
                navigator(navigate);
              }
            }}
            // component="a"
            // href={href}
            sx={{
              textDecoration: "none",
              textTransform: "capitalize",
              "&:hover": {
                color: "#20B69F",
              },
            }}
            variant="body2"
            color="initial"
            fontWeight="700"
          >
            {subtitle}
          </Typography>
        </Text>
      </Box>
    </Box>
  );
}
