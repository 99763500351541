import { Box } from "@mui/material";
import ChildBanner from "../../CommonSection/ChildBanner";
import Linkedin1 from "../../../Image/Linkedin1.png";
import Snapchat from "../../../Image/Snapchat.png";
import Twitter from "../../../Image/Twitter.png";
import Meta1 from "../../../Image/Meta1.png";
import Social from "../../../Image/Social.png";

const Socialmedia = () => {
  return (
    <Box>
      <ChildBanner
        value={"Meta Business, LinkedIn, Snapchat "}
        title={"Social media Marketing"}
        subtitle={
          "Are you looking to grow your business and reach a wider audience? Social media marketing can help! With billions of active users on platforms like Facebook, Instagram, Twitter, and LinkedIn, social media has become an essential tool for businesses to connect with their target audience."
        }
        subtitle1={
          "Our social media marketing services are designed to help you create a strong online presence, increase brand awareness, and drive traffic to your website. We'll work with you to develop a customized social media strategy that aligns with your business goals and target audience."
        }
        media={Meta1}
        media1={Linkedin1}
        media2={Snapchat}
        media3={Twitter}
        image={Social}
        i={"row"}
        ex={{pr:{xs:"0",md:4,xl:6}}}
        aos={"fade-down-left"}
        none2={"none"}
      />
    </Box>
  );
};

export default Socialmedia;
