import { Box} from "@mui/material";
import Androidbanner from "../../../Image/Android1.png";
import Android1 from "../../../Image/Android.png";
import Kotlin from "../../../Image/Kotlin.png";
import Java from "../../../Image/Java.png";
import React from "../../../Image/React.png";
import ChildBanner from "../../CommonSection/ChildBanner";

const Android = () => {
  return (
    <Box>
      <ChildBanner
        value={"Android Mobile Application"}
        title={"Android Development"}
        subtitle={
          "Transform all of your Android app concepts into functional applications. Android is not merely an operating system, but it also consists of middleware and several key applications. It features a vast array of APIs, and it is open source. Android is based on the Linux Kernel."
        }
        subtitle1={
          "Choosing Android has several advantages, including the ability to customize the platform to your needs, its flexibility enabling you to design for different devices regardless of size, resolution, and form factor, its scalability and cost-effectiveness, faster development cycles leading to quicker time-to-market, and the capability to target multiple platforms."
        }
        media={Android1}
        media1={React}
        media2={Kotlin}
        media3={Java}
        none2={"none"}
        image={Androidbanner}
        i={"row"}
        width="150%"
      ex={{pr:{xs:"0",md:4,xl:6}}}
      aos={"fade-down-left"}

      />
    </Box>
  );
};

export default Android;

