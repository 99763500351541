import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import Contact from '../../Service/ServiceScreen/Contact'
import YourProject from '../Screen/AppPage/YourProject'
import OurProcess from '../Screen/UIUXPage/OurProcess'
import BackEnd from '../Screen/WebPage/BackEnd'
import FrontEnd from '../Screen/WebPage/FrontEnd'
import OurWorkWeb from '../Screen/WebPage/OurWorkWeb'
import WebDevelopment from '../Screen/WebPage/WebDevelopment'
import ReactVuejs from '../Screen/WebPage/ReactVuejs'
import Wordpress from '../Screen/WebPage/Wordpress'
import Laravel from '../Screen/WebPage/Laravel'
import Webdesigning from '../Screen/WebPage/Webdesigning'
import Pythonweb from '../Screen/WebPage/Pythonweb'
import NodeNestExpressjs from '../Screen/WebPage/NodeNestExpressjs'

const WebPage = () => {
  useEffect(() => window.document.scrollingElement?.scrollTo(0, 0), [])
  return (
    <Box>
        <WebDevelopment/>
        <YourProject/>
        <FrontEnd/>
        <BackEnd/>
        <ReactVuejs/>
        <Wordpress/>
        <Laravel/>
        <Webdesigning/>

        <NodeNestExpressjs/>
        <Pythonweb/>

        <OurWorkWeb/>
        <OurProcess/>
        <Contact/>
    </Box>
  )
}

export default WebPage