import React, { useEffect } from "react";
import HomeScreen from "../Home/Screen/HomeScreen";
import Achievement from "../Home/Screen/Achievement";
import Blog from "../Home/Screen/Blog.jsx";
import Counter from "../Home/Screen/Counter";
import OurClients from "../Home/Screen/OurClients";
import Ourservice from "../Home/Screen/Ourservice";
import Portfolio from "../Home/Screen/Portfolio";
import Testimonials from "../Home/Screen/Testimonials";
import WhyChoose from "../Home/Screen/WhyChoose";
import { Box } from "@mui/material";
const Home = () => {
  useEffect(() => window.document.scrollingElement?.scrollTo(0, 0), []);

  return (
    <Box>
      <HomeScreen />
      <Ourservice />
      <Portfolio />
      <OurClients />
      <WhyChoose />
      <Testimonials />
      <Counter />
      <Achievement />
      <Blog />
    </Box>
  );
};

export default Home;
