
import { Box} from "@mui/material";
import FrontEnd1 from "../../../Image/FrontEnd.png";
import Next from "../../../Image/Next.png";
import Word from "../../../Image/Word.png";
import Angular1 from "../../../Image/Angular1.png";
import React from "../../../Image/React.png";
import ChildBanner from "../../CommonSection/ChildBanner";

const FrontEnd = () => {
  return (
    <Box>
      <ChildBanner
        value={"Web Development"}
        title={"Front-End Development "}
        subtitle={
          "We create and design the visual components of a website or software application, which are directly accessible and interactive for users. We build the UI and create a smooth and engaging user experience in the front-end development."
        }
        subtitle1={
          "Front-end development involves using HTML, CSS, and JavaScript to create a website's visual elements and user interactions, including navigation menus, forms, and other user interface elements."
        }
        image={FrontEnd1}
        media={React}
        media1={Next}
        media2={Angular1}
        media3={Word}
        none2={"none"}
        ex={{pr:{xs:"0",md:4,xl:6}}}
        aos={"fade-down-left"}
        i={"row"}
      />
    </Box>
  )
}

export default FrontEnd

