// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import React from 'react';

import {
  Autoplay,
  Navigation,
  Pagination,
} from 'swiper';
import {
  Swiper,
  SwiperSlide,
} from 'swiper/react';

import {
  Box,
  Typography,
} from '@mui/material';

import { WhyjoinData } from '../../../utils/Constent/Career/CareerConstent';
import Typographyview from '../../CommonSectionweb/Typographyview';

const Whyjoin = () => {
  return (
    <Box
    id="WhyJoinEquitysoft"
      sx={{
        backgroundColor: "#E0F6F9",
        marginTop: { xs: "4rem", sm: "5rem" },
      }}
    >
      <Box sx={{ py: 7 }}>

        <Box>
          <Typographyview value="Why Join Equitysoft? " subtitle="To experience constant success and growth for your outstanding talents in the company of like-minded people." />
        </Box>

        <Box sx={{ width: "100%",}}>
          <Swiper

            breakpoints={{
              320: { slidesPerView: 1, spaceBetween: 80 },
              375: { slidesPerView: 1.5, spaceBetween: 20 },
              500: { slidesPerView: 2, spaceBetween: 30 },
              600: { slidesPerView: 2.2, spaceBetween: 30 },
              700: { slidesPerView: 2.5, spaceBetween: 30 },
              900: { slidesPerView: 3.5, spaceBetween: 20 },
              1100: { slidesPerView: 4, spaceBetween: 20 },
              1300: { slidesPerView: 4.5, spaceBetween: 20 },
              1400: { slidesPerView: 5, spaceBetween: 15 },
              1440: { slidesPerView: 5.5, spaceBetween: 15 },
              1700: { slidesPerView: 6, spaceBetween: 20 },
            }}

            slidesPerView={0}
            // showspagination={false}
            spaceBetween={0}
            speed={400}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            
            navigation={true}
            modules={[
              Autoplay,
              Pagination,
              Navigation]}
            
            className="mySwiper"
          >
            {WhyjoinData.map((n,e) => {
              return (
                <SwiperSlide key={e}>
                  <Whyjoinview
                  {...n}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Box>
      </Box>
    </Box>
  );
};

export default Whyjoin;

function Whyjoinview({ media, value, subtitle, textcolor, rem, fadedown }) {
  return (
   
      <Box
        sx={{
          backgroundColor: "#fff",
          width: 250,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          py: 2,
          mt: rem,
        }}
      >
        <Box>
          <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
            <img src={media} alt="" width="110px" />
          </Box>
          <Box sx={{ color: textcolor }}>
            <Typography
              variant="body1"
              sx={{ textAlign: "center", fontWeight: "600" }}
            >
              {value}
            </Typography>
            <Typography
              variant="body1"
              sx={{ textAlign: "center", fontWeight: "600" }}
            >
              {subtitle}
            </Typography>
          </Box>
        </Box>
      </Box>
    
  );
}
