import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Typography,
} from "@mui/material";

import { NavbarDropData } from "../../../utils/Constent/Home/HomeConstent";
import Logo2 from "../../Image/Logo.png";

const NavbarDrawer = ({ setState, navigate }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const navigator = useNavigate();
  return (
    <Box
      palette={0}
      sx={{
        py: 2,
      }}
    >
      <Box
        sx={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <img src={Logo2} alt="" width="200px" />
      </Box>

      <Box palette={0} sx={{ py: 3 }}>
        {NavbarDropData.map((n, i) => {
          return (
            <Box key={i}>
              <Accordionview
                {...n}
                setState={setState}
                handleChange={handleChange}
                expanded={expanded}
              />
            </Box>
          );
        })}
      </Box>
      <Box
        sx={{
          mt: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          onClick={() => {
            setState(false);
            navigator("/contact-us");
          }}
          sx={{
            backgroundColor: "#295768",
            borderRadius: "50px",
            px: 7,
            py: 1.3,
            fontSize: "1rem",
            textTransform: "none",
            "&:hover": {
              color: "#0E3347",
              border: "1px solid #0E3347",
            },
          }}
        >
          Get a Quote
        </Button>
      </Box>
    </Box>
  );
};

export default NavbarDrawer;

function Accordionview({
  value,
  none,
  none1,
  navigate,
  setState,
  panel,
  expanded,
  handleChange,
  data,
}) {
  const navigator = useNavigate();

  return (
    <Box>
      <Accordion
        expanded={expanded === panel}
        onChange={handleChange(panel)}
        sx={{ backgroundColor: "transparent", boxShadow: "0", display: none }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ px: 1 }}>
            <Typography
              variant="h6"
              color="#0E3347"
              fontWeight="700"
              onClick={() => {
                setState(false);
                navigator(navigate);
              }}
            >
              {value}
            </Typography>
          </Box>

          <AccordionSummary
            sx={{
              height: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
            expandIcon={
              <Box sx={{ pt: 1, pointerEvents: "auto" }}>
                {" "}
                <ExpandMore
                  sx={{
                    pointerEvents: "auto",
                  }}
                />
              </Box>
            }
          ></AccordionSummary>
        </Box>

        <AccordionDetails
          sx={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Box>
              {data?.map((i, e) => (
                <Box key={e}>
                  <Menuview {...i} setState={setState} navigate={navigate} />
                </Box>
              ))}
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Box
        display={none1}
        sx={{
          px: 1,
          py: 0.5,
        }}
      >
        <Typography
          variant="h6"
          color="#0E3347"
          fontWeight="700"
          onClick={() => {
            setState(false);
            navigator(navigate);
          }}
        >
          {value}
        </Typography>
      </Box>

      <Divider sx={{ pt: 1 }} />
    </Box>
  );
}

function Menuview({ subtitle, href, navigate, setState, navigat }) {
  const navigator = useNavigate("");
  return (
    <Box sx={{ py: 0.7, width: "100%", textAlign: "center" }}>
      <Box sx={{ background: "#fff", py: 1, px: 1 }}>
        <Typography
          onClick={() => {
            if (href) {
              setState(false);
              navigator(navigate + `/${href}`);
              setTimeout(() => {
                var my_element = document.getElementById(href.replace("#", ""));

                if (my_element) {
                  my_element.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "nearest",
                  });
                }
              }, 300);
            } else {
              setState(false);
              navigator(navigat);
            }
          }}
          variant="body1"
          color="initial"
          textAlign="center"
          fontWeight="600"
        >
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
}
