import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";

import { API_URL } from "../../../api/config";
import { getAllCareer } from "../../../api/Services";
// import { OpenPositionsData } from "../../../utils/Constent/Career/CareerConstent";
import Typographyview1 from "../../CommonSectionweb/Typographyview1";

const Positions = () => {
  const [Positions, setPositions] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Set initial value to true

  useEffect(() => {
    const getData = async () => {
      const data = await getAllCareer();
      if (data?.s) setPositions(data?.r);
      setIsLoading(false); // Set isLoading to false after data is loaded
    };
    getData();
  }, []);

  return (
    <Container
      sx={{ maxWidth: { xs: "xs", sm: "md", md: "md", lg: "lg", xl: "xl" } }}
    >
      <Box id="Positions" sx={{ marginTop: { xs: "4rem", sm: "5rem" } }}>
        <Box>
          <Typographyview1
            value="Open Positions "
            subtitle="Let’s build and evolve together in the world of Information technology."
          />
        </Box>
        <Box>
          <Grid
            container
            spacing={{ xs: 3, sm: 2, md: 3 }}
            // sx={{
            //   display: "flex",
            //   justifyContent: "center",
            //   alignItems: "center",
            // }}
          >
            {isLoading
              ? [1, 2, 3, 4, 5, 6].map((i) => (
                  <Gridview key={i}>
                    <Stack
                      spacing={1}
                      boxShadow={3}
                      p={3}
                      borderRadius={3}
                      sx={{
                        display: "flex",

                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          gap: 2,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Skeleton
                          variant="rounded"
                          sx={{ width: "80px", height: "80px" }}
                          animation="wave"
                        />
                      </Box>
                      <Skeleton
                        variant="text"
                        width="60%"
                        sx={{ fontSize: "16px", borderRadius: "10px" }}
                        animation="wave"
                      />

                      <Skeleton
                        variant="text"
                        width="60%"
                        sx={{ fontSize: "16px", borderRadius: "10px" }}
                        animation="wave"
                      />
                      <Skeleton
                        variant="text"
                        width="60%"
                        sx={{ fontSize: "16px", borderRadius: "10px" }}
                        animation="wave"
                      />

                      <Skeleton
                        variant="rounded"
                        width={150}
                        height={35}
                        sx={{ borderRadius: "15px" }}
                        animation="wave"
                      />
                    </Stack>
                  </Gridview>
                ))
              : Positions?.map((n) => {
                  return (
                    <Gridview key={n?.id}>
                      <Positionsview
                        media={n.icon}
                        value={n.designation}
                        experience={n.experience}
                        vacancy={n.vacancy}
                      />
                    </Gridview>
                  );
                })}
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default Positions;

function Positionsview({ value, media, experience, vacancy }) {
  return (
    <Box
      sx={{
        boxShadow: "0px 0px 30px 0px rgba(0, 0, 0, 0.20)",
        borderRadius: "15px",
        py: 3,
      }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: 0.5,
          }}
        >
          <img
            src={API_URL.careerImage + media}
            alt=""
            width="80px"
            height="80px"
            style={{
              borderRadius: "5px",
              objectFit: "contain",
              // objectPosition: "center",
              // background: "rgb(238, 238, 238,0.3)",
              padding: "2px",
            }}
          />
        </Box>

        <Box sx={{ textAlign: "center" }}>
          <Typography
            variant="h5"
            noWrap
            fontWeight="800"
            color="#0E3347"
            sx={{ textTransform: "capitalize", px: 1 }}
          >
            {value}
          </Typography>

          <Box sx={{ my: 1 }}>
            <Typography variant="body3" fontWeight="900" color="#252525">
              Experience : <span>{experience} </span>
            </Typography>
          </Box>
          <Typography variant="body3" fontWeight="900" color="#252525">
            Vacancy : <span> {vacancy} </span>
          </Typography>
        </Box>
        <Box
          sx={{
            mt: { xs: 1.5, sm: 2 },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            className="arrow"
            // href="#apply-form"
            onClick={() => {
              document
                .getElementById("apply-form")
                .scrollIntoView({ behavior: "smooth" });
            }}
            sx={{
              color: "#fff",
              backgroundColor: "#0E3347",
              border: "2px solid #0E3347",
              borderRadius: "10px",
              textTransform: "none",
              fontWeight: "700",
              fontSize: { xs: "12px", sm: "15px" },
              px: { xs: 4, xl: 5 },
              py: { xs: 1, sm: 0.8 },
              "&:hover": {
                border: "2px solid #0E3347",
                color: "#0E3347",
                fontWeight: "800",
              },
            }}
          >
            <span style={{ paddingRight: "5px" }}>Apply Now</span>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

function Gridview({ children }) {
  return (
    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
      {children}
    </Grid>
  );
}
