import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { Box } from "@mui/system";

import { getCaseStudyById } from "../../../api/CaseStudy";
import Contact from "../../Service/ServiceScreen/Contact";
import Casestudy from "../Screen/CasestudyPage/Casestudy";
import DesignSystem from "../Screen/CasestudyPage/DesignSystem";
import Discover from "../Screen/CasestudyPage/Discover";
import Productoverview from "../Screen/CasestudyPage/Productoverview";
import ProjectsDuration from "../Screen/CasestudyPage/ProjectsDuration";
import Statement from "../Screen/CasestudyPage/Statement";
import Watching from "../Screen/CasestudyPage/Watching.jsx";

const CasestudyPage = () => {
  useEffect(() => window.document.scrollingElement?.scrollTo(0, 0), []);

  const { id } = useParams();
  const [CaseStudy, setCaseStudy] = useState([]);

  const getData = async () => {
    const data = await getCaseStudyById(id);
    if (data?.s) setCaseStudy(data?.r);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Box>
      <Casestudy data={CaseStudy} />
      <Productoverview data={CaseStudy} />
      <ProjectsDuration />
      <Statement data={CaseStudy} />
      <Discover />
      <DesignSystem data={CaseStudy} />
      <Watching />
      <Contact />
    </Box>
  );
};

export default CasestudyPage;
