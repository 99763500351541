import { Box } from "@mui/material";
import React from "react";

const CareerForm = () => {
  return (
    <Box
      sx={{
        height: "600px",
        mt: 6,
      }}
      id="apply-form"
    >
      <iframe
        src="/form.html"
        title="Sample HTML"
        style={{ width: "100%", height: "100%", border: "none" }}
      ></iframe>
    </Box>
  );
};

export default CareerForm;
