import React from "react";
import Typography from "@mui/material/Typography";
import mainline1 from "../../Image/mainline1.png";
import { Box } from "@mui/system";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination, Autoplay } from "swiper";
import { TimelineData, TimelineResponsiveData } from "../../../utils/Constent/About/AboutConstent";

const Time = () => {
  return (
    <Box
      sx={{
        my: { xs: "3rem", sm: "18rem" },
        position: "relative",
        cursor: "pointer",
      }}
    >
      <Box sx={{ display: { xs: "none", sm: "flex" } }}>
        <Box>
          <Box
            sx={{ position: "absolute", top: 0, width: "100%", mt: "-0.75rem" }}
          >
            <Box
              sx={{
                width: "100%",
                borderBottom: "5px solid #252525",
                height: "5px",
              }}
            ></Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                width: "100%",
              }}
            >
              <Swiper
                breakpoints={{
                  320: { slidesPerView: 1.5, spaceBetween: 20 },
                  500: { slidesPerView: 2.5, spaceBetween: 20 },
                  768: { slidesPerView: 2.5, spaceBetween: 50 },
                  1024: { slidesPerView: 3.5, spaceBetween: 30 },
                  1200: { slidesPerView: 4.5, spaceBetween: 30 },
                  1440: { slidesPerView: 5, spaceBetween: 50 },
                  1700: { slidesPerView: 5, spaceBetween: 50 },
                }}
                slidesPerView={5.5}
                // showspagination={false}
                spaceBetween={0}
                pagination={{
                  clickable: true,
                }}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
                modules={[Pagination, Autoplay]}
                className="mySwiper"
              >
                {TimelineData.map((n, i) => {
                  return (
                    <SwiperSlide key={i}>
                      <Timeline {...n} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: {
            xs: "flex",
            sm: "none",
            alignItems: "center",
            justifyContent: "center",
            height:"100%"
          },
        }}
      >
        <Box>
          <Box sx={{height:"100%",  position: "absolute",right:"0",left:"0",
                top: "0%",display:"flex",alignItems:"center",justifyContent:"center"}}>
            <img src={mainline1} alt="" height="100%" />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                mt:5
              }}
            >
              {
                TimelineResponsiveData.map((n,i)=>{
                  return(
                    <Box key={i}>
                      <Timeline1 {...n}/>
                    </Box>
                  )
                })
              }

            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Time;

function Timeline({
  number,
  subtitle,
  media,
  value,
  top1,
  top2,
  left1,
  Direction,
  mb,
  mt,
}) {
  return (
    <Box position="relative" sx={{ mb: mb, mt: mt, ml: 1 }}>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: top2,
            left: left1,
          }}
        >
          <Typography variant="body1" color="#252525">
            {number}
          </Typography>
        </Box>
        <Box>
          <img src={media} alt="" width="150px" />
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: top1,
            left: "16%",
            display: "flex",
            flexDirection: Direction,
          }}
        >
          <Typography
            variant="body5"
            color="#484848"
            // maxWidth="50rem"
          >
            {subtitle}
          </Typography>
          <Typography variant="body1" color="#252525" sx={{ my: 1 }}>
            {value}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

function Timeline1({
  number,
  subtitle,
  media,
  value,
  pl,
  top1,
  width,
  mr,
  left1,
  right,
  mt,
}) {
  return (
    <Box position="relative" >
      <Box sx={{mt:mt}}>
        <Box
          sx={{
            position: "absolute",
            top: "-10%",
            right: right,
          }}
        >
          <Typography variant="body1" color="#252525">
           {number}
          </Typography>
        </Box>
        <Box sx={{ pl: pl ,mr:mr}}>
          <img src={media} alt="" width={width} />
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: top1, 
            left: left1,
            width: "48%",
          }}
        >
          <Box
            sx={{
              lineHeight: "11px",
            }}
          >
            <Typography variant="body5" color="#484848">
             
              {subtitle}
            </Typography>
            <Typography variant="body1" color="#252525" sx={{ my: 1 }}>
           {value}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

