import React from "react";

import { Container, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { API_URL } from "../../../../api/config";

const DesignSystem = ({ data }) => {
  return (
    <Container
      sx={{ maxWidth: { xs: "xs", sm: "md", md: "md", lg: "lg", xl: "xl" } }}
    >
      <Box sx={{ marginTop: { xs: "4rem", sm: "4rem"} }}>
        <Box sx={{}}>
          <Box sx={{ mb: { xs: 2, sm: 3 } }}>
            <Typography
              variant="h4"
              color="#295768"
              sx={{
                textAlign: "center",
                letterSpacing: "2px",
                fontWeight: "400",
              }}
            >
              Design{" "}
              <span style={{ color: "#0E3347", fontWeight: "700" }}>
                {" "}
                System
              </span>
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 6,
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <img
                src={API_URL.casestudyImages + data?.design_img}
                alt=""
                width="100%"
                height="100%"
              />
            </Box>
          </Box>
          <Box>
            <Grid
              container
              spacing={0}
              sx={{
                mt: 7,
                border: "2px solid #0E3347",
                borderRadius: "20px",
                pb: 5,
              }}
            >
              {data?.images?.map((n, e) => {
                return (
                  <Gridview key={e}>
                    <Systemview img={n} index={e} />
                  </Gridview>
                );
              })}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default DesignSystem;

function Systemview({ img, index }) {
  return (
    <Box
      className="hovereffect"
      sx={{
        display: "flex",
        justifyContent: "center",
        mt: 3,
        pt: index % 2 !== 0 ? 4 : "0",

        transition: "all 0.5s ease 0s",
      }}
    >
      <Box
        sx={{
          overflow: "scroll",
          borderRadius: "30px",
          width: "90%",
          aspectRatio: "375 / 812",

          filter: "drop-shadow(2px 2px 5px rgba(38, 38, 38, 0.3))",
          // boxShadow:"0px 4px 8px rgba(38, 38, 38, 0.2)",
          "&::-webkit-scrollbar": { display: "none" },
        }}
      >
        <Box>
          <img
            src={API_URL.casestudyImages + img?.image}
            alt=""
            width="100%"
            height="100%"
            style={{
              borderRadius: "20px",

              cursor: "pointer",
              // boxShadow:"0px 4px 8px rgba(38, 38, 38, 0.2)",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

function Gridview({ children }) {
  return (
    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
      {children}
    </Grid>
  );
}
