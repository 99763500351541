import { Box} from "@mui/material";
import NativeApp1 from "../../../Image/NativeApp.png";
import ChildBanner from "../../CommonSection/ChildBanner";

const NativeApp = () => {
  return (
    <Box>
      <ChildBanner
        value={"Application Services"}
        title={"Cloud-Native App Development"}
        subtitle={
          "Cloud-native app development is a method of creating, operating, and enhancing applications using established processes and technologies for cloud computing. These applications consist of small, separate, and loosely linked services. Their purpose is to provide significant business benefits, such as the capacity to quickly integrate user input for ongoing enhancement."
        }
        subtitle1={
          "Cloud-native app development is an approach to software development optimized for deployment and operation on cloud computing platforms. It is based on the principles of cloud-native architecture, which include microservices, containerization, DevOps, automation, and resilience.We build higher-quality cloud-native apps with greater agility."
        }
        i={"row-reverse"}
        none1={"none"}
        ex={{pl:{xs:"0",md:4,xl:6}}}
        image={NativeApp1}
        width="150%"
        aos={"fade-down-right"}
      />
    </Box>
  );
};

export default NativeApp;
