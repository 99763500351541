// import { Swiper, SwiperSlide } from "swiper/react";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React from 'react';

// Import Swiper styles
// // import "swiper/css";
// import "swiper/css/pagination";
// import required modules
// import { Pagination, Autoplay, Navigation } from "swiper";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Slider from 'react-slick';

import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';

import { BoxviewData } from '../../../utils/Constent/Career/CareerConstent';
import Looking from '../../Image/Looking.png';

const Lookingfor = () => {
  var settings = {
    infinite: true,
    slidesToShow: 3.5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    overflow: "hidden",
    // pauseOnHover: false,
 

    responsive: [
      {
        breakpoint: 1536,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    
    <Box id="Lookingfor" sx={{ position: "relative", marginTop: "5rem" , overflow:"hidden"}}>
      <Box
        sx={{
          height: { xs: "400px", sm: "400px", lg: "100%" },
          objectFit: "cover",
        }}
      >
        <img src={Looking} alt="" width="100%" height="100%" />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            mt: 6,
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <AnimationOnScroll animateIn="animate__fadeInDown">
              <Typography
                variant="h5"
                color="#fff"
                sx={{ textAlign: "center", fontWeight: "700", pb: 3 }}
              >
                Who We Are Looking For?
              </Typography>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__fadeInUp" >
              <Box  maxWidth="50rem">
              <Typography
              
                variant="body3"
                color="#fff"
                sx={{ textAlign: "center", fontWeight: "400" }}
              >
                There are many variations of passages of available,
                but the majority have suffered alteration in some form, by
                injected humour,
              </Typography>
              </Box>
            </AnimationOnScroll>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: { xs: "45%", md: "40%", lg: "40%" },
          width: "100%",
        }}
      >
        <Slider {...settings}  >

        {BoxviewData.map((n) => {
            return (
              <Box key={n} >
                <Boxview
                 {...n}
                />
              </Box>
            );
          })}
        </Slider>
      </Box>
    </Box>
  );
};

export default Lookingfor;

function Boxview({ value, subtitle, no, fadedown }) {
  return (
    <AnimationOnScroll animateIn={fadedown} delay={100}>
      <Box className="hovereffect"  sx={{ display: "flex", alignItems: "center" ,mx:2,mt:5,width:"auto",transition: "all 0.5s ease 0s"}}>
        <Box
          sx={{
            background: "#E0F6F9",
            p: 3,
            height: 150,
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <Box
              sx={{
                pb: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body1"
                color="#20B69F"
                fontWeight="700"
                sx={{ px: 0.5 }}
              >
                {no}
              </Typography>
              <Typography variant="body1" color="#252525" fontWeight="700">
                {value}
              </Typography>
            </Box>
            <Typography variant="body2" color="#595959" fontWeight="400">
              {subtitle}
            </Typography>
          </Box>
        </Box>
      </Box>
    </AnimationOnScroll>
  );
}
