import { Box } from '@mui/material'
import React from 'react'
import SpinUp from "../../../Image/SpinUp.png";
import Ourwork from '../../CommonSection/Ourwork'

const OurWorkUiUx = () => {
  return (
    <Box>
        <Ourwork media={SpinUp} i="row"  ex={{pr:{xs:"0",md:4,xl:6}}}/>
    </Box>
  )
}

export default OurWorkUiUx
