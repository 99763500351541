import React, { useEffect, useState } from "react";

import { AnimationOnScroll } from "react-animation-on-scroll";

import { Box, Container, Grid, Paper } from "@mui/material";

import { API_URL } from "../../../api/config";
import { getClientImages } from "../../../api/Testimonials";
import Typographyview from "../../CommonSectionweb/Typographyview";

const OurClients = () => {
  const [Testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const data = await getClientImages();
      if (data?.s) setTestimonials(data?.r);
    };
    getData();
  }, []);

  return (
    <Container
      sx={{ maxWidth: { xs: "xs", sm: "md", md: "md", lg: "lg", xl: "xl" } }}
    >
      <Box sx={{ marginTop: { xs: "4rem", md: "5rem" } }}>
        <Box>
          <Typographyview
            value="Our Clients"
            subtitle="Our clients speak of our success. Here is whom we have helped."
          />
        </Box>
        <Box>
          <Grid
            container
            spacing={{
              xs: 3,
              sm: 2,
              md: 5,
              lg: 3,
              xl: 4,
            }}
          >
            {Testimonials.map((v, n) => {
              return (
                <Gridview key={n}>
                  <Clientview {...v} />
                </Gridview>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default OurClients;

function Clientview({ image }) {
  return (
    <AnimationOnScroll animateIn="animate__fadeIn">
      <Box>
        <Box>
          <Paper elevation={0}>
            <Box
              className="item"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  filter: "grayscale(100%)",
                  p: 1.2,
                  "&:hover": {
                    filter: "none",
                  },
                }}
              >
                <img
                  src={API_URL.clientImage + image}
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                    aspectRatio: "248 / 80",
                    objectFit: "cover",
                  }}
                />
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
    </AnimationOnScroll>
  );
}

function Gridview({ children }) {
  return (
    <Grid item xs={12} sm={6} lg={3}>
      {children}
    </Grid>
  );
}
