
import { Box } from "@mui/material";
import Scalable1 from "../../../Image/Scalable.png";
import ChildBanner from "../../CommonSection/ChildBanner";


const Scalable = () => {
  return (
    <Box>
      <ChildBanner
        value={"Containers & Microservices Development"}
        title={"Scalable And Distributed System"}
        subtitle={
          "Containers are a lightweight and portable way of packaging software applications and their dependencies. They provide a consistent and isolated runtime environment for applications. Microservices are an architectural pattern for building software applications as a collection of small, independent services communicating with each other over APIs. Each microservice performs a specific business function and can be developed, deployed, and scaled independently of other services."
        }
        subtitle1={
          "In cloud-native development, containers and microservices are often used to create scalable and resilient applications. They make it easier to develop, deploy, and manage applications in a cloud-native environment and enable organizations to take advantage of cloud computing platforms' scalability, flexibility, and cost-effectiveness."
        }
        none1={"none"}
        image={Scalable1}
        i={"row"}
        ex={{pr:{xs:"0",md:4,xl:6}}}
       
        aos={"fade-down-left"}
      />
    </Box>
  )
}

export default Scalable

  