import { Box } from "@mui/material";
import React from "react";
import OurWork1 from "../../../Image/OurWork.png";
import Ourwork from "../../CommonSection/Ourwork";

const OurWorkWeb = () => {
  return (
    <Box>
      <Ourwork
        media={OurWork1}
        i="row-reverse"
        ex={{ pl: { xs: "0", md: 4, xl: 6 } }}
      />
    </Box>
  );
};

export default OurWorkWeb;
