import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { Box } from "@mantine/core";

import { getPortfolioById } from "../../../api/Portfolio";
import Contact from "../../Service/ServiceScreen/Contact";
import Mobilescreen from "../Screen/CasestudyChildPage/Mobilescreen";
import Productoverview from "../Screen/CasestudyPage/Productoverview";
import Project from "../Screen/ProjectView/Project";

const ProjectView = () => {
  const { id } = useParams();

  // get portfolio by id
  const [Portfolio, setPortfolio] = useState();
  const [loading, Setloading] = useState();
  const getbyid = async () => {
    Setloading(true);
    const data = await getPortfolioById(id);
    if (data?.s) setPortfolio(data?.r);
    Setloading(false);
  };

  useEffect(() => {
    getbyid();
  }, []);

  useEffect(() => window.document.scrollingElement?.scrollTo(0, 0), []);
  return (
    <Box>
      <Project data={Portfolio} isLoading={loading} />
      <Productoverview data={Portfolio} />
      <Mobilescreen data={Portfolio} loading={loading} />
      <Contact />
    </Box>
  );
};

export default ProjectView;
