import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React, {
  useEffect,
  useState,
} from 'react';

import { useParams } from 'react-router-dom';
import Slider from 'react-slick';

import {
  Box,
  Container,
  Grid,
  Typography,
} from '@mui/material';

import { gethighlights } from '../../../../api/CaseStudy';
import { API_URL } from '../../../../api/config';

const Discover = () => {
  const { id } = useParams();

  const [Icon, setIcon] = useState([]);
  useEffect(() => {
    const gethightlight = async () => {
      const data = await gethighlights(id);
      if (data?.s) setIcon(data?.r);
    };
    gethightlight();
  }, []);

  var settings = {
    dots: true,
    slidesToShow:4,
    infinite: true,
    autoplay: true,
    // cssEase: "linear",
    // overflow: "hidden",
    dotsClass: "button__bar",
    pauseOnHover: false,

    responsive: [
      {
        breakpoint: 1528,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Container
      sx={{ maxWidth: { xs: "xs", sm: "md",lg: "lg", xl: "xl" } }}
    >
      <Box sx={{ marginTop: { xs: "4rem", sm: "5rem" } }}>
        <Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {Icon.length !== 0 && (
              <Typography
                className="Typographyview"
                variant="h1"
                fontWeight="700"
                color="#0E3347"
                sx={{
                  textAlign: "center",
                  letterSpacing: "2px",
                  fontWeight: "700",
                }}
              >
                Highlights
              </Typography>
            )}
          </Box>
        </Box>
        <Box>
          <Slider {...settings}>
            {Icon?.map((n, i) => (
              <Discoverview data={n} key={i} />
            ))}
          </Slider>
        </Box>
        <Box>
          <Box sx={{ mt:7 }}>
            <Box sx={{ mb: { xs: 2, sm: 3 } }}>
              <Typography
                variant="h4"
                color="#295768"
                sx={{
                  textAlign: "center",
                  letterSpacing: "2px",
                  fontWeight: "400",
                }}
              >
                Design{" "}
                <span style={{ color: "#0E3347", fontWeight: "700" }}>
                 Process
                </span>
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography
                variant="body1"
                color="#484848"
                sx={{
                  textAlign: "center",
                  width: { xs: "100%", md: "100%" },
                  fontWeight: "400",
                }}
              >
                Our design process follows a strategic path to create
                exceptional user experiences. We define the problem statement
                and conduct thorough user and competitor research. Ideation
                follows, where we craft user flows, personas, and journey maps
                to outline the entire experience. With a solid foundation, we
                move on to crafting low-fidelity wireframes, which evolve into
                high-fidelity user interfaces designed with user-centricity at
                the forefront. Rigorous analysis and error elimination refine
                the design, ensuring a polished result. Finally, we deliver a
                seamless, user-centred product that embodies our iterative and
                creative approach.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Discover;

function Discoverview({ value, media, background, color, data }) {
  return (
    <Box
      className="hovereffect"
      sx={{ mx: 1, transition: "all 0.5s ease 0s", mt: "4rem",height:"100%" }}
    >
      <Box
        backgroundColor={data?.color}
        sx={{
          mb: 3,
          // width: { xs: "100%", md: "100%" },
          borderRadius: "50px",
          height:"100%" 
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              // justifyContent:"center",
              width: "100%",
              py: 3,
              transition: "all 0.5s ease 0s",
              borderRadius: "50px",
            }}
          >
            <Box sx={{ zIndex: 1 }}>
              <Typography
                variant="h2"
                color="#000"
                fontWeight="700"
                sx={{ textAlign: "center" }}
              >
                {data?.title}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                my: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={API_URL.casestudyImages + data?.icon}
                alt=""
                width="auto"
                height="100px"
              />
            </Box>
            <Box>
              <Typography
                variant="body1"
                color="#000"
                fontWeight="700"
                sx={{ textAlign: "center" }}
              >
                {data?.description}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function Gridview({ children }) {
  return (
    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
      {children}
    </Grid>
  );
}
