import { Box, Container, Skeleton, Typography } from "@mui/material";
import React, { useState } from "react";
import App from "../../../Image/Apps.png";
import Play from "../../../Image/GPlay.png";
import { API_URL } from "../../../../api/config";
import "react-lazy-load-image-component/src/effects/blur.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Project = ({ data, isLoading }) => {
  const tags = data?.tags.split(",");
  // const [imageLoaded, setImageLoaded] = useState(false);

  // const handleImageLoad = () => {
  //   setImageLoaded(true);
  // };

  return (
    <Container
      sx={{ maxWidth: { xs: "xs", sm: "md", md: "md", lg: "lg", xl: "xl" } }}
    >
      <Box sx={{ marginTop: { xs: "8rem", sm: "8rem", lg: "8rem" } }}>
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <Typography variant="h3" color="#295768" fontWeight="700">
            Project View
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            my: { xs: "2rem", md: "2rem" },
          }}
        >
          <Typography
            variant="h3"
            color="#0E3347"
            fontWeight="600"
            className="Typographyview"
          >
            {data?.title}
          </Typography>
        </Box>

        <Box>
          {isLoading ? (
            <Skeleton
              variant="rect"
              animation="wave"
              width="100%"
              height={800}
              sx={{ borderRadius: "30px" }}
            />
          ) : (
            <LazyLoadImage
              src={API_URL.portfolioImages + data?.cover_photo}
              alt=""
              width="100%"
              loading="lazy"
              effect="blur"
              style={{ borderRadius: "30px", minHeight: 800, height: "100%" }}
            />
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            my: { xs: "2rem", md: "3rem" },
          }}
        >
          <Box
            sx={{
              display: { sm: "inline-block ", md: "flex" },

              gap: "20px",
            }}
          >
            {tags?.map((item) => (
              <Box>
                <TypographyView value={item} />
              </Box>
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "center" },
          }}
        >
          <Box>
            <img src={Play} alt="" width="100%" />
          </Box>

          <Box sx={{ pl: 2 }}>
            <img src={App} alt="" width="100%" />
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Project;
function TypographyView({ value }) {
  return (
    <Box
      sx={{
        background: "#E0F6F9",
        py: 1,
        mt: { xs: 2, md: 0 },
        px: { xs: 3, md: 2, lg: 3 },
        width: "100%",
        borderRadius: "5px",
      }}
    >
      <Box sx={{ textAlign: { xs: "center", md: "center" } }}>
        <Typography variant="body1" color="#0E3347" fontWeight="700">
          {value}
        </Typography>
      </Box>
    </Box>
  );
}
