import { Box } from "@mui/material";
import Cloud from "../../../Image/Cloud.png";
import Aws from "../../../Image/Aws.png";
import Kubernetes from "../../../Image/Kubernetes.png";
import VS from "../../../Image/VS.png";
import Digital from "../../../Image/Digital.png";
import ChildBanner from "../../CommonSection/ChildBanner";


const HireCloudApp = () => {
  return (
    <Box>
    <ChildBanner
      value={"Cloud Computing"}
      title={"Hire A Cloud App & Web Developer"}
      subtitle={
        "We provide comprehensive cloud & web development services that encompass the design, construction, support, and evolution of all types of web-based software. Hiring cloud & web developers from us guarantees you with innovative and results-driven solutions."
      
      }
      subtitle1={
        "Our services cover a wide range of offerings, including Full-stack Web Development Services, Custom Web Applications, Ecommerce Applications, Content Management Systems, as well as Quality Assurance and Testing."
      }
      media={Aws}
      media1={VS}
      media2={Kubernetes}
      media3={Digital}
      image={Cloud}
      i={"row-reverse"}
      ex={{pl:{xs:"0",md:4,xl:6}}}
      none2={"none"}
      aos={"fade-down-right"}
    />
    </Box>
  );
};

export default HireCloudApp;

