import React, { useEffect } from "react";

import { Box } from "@mui/system";

import BlogScreen from "../BlogScreen/BlogScreen";
import TechBlog from "../BlogScreen/TechBlog";

import { logEvent } from "firebase/analytics";
import { analytics } from "../../../Firebase";

const BlogPage = () => {
  useEffect(() => {
    logEvent(analytics, "page_view", {
      page_location: window.location.href + window.location.search,
      page_path: window.location.href + window.location.search,
      page_title: "Blog Page",
    });
  }, []);
  useEffect(() => window.document.scrollingElement?.scrollTo(0, 0), []);
  return (
    <Box>
      <BlogScreen />
      {/* <WhatwhyBlog /> */}
      <TechBlog />
    </Box>
  );
};

export default BlogPage;
