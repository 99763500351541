import { API_GET, API_URL } from "./config";

export const getAllPortfolio = (category) => {
  try {
    if (category) {
      const q = `?category_id=${category}`;
      const data = API_GET(API_URL.getAllportfolio + q);
      return data;
    } else {
      const data = API_GET(API_URL.getAllportfolio);
      return data;
    }
  } catch (error) {
    return error;
  }
};

export const getPortfolioById = (id) => {
  try {
    const data = API_GET(API_URL.getPotfolioById + id);
    return data;
  } catch (error) {
    return error;
  }
};
