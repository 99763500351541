import React from 'react'
import Ourwork from '../../CommonSection/Ourwork'
import Ourwork2 from "../../../Image/Ourwork2.png"
import { Box } from '@mui/material'

const OurWorkCloud = () => {
  return (
    <Box>
    <Ourwork
      media={Ourwork2}
      i="row-reverse"
      ex={{ pl: { xs: "0", md: 4, xl: 6 } }}
    />
  </Box>
  )
}

export default OurWorkCloud