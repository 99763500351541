// import React, { useEffect, useState } from "react";

// import {
//   Box,
//   Container,
//   Paper,
//   Typography,
// } from "@mui/material";

// import { API_URL } from "../../../api/config";
// import { getAllTestimonials } from "../../../api/Testimonials";
// import Typographyview from "../../CommonSectionweb/Typographyview";
// import Vector from "../../Image/Vector.png";
// import Vector1 from "../../Image/Vector1.png";
// import Test from '../../Image/Test.png';
// const Testimonials = () => {
//   const [Testimonials, setTestimonials] = useState([]);
//   const [index, setindex] = useState(0);
//   useEffect(() => {
//     const getData = async () => {
//       const data = await getAllTestimonials();
//       if (data?.s) setTestimonials(data?.r);
//     };
//     getData();
//   }, []);
//   return (
//     <Container
//       sx={{ maxWidth: { xs: "xs", sm: "md", md: "md", lg: "lg", xl: "xl" } }}
//     >
//       <Box
//         id="Testimonials"
//         sx={{
//           marginTop: { xs: "4rem", md: "5rem" },
//         }}
//       >
//         <Paper elevation={3}>
//           <Box
//             sx={{
//               py: 6,
//               flexWrap: "wrap",
//               position: "relative",
//             }}
//           >
//             <Box>
//               <Typographyview
//                 value="what our client says"
//                 subtitle="This is why we do what we do!"
//               />
//             </Box>
//             <Box
//               sx={{
//                 display: "flex",
//                 justifyContent: "start",
//                 flexDirection: { xs: "column", md: "row" },
//                 alignItems: { xs: "start", sm: "center" },
//                 marginInline: { md: "1.8rem", lg: "2.5rem" },
//                 marginTop: "3rem",
//               }}
//             >
//               <Box
//                 sx={{
//                   display: "flex",
//                   justifyContent: "start",
//                   position: "relative",
//                   width: { xs: "100%", sm: "auto" },
//                 }}
//               >
//                 <Box
//                   // className="testimonialBorder_animation"
//                   sx={{
//                     width: {
//                       xs: "100%",
//                       sm: "500px",
//                       md: "400px",
//                       lg: "500px",
//                       // borderWidth: "10px 10px 10px 10px",
//                       // borderStyle: "solid",
//                       // borderImage:
//                       //   "linear-gradient(316.51deg, #295768 -2.02%, rgba(81, 172, 206, 0) 100%) 10",
//                       // position: "relative",
//                       borderRadius: "3px",
//                     },
//                     mx: { xs: "0.8rem", md: 0 },
//                   }}
//                 >
//                   <img
//                     src={Test}
//                     alt=""
//                     style={{
//                       width: "100%",
//                       height: "auto",
//                       aspectRatio: "570 / 570",
//                       objectFit: "cover",
//                       objectPosition: "top",
//                     }}
//                     loading="lazy"
//                   />
//                 </Box>
//                 <Box
//                   sx={{
//                     position: "absolute",
//                     display: { xs: "none", sm: "flex" },
//                     left: "-3%",
//                     top: { xs: "-15%", sm: "-10%", md: "-10%", lg: "-9%" },
//                   }}
//                 >
//                   <img src={Vector} alt="" width="100px" />
//                 </Box>
//               </Box>
//               <Box
//                 sx={{
//                   marginLeft: { xs: "0.8rem", md: "1.5rem" },
//                   marginTop: { xs: "1rem", md: "0" },
//                   marginRight: { xs: "0.8rem", md: 0 },
//                 }}
//               >
//                 <Box
//                   sx={{
//                     display: "flex",
//                     alignItems: "center",
//                     justifyContent: { sm: "center", md: "start" },
//                   }}
//                 >
//                   <Box
//                     sx={{
//                       marginRight: "1rem",
//                     }}
//                   >
//                     <img
//                       src={
//                         API_URL.testimonialsImages +
//                         Testimonials[index]?.profile_img
//                       }
//                       alt=""
//                       style={{
//                         width: "75px",
//                         height: "75px",
//                         aspectRatio: "75 / 754",
//                         objectFit: "cover",
//                         objectPosition: "top",
//                         borderRadius: "50%",
//                         filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
//                       }}
//                     />
//                   </Box>
//                   <Box>
//                     <Typography
//                       variant="body1"
//                       color="#484848"
//                       fontWeight="500"
//                     >
//                       {/* Mr.john Wick */}
//                       {Testimonials[index]?.name}
//                     </Typography>
//                     <Typography
//                       variant="body1"
//                       color="#868686"
//                       sx={{ overflowWrap: "anywhere" }}
//                     >
//                       {/* Ceo of App */}
//                       {Testimonials[index]?.designation}
//                     </Typography>
//                   </Box>
//                 </Box>
//                 <Box
//                   sx={{
//                     marginTop: { xs: "0.5rem", md: "1rem" },
//                     textAlign: { sm: "center", md: "start" },
//                   }}
//                 >
//                   <Typography
//                     variant="body1"
//                     color="#646464"
//                     sx={{ maxWidth: { md: "40rem", lg: "60rem" } }}
//                   >
//                     {Testimonials[index]?.review}
//                   </Typography>
//                 </Box>
//                 <Box
//                   sx={{
//                     display: "flex",
//                     marginTop: { xs: "1rem", md: "2rem" },
//                     justifyContent: { sm: "center", md: "start" },

//                     flexWrap: "wrap",
//                     gap: "0.5rem",
//                   }}
//                 >
//                   {Testimonials?.map((item, idx) => (
//                     <Box
//                       key={item.id}
//                       onClick={() => setindex(idx)}
//                       sx={{ cursor: "pointer" }}
//                     >
//                       <EllipseView
//                         image={API_URL.testimonialsImages + item?.profile_img}
//                       />
//                     </Box>
//                   ))}
//                 </Box>
//               </Box>
//             </Box>

//             <Box
//               sx={{
//                 display: { xs: "none", md: "flex" },
//                 position: "absolute",
//                 right: { md: "2%", lg: "5%" },
//                 bottom: "5%",
//               }}
//             >
//               <img src={Vector1} alt="" width="100px" />
//             </Box>
//           </Box>
//         </Paper>
//       </Box>
//     </Container>
//   );
// };

// export default Testimonials;

// function EllipseView({ image }) {
//   return (
//     <Box sx={{ flexWrap: "wrap" }}>
//       <img
//         src={image}
//         alt=""
//         style={{
//           width: "55px",
//           height: "55px",
//           aspectRatio: "55 / 55",
//           objectFit: "cover",
//           objectPosition: "top",
//           borderRadius: "50%",
//           filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
//         }}
//       />
//     </Box>
//   );
// }
import React, { useEffect, useState } from "react";

import {
  Box,
  Container,
  Tooltip,
  IconButton,
  Modal,
  Paper,
  Typography,
} from "@mui/material";

import { API_URL } from "../../../api/config";
import { getAllTestimonials } from "../../../api/Testimonials";
import Typographyview from "../../CommonSectionweb/Typographyview";
import Vector from "../../Image/Vector.png";
import Vector1 from "../../Image/Vector1.png";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import TESTIMONIAL_1 from "../../Image/testimonials/1.png";
import TESTIMONIAL_2 from "../../Image/testimonials/3.png";
import TESTIMONIAL_3 from "../../Image/testimonials/2.png";
import TESTIMONIAL_4 from "../../Image/testimonials/4.png";
import TESTIMONIAL_5 from "../../Image/Test.png";
import TESTIMONIAL_6 from "../../Image/testimonials/6.png";
import TESTIMONIAL_7 from "../../Image/testimonials/5.png";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  height: "75vh",
  p: 2.5,
};
const Testimonials = () => {
  const [Testimonials, setTestimonials] = useState([]);
  const [index, setindex] = useState(0);
  const [indexOpen, setIndexOpen] = useState(0);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [openVideo, setOpenVideo] = useState(false);
  console.log(Testimonials);
  const [reviewList, setReviewList] = useState([
    {
      review: ` Hello recipients,
      my name is Sean.
      I am the CSO of Crimson Stark and the president and founder of another company called Rovebox.
      I reached out to Kati exactly where I found her and she works with a very,
      very,
      very prolific company called Equity Soft.
      She and I chatted,
      we hit it off.
      She's a very,
      very nice woman,
      and she introduced me to Yogi who kind of took over a lot of the actual technical aspect of it.
      I will tell you straight away working with them was an absolute joy.
      They were very intelligent,
      they actually could help me out with my website telling me certain attributes that may benefit me without me even knowing it.
      I am not very technical so I needed a technical team to really help me understand exactly what I needed for the next steps.
      They were absolutely able to do that.
      I plan on working with them more so in the future but I did want to let you know let them know thank you very much for all the work that you've done,
      done a fantastic job you were able to get my website up and running,
      long before it was actually do.
      And for that I'm very,
      very grateful,
      we were able to fix some of the bugs very quickly,
      highly receptive communication was on point,
      and I will absolutely make sure that if there's any recommendations that I can give.
      I,
      for sure,
      would extend that.
      Once again,
      Kati,
      Yogi I want to say thank you very much for all the hard work that you've done.
      You've been phenomenal every step of the way,
      I very much appreciate everything you guys have done for me.
      If there's anything else that you need from me,
      please let me know.
      In the meanwhile,
      please get some sleep I know it's nighttime for you,
      daytime for me,
      but sorry this took so long to get to you.
      All right.
      Please have a good day,
      and I hope you receive this well.
      Cheers.
     `,
      videoUrl: "https://www.youtube.com/embed/B6Q83mlwmqo?si=vKxV3_JrjsYxw_vD",
      designation: " CEO of RoaveBox",
      name: " Mr. Sean Whitener744",
      profile_img: TESTIMONIAL_1,
    },
    {
      review: `Bonjour,
      je suis Christophe,
      CEO de Kenyetta.
      Keyette est une application mobile française en function de la place de Marché
      et nous avons développé Keyette avec Equitysoft Technologie.
      Pourquoi avons-nous choisi Equitysoft ?
      1.
      Nous voulions utiliser la technologie de Flutter
      parce que c'est une plateforme de croissance,
      donc très time-saving.
      2.
      Nous voulions avoir une organisation structurelle.
      3.
      À Equitysoft,
      vous avez un team de design,
      un team de front-end développeurs,
      un team de back-end développeurs et vous avez un grand project manager toujours disponible.
      3.
      Nous voulions avoir une team avec expérience parce que Flutter est une langue récente
      et ils ont déjà fait beaucoup de projets avec cette technologie,
      donc c'était un changement de jeu pour nous.
      Alors,
      comment est-il de travailler avec Equitysoft ?
      C'est principalement seulement trois phases.
      1.
      Vous designez avec eux,
      ils ont développé,
      et ensuite,
      quand ils sont déploies,
      ils ne vous laissent pas,
      ils vous donneront la maintenance,
      etc.
      Qu'est-ce que j'ai ressenti comme client ?
      Je dois dire que j'ai été très très impressionné par Equitysoft.
      Cette expérience s'est rendue très près de ma expectation.
      Je vais vous donner un exemple concret.
      En fait,
      avec des jobs créatives,
      je vois en de mes expériences professionnelles
      aller leur propre façon et ne pas s'entraîner à la vision clientielle.
      Avec Equitysoft,
      ce n'est absolument pas le cas,
      ils vont toujours vous offerter une solution en ligne avec votre vision
      et ils vont comprendre votre objectif et adapter à atteindre votre target.
      Et c'est ce qui fait la différence.
      Et une dernière chose avec Equitysoft,
      quand vous commencez un projet avec eux,
      vous ne targeteriez pas un simple MVP.
      Non,
      vous targeteriez une application bien-grille
      qui est prête à se scaleer avec votre business.
      Merci.
     `,
      videoUrl: "https://www.youtube.com/embed/xR2kWjWD_RU?si=gGztch_Ff4ioUnA2",
      designation: "CEO of Cueillette",
      name: "Mr. chrissous",
      profile_img: TESTIMONIAL_2,
    },
    {
      review: ` Hello,
      my name is Albert and today I would like to leave my honest review about equity
      soft technology.
      The guys who brought my idea into life actually they made the application for I was an android
      swap application and what I would like to say is leave my honest review about the
      guys.
      So first thing is the guys are really responsible for what they're doing and they
      graded what they're doing.
      They know exactly the field and for me with little knowledge and even I would say with
      zero knowledge I got to know so much thing right now thanks to the guys and they explain
      like every step what we are doing and we divided it into different phases so we speak
      about like design the guys brought with different kind of logos types colors type and what is
      good thing about them is every phase once we are doing it before we do it we have a discussion.
      Once they've done something we check it we see and we discussed again really great
      communication there is no misunderstanding at all.
      If we have some problems with the app or maybe some delay from outside always you know once
      you text once you call always available always available for the talk you just arrange the
      time and then we see what can be done better definitely that's that's really great point I would say
      about the team.
      Another thing is again it's about communication because I strongly believe that communication is one of
      the one of the hardest and one of the most important thing that we need to have between
      team and management right and even though there was such a huge distance between us and the guys
      the team's equity soft technology we still could handle it so smoothly I would say and so quickly
      in terms of just I'm not saying man something just weeks every week there is an update every
      once in a while when we have something need to be done we just speak about that even if I
      miss something and then I make it one more time and I just say okay we need to change some sort
      of things the things are getting changed and it's pretty pretty much awesome I would say it's
      really amazing how the guys are working what I can say is reliable reliable that's that's
      definitely one of the point communication reliable and you know the professionalism I would say
      yes exactly that's what people are looking for right professionalism if something is made and
      you want to change it don't hesitate to ask the guys will will be always there for you
      and they will change things accordingly what you need and they will show you the road sometimes
      you know like as it was with me you really sometimes getting confused and don't really know your
      road but those guys with the experience and that's one of the main thing as well experience they
      know from the experience perspective how it can be done better how it can be done fast how it can be
      more attractive I would say and don't doubt they have this perspective of you definitely that's
      what that's that's really amazing and what I can say we we finished our work and I really like
      what I do and if and not if the question is when I have other projects I definitely recommend you and
      I will definitely work with them it just made off like you know business and the guys are doing
      what they have to do really professionalism and communication that's like and reliability
      that's three core points of what you're looking for definitely with the good design even though if
      you have your design and you have imagination and you know how to structure everything those guys
      from the perspective of experience and definitely they do have it they will show you the best road
      which you can take and move with it and my honest opinion again equity soft technology that
      choice you should make especially in this kind of war right now because a lot of scum people you
      know a lot of people who are trying to just take money and they don't care how your project will
      go through that's not about those guys those guys really care about your project they don't I
      wouldn't say they people don't care about money everyone cares about money right but what I can
      say is they care about your project like they are members you know once you start doing your thing
      until it's finished make sure you know those guys will stay with you along the way and they
      will share this journey with you and that's really what you're looking for you don't want
      someone to be hired you know and just like do the work and whatever like no matter how it's done
      good bad you know neutral those guys are looking for the best result which you can be satisfied with
      until you're not satisfied they won't leave you I can I can tell you this you know and yeah hard
      working guys you know even when it's like I would say weekends you know and some holidays
      maybe going around if you need to get things done they will be doing it definitely so I'm giving 10
      out of 10 equity soft technology that's your choice like that's my honest review on them and
      honestly I suggest you to take them good luck with that and any my future projects definitely
      with equity soft technology gonna be on me like that's 100% thank you
     `,
      videoUrl: "https://www.youtube.com/embed/kZKomY_mvbA?si=Qay906eqsLx97_u3",
      designation: "CEO of SWOP",
      name: "Mr. Albert",
      profile_img: TESTIMONIAL_3,
    },
    {
      review: `Equity Soft Technologies have been amazing for me.
      So I've been working with Sachin and his team
      for about six months and from start to finish,
      he's been amazing.
      His updates are super sharp and efficient.
      They can create whatever they want
      and everything that I've asked for,
      they've been managed to handle and it's amazing.
      I love the app that they created
      and I cannot recommend these guys enough.
      If you are thinking of developing an app,
      use these guys,
      they are amazing.
     `,
      videoUrl: "https://www.youtube.com/embed/RbDdEgudgI4?si=I1nrQrFXrw9wffSY",
      designation: "CEO of AETHER Wellness",
      name: "Mr. DAVID LALIEU",
      profile_img: TESTIMONIAL_4,
    },
    {
      review: ` Hi,
      my name is Victor Aguilar and I am the founder and CEO of MicroMunchie.
      MicroMunchie is a social food marketplace that connects consumers to local restaurants.
      Our unique value proposition is that we are also a social media app,
      which means that our users can upload images and videos of their recent experiences,
      tag the merchant and upload it to a location-based feed.
      In our case,
      we had to build two apps and equity softed a phenomenal job in delivering both of the products.
      The first app we built was for consumers to place their orders,
      and the second one was for restaurants to manage and track orders.
      Two different apps and they're available today on iOS and Android.
      They're cross-platform,
      which means that it's one code base and we use Flutter to do so.
      I couldn't be any happier.
      Equity soft did consulting when it came down to the technology stacks,
      the databases,
      the architecture,
      testing.
      They helped me with all that.
      So if you're looking for software solutions,
      please reach out to equity soft.
      I cannot emphasize it enough.
      I looked all over the world,
      literally all over the world,
      United States,
      South America,
      and I finally found equity soft and I couldn't be any happier.
      Please give them a call.
     `,
      videoUrl: "https://www.youtube.com/embed/dH85ACWWyTU?si=hQboRiXKCejy8sa0",
      designation: "CEO of MicroMunchie",
      name: "Mr. stephenvp",
      profile_img: TESTIMONIAL_5,
    },
    {
      review: ` Hi,
      I want to give a special shout out to the equity soft solutions team.
      They've really done an amazing job
      Helping me come up with the right technology solution
      My some of the technologies that I built have mobile and web applications
      So really giving me advice on how to do this correctly and building it fast and with high quality
      So highly recommend them give them a five-star review.
      Thank you
     `,
      videoUrl: "https://www.youtube.com/embed/-tORE5gHVYM?si=oomYLWRy0PiTyOy8",
      designation: "CEO of SpinUP Capial",
      name: "Mr. Palathinkal ",
      profile_img: TESTIMONIAL_6,
    },
    {
      review: ` Hey,
      my name is Darnell Reed with no paper timesheet calm
      I wanted to do this video testimonial for equity soft
      Technologies,
      let me share what they did.
      They were able to quickly create for me a
      Mobile app that's gonna save my agency and other home care agency
      time and
      money and
      This team did a fabulous job
      They were able to take the concept an idea that I had and really bring it to life
      with my new app as well as
      They created the app within the Android and Apple iOS
      platform
      They were also created an administrative site
      Where the various companies can be able to go in and look at and then gave me super admin
      Capabilities and these are some of the things that I didn't even have as a part of the scope
      But they knew and recognized that that was some things that I needed to have
      as a part of what I was creating and
      Developing so I'm just so thankful
      For this team you guys did a fabulous job
      Definitely any upgrades and things I plan to do in the future with this software.
      I will be utilizing
      equity soft
      Technologies as well as I've already started to recommend
      Your company to other friends of mine who are interested in creating their own apps
      For their businesses or to sell,
      you know in the marketplace.
      So once again guys,
      thank you
      I appreciate you you guys did an amazing job
      Hey and keep up the good work
     `,
      videoUrl: "https://www.youtube.com/embed/IaHIfAX7fns?si=v-I5MFC2uzvw5hlH",
      designation: "CEO of Timesheet App",
      name: "Mr. Darnell Reid",
      profile_img: TESTIMONIAL_7,
    },
    {
      review: ` Hi,
      I'm Vanno,
      the CEO of Smart Emergency Solutions.
      Just wanted to share a few of our thoughts,
      our experience with EquitySoft through the development
      of our mobile application,
      Software Solution.
      We were very happy and pleased with Conte,
      the project manager,
      as well as Sachin,
      Posh,
      and there were so many people involved
      to help us to get from point A to point B.
      There were a lot of challenges on our end.
      We started out coding ourselves,
      and we were so happy that EquitySoft came to our rescue.
      The coding is five-star at EquitySoft,
      as well as the graphics.
      We've gotten so much of a very positive response
      for the graphics on our mobile app,
      as well as the timeliness and efficiency of the solution
      that we provided with this application.
      Just wanted to share with everyone
      that it's been a great experience.
      We look forward to next more experience
      as we grow as a company.
      We will always use or look forward to using EquitySoft
      to support each one of our initiatives.
      And thank you so much,
      EquitySoft,
      for being our partner.
      Thank you for your integrity.
      We're a company of great integrity,
      as well,
      and we look forward to more projects.
     `,
      videoUrl: "https://www.youtube.com/embed/gjBF6RVqbY4?si=hoih6PvAC7dK8hXJ",
      designation: "CEO of LIVE Safety ",
      name: "MS. Osbovan",
      profile_img: TESTIMONIAL_5,
    },
    {
      review: ` Hi guys,
      my name is Adrian and I would like to talk with you about EquitySoft
      as they are my long-term partner right now for nearly over a year.
      I have to 100% recommend them to everyone else looking for software development
      like Android or iOS development.
      They're super reliable.
      I get fast responses every time I need something,
      a change in the software.
      We are working for around one year right now on a permanent basis
      and for several projects.
      If I need anything from them or if there is something,
      they always provide me with the best solution.
      They are always available to discuss the best solution.
      That's simply amazing because this is a developer who is always interested
      that it works,
      who takes care that it works really fast.
      If there are bugs,
      if there is something not working,
      they really take care very fast that it works again.
      They do not leave you alone with a code and you always have a reliable partner
      delivering fast results and very,
      very good results.
      The feedback from our clients was very positive or is very positive.
      I can highly recommend anyone who is looking into mobile applications
      or development to get in touch with EquitySoft
      because they are simply delivering amazing work
      and simply have an amazing team of people behind the company
      who are really,
      really,
      really great to work with.
      I really enjoy to work with EquitySoft.
      Honestly,
      it's the best experience to work with a company I've ever had.
      Again,
      to everyone who is looking for software development,
      I can highly recommend the team of EquitySoft
      and would like you to try it out and to get in touch with EquitySoft.
      Thank you.
     `,
      videoUrl: "https://www.youtube.com/embed/BUGQM26Reao?si=eGARG_blybcf_SDq",
      designation: "CEO of BreastInform",
      name: "Mr. Adrian",
      profile_img: TESTIMONIAL_5,
    },
    {
      review: ` My name is Tally I run a ministry in the United States of America called TFG ministries.com
      and
      we were in need of an app and
      being new to the
      App world we didn't know who to turn to so we began to do research
      We began to look at different companies and ultimately we landed with equity soft technology
      And I must say the team behind equity soft technologies is simply amazing.
      They were budget conscious
      They took in consideration all of my ideas and they also gave me great ideas for my app as well
      Every week I had an update on the design and build for the app and what impressed me the most was at the very beginning
      He had a timeline documented of step-by-step.
      What would be needed?
      What were the milestones and more and I must say that equity soft technologies has lived up to every single
      Expectation that I had and they went above and beyond
      Thank you for what you've done and I would look forward to using you in the future as well.
      Thank you equity soft
     `,
      videoUrl: "https://www.youtube.com/embed/rnOzqinthws?si=cCWe04GR8a9j5ZMH",
      designation: "CEO of TFG Ministries",
      name: "Mr. Nephtali",
      profile_img: TESTIMONIAL_5,
    },
  ]);
  useEffect(() => {
    const getData = async () => {
      const data = await getAllTestimonials();
      if (data?.s) setTestimonials(data?.r);
    };
    getData();
  }, []);
  return (
    <Container
      sx={{ maxWidth: { xs: "xs", sm: "md", md: "md", lg: "lg", xl: "xl" } }}
    >
      <Box
        id="Testimonials"
        sx={{
          marginTop: { xs: "4rem", md: "5rem" },
        }}
      >
        <Paper elevation={3}>
          <Box
            sx={{
              py: 6,
              flexWrap: "wrap",
              position: "relative",
            }}
          >
            <Box>
              <Typographyview
                value="what our client says"
                subtitle="This is why we do what we do!"
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                flexDirection: { xs: "column", md: "row" },
                alignItems: { xs: "start", sm: "center" },
                marginInline: { md: "1.8rem", lg: "2.5rem" },
                marginTop: "3rem",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  position: "relative",
                  width: { xs: "100%", sm: "auto" },
                }}
              >
                <Box
                  className="testimonialBorder_animation"
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "500px",
                      md: "400px",
                      lg: "500px",
                      borderRadius: "3px",
                    },
                    mx: { xs: "0.8rem", md: 0 },
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      "&::after": {
                        content: '""',
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        background: "rgba(0, 0, 0, 0.5)", // Adjust the opacity and color as needed
                        zIndex: 1,
                      },
                    }}
                  >
                    <img
                      src={
                        API_URL.testimonialsImages +
                        Testimonials[index]?.profile_img
                      }
                      // src={reviewList[index].profile_img}
                      alt=""
                      style={{
                        width: "100%",
                        height: "auto",
                        aspectRatio: "570 / 570",
                        objectFit: "cover",
                        objectPosition: "top",
                      }}
                      loading="lazy"
                    />
                  </Box>
                  {/* <video
                    autoPlay={isVideoPlaying}
                    muted
                    loop
                    style={{
                      width: "100%",
                      height: "auto",
                      aspectRatio: "570 / 570",
                      objectFit: "cover",
                    }}
                  >
                    <source src={TESTIMONIALS1} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video> */}
                  <IconButton
                    sx={{
                      width: "91px",
                      height: "91px",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      background: "rgba(41, 87, 104, 1)",
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      zIndex: "2",
                      transform: "translate(-50%, -50%)",
                      "&:hover": {
                        background: "rgba(41, 87, 104, 1)",
                      },
                    }}
                    onClick={() => {
                      setIsVideoPlaying(true);
                      setOpenVideo(true);
                    }}
                  >
                    <PlayArrowIcon
                      sx={{
                        color: "#ffffff",
                        fontSize: "72px",
                      }}
                    />
                  </IconButton>
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    display: { xs: "none", sm: "flex" },
                    left: "-3%",
                    top: { xs: "-15%", sm: "-10%", md: "-10%", lg: "-9%" },
                  }}
                >
                  <img src={Vector} alt="" width="100px" />
                </Box>
              </Box>
              <Box
                sx={{
                  marginLeft: { xs: "0.8rem", md: "1.5rem" },
                  marginTop: { xs: "1rem", md: "0" },
                  marginRight: { xs: "0.8rem", md: 0 },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: { sm: "center", md: "start" },
                  }}
                >
                  <Box
                    sx={{
                      marginRight: "1rem",
                    }}
                  >
                    <img
                      src={
                        API_URL.testimonialsImages +
                        Testimonials[index]?.profile_img
                      }
                      // src={reviewList[index].profile_img}
                      alt=""
                      style={{
                        width: "75px",
                        height: "75px",
                        aspectRatio: "75 / 754",
                        objectFit: "cover",
                        objectPosition: "top",
                        borderRadius: "50%",
                        filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography
                      variant="body1"
                      color="#484848"
                      fontWeight="500"
                    >
                      {/* Mr.john Wick */}
                      {/* {reviewList[index]?.name} */}
                      {Testimonials[index]?.name}
                    </Typography>
                    <Typography
                      variant="body1"
                      color="#868686"
                      sx={{ overflowWrap: "anywhere" }}
                    >
                      {/* Ceo of App */}
                      {/* {reviewList[index]?.designation} */}
                      {Testimonials[index]?.designation}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    marginTop: { xs: "0.5rem", md: "1rem" },
                    textAlign: { sm: "center", md: "start" },
                  }}
                >
                  <Tooltip placement="top">
                    <Typography
                      variant="body1"
                      color="#646464"
                      sx={{
                        maxWidth: { md: "40rem", lg: "60rem" },
                        lineHeight: "1.5",
                        maxHeight: "6em", // Approximately 4 lines of text
                        overflow: "hidden",
                        display: "-webkit-box",
                        WebkitLineClamp: 3, // Limit to 4 lines
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      {/* {reviewList[index]?.review} */}
                      {Testimonials[index]?.review}
                    </Typography>
                  </Tooltip>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    marginTop: { xs: "1rem", md: "2rem" },
                    justifyContent: { sm: "center", md: "start" },

                    flexWrap: "wrap",
                    gap: "0.5rem",
                  }}
                >
                  {Testimonials?.map((item, idx) => (
                    <Box
                      key={item.id}
                      onClick={() => {
                        setindex(idx);
                        setIndexOpen(idx);
                      }}
                      sx={{ cursor: "pointer" }}
                    >
                      <EllipseView
                        image={API_URL.testimonialsImages + item?.profile_img}
                      />
                    </Box>
                  ))}
                  {/* {reviewList?.map((item, idx) => (
                    <Box
                      key={item.id}
                      onClick={() => {
                        setindex(idx);
                        setIndexOpen(idx);
                      }}
                      sx={{ cursor: "pointer" }}
                    >
                      <EllipseView image={item?.profile_img} />
                    </Box>
                  ))} */}
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                position: "absolute",
                right: { md: "2%", lg: "5%" },
                bottom: "5%",
              }}
            >
              <img src={Vector1} alt="" width="100px" />
            </Box>
          </Box>
        </Paper>
      </Box>
      <Modal
        open={openVideo}
        onClose={() => {
          setIsVideoPlaying(false);
          setOpenVideo(false);
        }}
      >
        <Box sx={style}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
            }}
          >
            <iframe
              width="100%"
              height="100%"
              src={Testimonials[indexOpen]?.video_link}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </Box>
        </Box>
      </Modal>
    </Container>
  );
};

export default Testimonials;

function EllipseView({ image }) {
  return (
    <Box sx={{ flexWrap: "wrap" }}>
      <img
        src={image}
        alt=""
        style={{
          width: "55px",
          height: "55px",
          aspectRatio: "55 / 55",
          objectFit: "cover",
          objectPosition: "top",
          borderRadius: "50%",
          filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
        }}
      />
    </Box>
  );
}
