import React from 'react';

import { AnimationOnScroll } from 'react-animation-on-scroll';

import {
  Box,
  Button,
  Container,
  Paper,
  Typography,
} from '@mui/material';


import {
  interviewprocessData,
} from '../../../utils/Constent/Career/CareerConstent';

const Interviewprocess = () => {
  return (
    <Box sx={{ backgroundColor: "#F5FDFF" }}>
      <Container
        sx={{ maxWidth: { xs: "xs", sm: "md", md: "md", lg: "lg", xl: "xl" } }}
      >
        <Box
          id="Interviewprocess"
          sx={{ pt: 3, marginTop: { xs: "4rem", sm: "5rem" },pb:5 }}
        >
          <Box>
            <Box
              sx={{
                mb: { xs: "3rem", sm: "4rem" },
                textAlign: "start",
              }}
            >
              <AnimationOnScroll animateIn="animate__flipInX">
                <Typography
                  variant="h4"
                  color="#295768"
                  fontWeight="700"
                  className="Typographyview"
                >
                  Interview Process
                </Typography>
              </AnimationOnScroll>
            </Box>
            <Box>
              <Box sx={{ width:"100%",display: "flex", flexDirection: { xs: "column",md:"row" } }}>
                {interviewprocessData.map((n, e) => {
                  return (
                    <Box key={e}>
                      <Processview {...n} />
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Interviewprocess;

function Processview({ image, value, data, image1, none }) {
  return (
    <Box
      sx={{
        display: { xs: "flex", md: "inline" },
        alignItems: { xs: "center", md: "start" },
        flexDirection: { xs: "row", md: "column" },
        justifyContent: { xs: "center", md: "start" },
        mt: { xs: 0, sm: 3, md: 0 },
      }}
    >
      <Box>
        <Box
          sx={{
            display: { xs: none, md: "flex" },
            width: "100%",
            ml:{xs:0,md:3},
          }}
        >
          <img src={image} alt="" width="100%" height="100%" />
        </Box>
        <Box
          sx={{
            display: { xs: "flex", md: "none" },
          }}
        >
          <img src={image1} alt="" width="95%" height="100%" />
        </Box>
      </Box>
      <Box sx={{ ml: { xs: -5, md: 0 }, height: "100%", width: "100%" ,mt:{xs:0,md:3}}}>
        <Box
          boxShadow={1}
          sx={{
            background: "#fff",
            borderRadius: "10px",
            px: 1,
            py: 2,
            mr: { md: 1, lg: 3 },
            width: { xs: "100%",  md: "auto" },
            mt: 2,
            height: { xs: "auto", md: "180px", lg: "200px", xl: "200px" },
          }}
        >
          <Typography
            variant="h2"
            color="#252525"
            fontWeight="700"
            // maxWidth="7rem"
          >
            {value}
          </Typography>

          <Box sx={{ height: "100%" }}>
            {data?.map((n, i) => {
              return (
                <Box key={i}>
                  <ProcessText {...n} />
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}


function ProcessText({ subtitle }) {
  return (
    <Box sx={{height:"100%"}}>
      <Typography
        variant="body2"
        color="#595959"
        // noWrap
        fontWeight="400"
        sx={{ mt: 1.5, textTransform: "capitalize" ,maxWidth:"14rem"}}
      >
        {subtitle}
      </Typography>
    </Box>
  );
}