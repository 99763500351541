import ChildBanner from '../../CommonSection/ChildBanner'
import { Box } from '@mui/material'
import AIMachine from "../../../Image/AIMachine.png";
import React from "../../../Image/React.png";
 
 const Machine = () => {
   return (
    <Box>
    <ChildBanner
      value={"AI"}
      title={"Machine Learning"}
      subtitle={
        "Unlock the potential of your data with machine learning. Our machine-learning solutions empower you to make data-driven decisions, predict trends, and optimize processes. "
      }
      subtitle1={
        "Whether it's recommendation systems, fraud detection, or predictive maintenance, we have the expertise to leverage machine learning for your business advantage. Stay ahead of the competition by harnessing the power of data-driven insights."
      }
      
      image={AIMachine}
      none1={"none"}
      i={"row-reverse"}
      none={"none"}
      ex={{pl:{xs:"0",md:4,xl:6}}}
     
      aos={"fade-down-right"}
    />
    </Box>
   )
 }
 
 export default Machine