import { AnimationOnScroll } from "react-animation-on-scroll";

import { Box, Button, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";

const ChildBanner = ({
  value,
  title,
  subtitle,
  subtitle1,
  media,
  media1,
  media2,
  media3,
  media4,
  media5,
  media6,
  image,
  i,
  none,
  none1,
  none2,
  none3,
  ex,
  aos,
}) => {
  return (
    <Container
      sx={{ maxWidth: { xs: "xs", sm: "md", md: "md", lg: "lg", xl: "xl" } }}
    >
      <Box
        sx={{
          marginTop: { xs: "4rem", sm: "5rem" },
          overflow: "hidden",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "space-between" },
              alignItems: "center",
              flexDirection: {
                xs: "column",
                md: i,
              },
            }}
          >
            <Box
              sx={{
                ...ex,
              }}
            >
              <Uiuxview
                value={value}
                title={title}
                subtitle={subtitle}
                subtitle1={subtitle1}
                media={media}
                media1={media1}
                media2={media2}
                media3={media3}
                none={none}
                none1={none1}
                none2={none2}
                none3={none3}
                media4={media4}
                media5={media5}
                media6={media6}
                image={image}
              />
            </Box>
            <Box data-aos={aos} data-aos-duration="1500" data-aos-offset="100">
              <Box
                // boxShadow={1}
                sx={{
                  overflow: "hidden",
                  display: { xs: "none", md: "flex" },
                  justifyContent: "center",
                  maxWidth: { xs: "100%", md: "100%" },
                  height: { xs: "auto", md: "100%", lg: "100%", xl: "auto" },
                }}
              >
                <img
                  src={image}
                  alt=""
                  width="100%"
                  height="100%"
                  style={{ objectFit: "cover" }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default ChildBanner;

function Cradview({ media }) {
  return (
    <Box
      boxShadow={2}
      sx={{
        display: "flex",
        justifyContent: "center",
        borderRadius: "10px",
      }}
    >
      <img src={media} alt="" width="50px" />
    </Box>
  );
}

function Uiuxview({
  value,
  image,
  title,
  subtitle,
  subtitle1,
  media,
  media1,
  media2,
  media3,
  media4,
  media5,
  media6,
  none2,
  none3,
  none,
  none1,
  url,
}) {
  const navigator = () => {
    window.open(url);
  };
  const [showContactUsButton, setShowContactUsButton] = useState(true);

  useEffect(() => {
    // Check if the current pathname is "/hite"
    if (window.location.pathname === "/hire-software-developers") {
      setShowContactUsButton(false); // Don't show the button on the "hite" page
    } else {
      setShowContactUsButton(true); // Show the button on other pages
    }
  }, []);

  const [showContactUsButton1, setShowContactUsButton1] = useState(true);

  useEffect(() => {
    // Check if the current pathname is "/hite"
    if (window.location.pathname === "/hire-software-developers") {
      setShowContactUsButton1(true); // Don't show the button on the "hite" page
    } else {
      setShowContactUsButton1(false); // Show the button on other pages
    }
  }, []);

  return (
    <Box sx={{ overflow: "hidden" }}>
      <Box>
        <Box>
          <AnimationOnScroll animateIn="animate__fadeInDown">
            <Typography
              variant="h5"
              color="#0E3347"
              fontWeight="700"
              sx={{
                pb: { xs: 3, md: 2 },
                textAlign: { xs: "center", md: "start" },
                fontWeight: { xs: "800", md: "700" },
                textTransform: "capitalize",
              }}
            >
              {value}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: { xs: "center", md: "start" },
              }}
            >
              <Typography
                variant="h1"
                color="#252525"
                sx={{
                  textAlign: { xs: "center", md: "start" },
                  pb: { xs: 3, md: 4 },
                  lineHeight: 1.2,
                  fontWeight: { xs: "800", md: "700" },
                  textTransform: "capitalize",
                }}
              >
                {title}
              </Typography>
            </Box>
          </AnimationOnScroll>
        </Box>
        <Box
          sx={{ overflow: "hidden" }}
          data-aos="fade-down-left"
          data-aos-duration="1500"
          data-aos-offset="200"
        >
          <Box
            boxShadow={1}
            sx={{
              display: { xs: "flex", md: " none" },
              justifyContent: "center",
              maxWidth: { xs: "100%", md: "100%" },
              height: { xs: "auto", md: "100%", lg: "100%", xl: "auto" },
              objectFit: "cover",
              overflow: "hidden",
              my: 3,
            }}
          >
            <img
              src={image}
              alt=""
              width="100%"
              height="100%"
              style={{ overflow: "hidden" }}
            />
          </Box>
        </Box>
        <AnimationOnScroll animateIn="animate__fadeInUp">
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "start", md: "start" },
            }}
          >
            <Box maxWidth="50rem">
              <Typography
                variant="body2"
                color="#484848"
                fontWeight="400"
                sx={{
                  pb: { xs: 3, md: 2 },
                  textAlign: { xs: "justify", md: "justify" },
                }}
              >
                {subtitle}
              </Typography>

              <Typography
                variant="body2"
                color="#484848"
                fontWeight="400"
                sx={{
                  pb: { xs: 3, md: 4 },
                  textAlign: { xs: "justify", md: "justify" },
                }}
              >
                {subtitle1}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: none1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  sm: "row",
                  md: "row",
                  lg: "row",
                },
                justifyContent: { xs: "start", lg: "start" },
                pb: { xs: 3, md: 4 },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "start", md: "start" },
                }}
              >
                <Cradview media={media} />
                <Box sx={{ px: 2 }}>
                  <Cradview media={media1} />
                </Box>
                <Cradview media={media2} />

                <Box sx={{ pl: 2, display: none }}>
                  <Cradview media={media3} />
                </Box>
              </Box>
              <Box
                sx={{
                  display: none2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "start", md: "start" },
                    pt: { xs: 2.5, sm: 0, md: 0, lg: 0 },
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      pr: { xs: 2, md: 2, sm: 0, lg: 0 },
                      pl: { md: 2, lg: 0 },
                      mx: { sm: 2, md: 0, lg: 2 },
                    }}
                  >
                    <Cradview media={media4} />
                  </Box>

                  <Cradview media={media5} />

                  <Box sx={{ pl: 2, display: none3 }}>
                    <Cradview media={media6} />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",

              pb: { xs: 0, md: 0 },
            }}
          >
            {showContactUsButton && (
              <Button
                onClick={() => {
                  window.open("/contact-us", "_blank"); // Open the "Contact Us" page in the same tab
                }}
                sx={{
                  color: "#fff",
                  backgroundColor: "#0E3347",
                  borderRadius: "50px",
                  textTransform: "none",
                  border: "2px solid #0E3347",
                  fontWeight: "700",
                  fontSize: { xs: "12px", sm: "15px" },
                  px: { xs: 4, sm: 4.5 },
                  "&:hover": {
                    border: "2px solid #0E3347",
                    color: "#295768",
                  },
                }}
              >
                Contact Us
              </Button>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",

              pb: { xs: 0, md: 0 },
            }}
          >
            {showContactUsButton1 && (
              <Button
                href="skype:live:.cid.24dd4a6e3e1d2c33?chat"
                target="blank"
                sx={{
                  color: "#fff",
                  backgroundColor: "#0E3347",
                  borderRadius: "50px",
                  textTransform: "none",
                  border: "2px solid #0E3347",
                  fontWeight: "700",
                  fontSize: { xs: "12px", sm: "15px" },
                  px: { xs: 4, sm: 4.5 },
                  "&:hover": {
                    border: "2px solid #0E3347",
                    color: "#295768",
                  },
                }}
              >
                Contact Us
              </Button>
            )}
          </Box>
        </AnimationOnScroll>
      </Box>
    </Box>
  );
}
