import { useState } from "react";

import { AnimationOnScroll } from "react-animation-on-scroll";

import { Box, Container, Grid, Paper, Typography } from "@mui/material";

import { equitysoftData } from "../../../utils/Constent/Home/HomeConstent";
import Typographyview from "../../CommonSectionweb/Typographyview";

const WhyChoose = () => {
  return (
    <Container
      sx={{ maxWidth: { xs: "xs", sm: "md", md: "md", lg: "lg", xl: "xl" } }}
    >
      <Box id="WhyChoose" sx={{ marginTop: { xs: "4rem", md: "5rem" } }}>
        <Box>
          <Typographyview
            value="Why choose us?"
            custom={
              <Typography
                variant="body1"
                color="#484848"
                fontWeight="400"
                sx={{
                  paddingTop: "1.5rem",
                }}
              >
                We are not just a web, mobile app and Software development
                agency <br /> we are your true growth partners for your business
                growth.
              </Typography>
            }
          />
        </Box>
        <Box>
          <Grid
            container
            spacing={{
              xs: 3,
              sm: 2,
              md: 5,
              lg: 3,
              xl: 4,
            }}
          >
            {equitysoftData.map((item, n) => {
              return (
                <Gridview key={n}>
                  {" "}
                  <ClientBoxview {...item} />
                </Gridview>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default WhyChoose;
function ClientBoxview({ image, onHover, title, subtitle }) {
  const [i, setI] = useState(image);
  return (
    <AnimationOnScroll animateIn="animate__fadeIn">
      <Box>
        <Box className="container">
          <Paper elevation={0}>
            <Box
              onMouseEnter={() => setI(onHover)}
              onMouseLeave={() => setI(image)}
              className="item "
              sx={{
                height: "180px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box>
                <img src={i} alt="" width="80px" className="box" />
              </Box>
              <Box>
                <Typography
                  variant="body1"
                  color="#0E3347"
                  fontWeight="800"
                  sx={{ textAlign: "center", textTransform: "capitalize" }}
                >
                  {title}
                </Typography>
                <Typography
                  variant="body1"
                  color="#0E3347"
                  fontWeight="800"
                  sx={{ textAlign: "center", textTransform: "capitalize" }}
                >
                  {subtitle}
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
    </AnimationOnScroll>
  );
}

function Gridview({ children }) {
  return (
    <Grid item xs={12} sm={6} lg={3}>
      {children}
    </Grid>
  );
}
