import React from "react";
import { Box } from "@mui/material";
import AIApp from "../../../Image/AIApp.png";

import ChildBanner from "../../CommonSection/ChildBanner";

const AIappdevelopment = () => {
  return (
    <Box>
      <ChildBanner
        value={"AI Development "}
        title={"AI App development"}
        subtitle={
          "Empower your business with AI-driven applications that revolutionize the way you operate. Our AI app development services combine cutting-edge technology with tailored solutions to address your unique needs."
        }
        subtitle1={
          "From predictive analytics to intelligent automation, we bring the future of AI to your fingertips. Stay ahead of the curve and transform your business with our AI app development expertise."
        }
        none1={"none"}
        image={AIApp}
        i={"row"}
       
        ex={{pr:{xs:"0",md:4,xl:6}}}
        width="150%"
        aos={"fade-down-left"}
      />
    </Box>
  );
};

export default AIappdevelopment;
