import { Box, Container, Link, Typography } from "@mui/material";
import React from "react";
import { SocialData } from "../../../../utils/Constent/ChildPage/CasestudyConstent";
import { Tooltip } from "@mantine/core";

export const SocialMedia = () => {
  return (
   
    <Box sx={{ position: "relative", width: "100%" }}>
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box>
          <Box sx={{ display: { xs: "none", sm: "flex" } }}>
            <Typography
            noWrap
              variant="body5"
              color="#484848"
              fontWeight="700"
              textAlign="center"
            >
              SOCIAL MEDIA{" "}
            </Typography>
          </Box>
          
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box>
              {SocialData.map((n,i) => {
                return (
                  <Box key={i}>
                    <IconView {...n} />
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
   
  );
};

function IconView({ media, title, href }) {
  return (
    <Link href={href} target={"_blank"}>
      <Box className="hover-effect5" sx={{ mt: 1 }}>
        <Tooltip
          label={title}
          color="dark"
          position="left"
          transitionProps={{ transition: "scale-y", duration: 300 }}
          withArrow
        >
          <img src={media} alt="" width="40px" href={href} />
        </Tooltip>
      </Box>
    </Link>
  );
}
