

import { Box} from "@mui/material";
import reactweb from "../../../Image/reactweb.png";
import Next from "../../../Image/Next.png";
import Word from "../../../Image/Word.png";
import Angular1 from "../../../Image/Angular1.png";
import React from "../../../Image/React.png";
import ChildBanner from "../../CommonSection/ChildBanner";

const ReactVuejs = () => {
  return (
    <Box>
      <ChildBanner
        value={"Web Development"}
        title={"React JS & Vue JS "}
        subtitle={
          "Revolutionize your online presence with React JS and Vue JS. These dynamic technologies allow us to craft stunning, responsive websites that captivate your audience."
        }
        subtitle1={
          "With their user-friendly interfaces and seamless performance, your website will not only stand out but also engage visitors like never before. Elevate your digital identity with these powerful tools."
        }
        image={reactweb}
        media={React}
        media1={Next}
        media2={Angular1}
        media3={Word}
        none2={"none"}
        ex={{pr:{xs:"0",md:4,xl:6}}}
        aos={"fade-down-left"}
        i={"row"}
      />
    </Box>
  )
}

export default ReactVuejs

