
import React from "react";
import { Box, Container, Typography } from "@mui/material";
// import { useNavigate } from "react-router-dom";

const BlogHeaderChild = ({
  value,
  title,
  tags,
}) => {
  return (
    <Container
      sx={{ maxWidth: { xs: "xs", sm: "md", md: "md", lg: "lg", xl: "lg" } }}
    >
      <Box sx={{ marginTop: { xs: "8rem", sm: "8rem", lg: "8rem" } }}>
        <Box
          sx={{
            textAlign: "center",
            px: 3,
            pb: { xs: 2, md: 2, lg: 3, xl: 3.5 },
          }}
        >
          <Typography variant="h3" color="#295768" fontWeight="700" sx={{textTransform:"capitalize"}}>
            {value}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="h1"
            color="#252525"
            fontWeight="400"
            sx={{
              textAlign: "center",
              pb: { xs: 2, md: 2, lg: 3, xl: 3 },
              lineHeight: 1.2,
            }}
          >
            {title}
          </Typography>
        </Box>
        <Box>
          {/* tags */}
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                display: { xs: "inline-block ", md: "flex" },
                justifyContent: "center",
                mb: 6,
              }}
            >
              {tags &&
                tags?.split(",").map((item,i) => <Box key={i}>
                  <TypographyView value={item} />
                </Box>)}
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default BlogHeaderChild;


function TypographyView({ value }) {
  return (
    <Box
      sx={{
        mt: 1.5,
        mx: { xs: 0, md: 1 },
        background: "#E0F6F9",
        py: 1,
        px: { xs: 3, md: 2, lg: 3 },
        // width: "100%",
        borderRadius: "5px",
      }}
    >
      <Box sx={{ textAlign: { xs: "center", md: "center" } }}>
        <Typography variant="body1" color="#0E3347" fontWeight="700">
          {value}
        </Typography>
      </Box>
    </Box>
  );
}
