import { Box, Container, Typography } from "@mui/material";
import React from "react";

const Productoverview = ({ data }) => {
  return (
    <Container
      sx={{ maxWidth: { xs: "xs", sm: "md", md: "md", lg: "lg", xl: "xl" } }}
    >
      <Box sx={{ marginTop: { xs: "4rem", sm: "4rem" } }}>
        <Box>
          <Box
            sx={{
              textAlign: "center",
              pb: { xs: 4, md: 4, lg: 7, xl: 7 },
            }}
          >
            <Typography
              variant="h3"
              color="#0E3347"
              fontWeight="700"
              className="Typographyview"
            >
              Product Overview
            </Typography>
          </Box>
          <Box>
              <Box sx={{ pb: 3}}>
              <Typography
                variant="h4"
                color="#295768"
                fontWeight="400"
                sx={{ textAlign: { xs: "center", sm: "start" } }}
              >
                Project{" "}
                <span style={{ color: "#0E3347", fontWeight: "700" }}>
                  Nature
                </span>
              </Typography>
            </Box>
            <Box
            sx={{ml: { xs: 2.5, sm: 3, lg: 3 },  }}
              component="div"
              dangerouslySetInnerHTML={{ __html: data?.content }}
            />
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Productoverview;
