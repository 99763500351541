import React, { useEffect, useState } from "react";

import copy from "clipboard-copy";
import moment from "moment/moment";

// import Send from "../../Image/Send.svg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  Link,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

// import Blogvideo from "../../Image/video.mp4";
// import { useNavigate } from "react-router-dom";
import {
  getAllBlog,
  getTechFest,
  updateBlogViewCount,
} from "../../../api/Blog";
import { getAllCategory } from "../../../api/Category";
import { API_URL } from "../../../api/config";
import { getVideo } from "../../../api/Services";
import { useNavigate } from "react-router-dom";
import { Blogview } from "../../../common/BlogView";

const TechBlog = () => {
  const [CategortList, setCategortList] = useState([]);

  const [BlogList, setBlogList] = useState([]);
  const [SelectedCate, setSelectedCate] = useState("");

  const [isLoading, setIsLoading] = useState();

  useEffect(() => {
    const getcategoty = async () => {
      const data = await getAllCategory();

      if (data?.s) setCategortList(data?.r);
    };

    getcategoty();
  }, []);

  const getBlog = async () => {
    setIsLoading(true);
    const data = await getAllBlog(SelectedCate);

    if (data?.s) setBlogList(data?.r);

    setIsLoading(false);
  };
  useEffect(() => {
    getBlog();
  }, [SelectedCate]);

  return (
    <Container
      sx={{ maxWidth: { xs: "xs", sm: "md", md: "md", lg: "lg", xl: "xl" } }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
          mt: 7,
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box>
            <Box>
              <Box
                sx={{
                  mb: { xs: 1.5, lg: 2 },
                  display: { xs: "flex", md: "none" },

                  textDecoration: "none",
                  outline: "none",
                }}
              >
                <FormControl
                  sx={{
                    minWidth: {
                      xs: "100%",
                      sm: 250,
                      md: 280,
                      lg: 300,
                      xl: 350,
                    },
                    backgroundColor: "#E0F6F9",
                    borderRadius: "15px",
                  }}
                >
                  <Select
                    sx={{
                      height: "45px",
                      color: "#0E3347",
                      borderRadius: "15px",

                      "& .MuiOutlinedInput-notchedOutline": {
                        // color: "#295768",
                        border: "1px solid #E0F6F9",
                      },
                      "& .MuiSvgIcon-root": {
                        color: "#295768;",
                      },
                    }}
                    value={SelectedCate}
                    onChange={(e) => setSelectedCate(e.target.value)}
                    displayEmpty
                    // inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value="">
                      <Typography variant="body2" color="#295768">
                        All
                      </Typography>
                    </MenuItem>

                    {CategortList.map((item) => (
                      <MenuItem value={item?.id} key={item?.id}>
                        <Typography variant="body2" color="#295768">
                          {item?.name}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              {/* Blog List */}
              <Box
                sx={{
                  mr: { xs: 0, md: 1 },
                }}
              >
                <Grid
                  sx={{ width: "100%" }}
                  container
                  spacing={{
                    xs: 3,
                    sm: 2,
                    md: 2,
                    lg: 3,
                    xl: 4,
                  }}
                >
                  {isLoading
                    ? [1, 2, 3, 4].map((i) => (
                        <Gridview key={i}>
                          <Stack
                            spacing={1}
                            boxShadow={3}
                            p={2}
                            borderRadius={3}
                          >
                            <Skeleton
                              variant="rounded"
                              sx={{ width: "100%", height: "300px" }}
                              animation="wave"
                            />
                            <Skeleton
                              variant="text"
                              width="100%"
                              sx={{ fontSize: "16px", borderRadius: "30px" }}
                              animation="wave"
                            />
                            <Skeleton
                              variant="text"
                              width="100%"
                              sx={{ fontSize: "16px", borderRadius: "30px" }}
                              animation="wave"
                            />
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                gap: 2,
                              }}
                            >
                              <Skeleton
                                variant="text"
                                width="100%"
                                sx={{ fontSize: "16px", borderRadius: "10px" }}
                                animation="wave"
                              />
                              <Skeleton
                                variant="rounded"
                                width={120}
                                height={30}
                                sx={{ borderRadius: "15px" }}
                                animation="wave"
                              />
                            </Box>
                          </Stack>
                        </Gridview>
                      ))
                    : BlogList?.map((item) => (
                        <Gridview key={item?.id}>
                          <Blogview data={item} />
                        </Gridview>
                      ))}
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Tech View */}
        {/* <Box>
          <Box sx={{ mt: { xs: 4, md: 0 } }}>
            <Companyview
              setSelectedCate={setSelectedCate}
              SelectedCate={SelectedCate}
            />
          </Box>
        </Box> */}
      </Box>
    </Container>
  );
};

export default TechBlog;

function Companyview({ SelectedCate, setSelectedCate, url }) {
  const navigator = useNavigate("");
  const [CategortList, setCategortList] = useState([]);
  const [VideoData, setVideoData] = useState(null);

  useEffect(() => {
    const getcategoty = async () => {
      const data = await getAllCategory();

      if (data?.s) setCategortList(data?.r);

      const videoRes = await getVideo();
      if (videoRes && videoRes.s) {
        setVideoData(API_URL.galleryVideo + videoRes.r[0].video);
      }
    };

    getcategoty();
  }, []);

  const [Techfest, setTechfest] = useState([]);

  useEffect(() => {
    const Techfestget = async () => {
      const data = await getTechFest();
      if (data?.s) setTechfest(data?.r[0]);
    };
    Techfestget();
  }, []);

  return (
    <>
      {/* category section */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          ml: { xs: 0, md: 2 },
          mb: 4,
        }}
      >
        <Box>
          <Box>
            <Box
              sx={{
                mb: { xs: 1.5, lg: 2 },
                display: { xs: "none", md: "flex" },
                justifyContent: "center",
                textDecoration: "none",
                outline: "none",
              }}
            >
              <FormControl
                sx={{
                  minWidth: { xs: "100%", sm: 250, md: 280, lg: 300, xl: 350 },
                  backgroundColor: "#E0F6F9",
                  borderRadius: "15px",
                }}
              >
                <Select
                  sx={{
                    height: "45px",
                    color: "#0E3347",
                    borderRadius: "15px",

                    "& .MuiOutlinedInput-notchedOutline": {
                      // color: "#295768",
                      border: "1px solid #E0F6F9",
                    },
                    "& .MuiSvgIcon-root": {
                      color: "#295768;",
                    },
                  }}
                  value={SelectedCate}
                  onChange={(e) => setSelectedCate(e.target.value)}
                  displayEmpty
                  // inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value="">
                    <Typography variant="body2" color="#295768">
                      All
                    </Typography>
                  </MenuItem>

                  {CategortList.map((item) => (
                    <MenuItem value={item?.id} key={item?.id}>
                      <Typography variant="body2" color="#295768">
                        {item?.name}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ mb: { xs: 1.5, lg: 2 }, pl: 2 }}>
              <Typography variant="h6" color="#295768" fontWeight="700">
                Company Strength
              </Typography>
            </Box>
          </Box>
          <Card
            sx={{
              borderRadius: "10px",
              backgroundColor: "#0E3347",
              py: { xs: 2, sm: 5, md: 3, lg: 6 },

              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box>
              <Box component="ul" sx={{ px: 5 }}>
                <Typography
                  variant="body2"
                  color="#fff"
                  fontWeight="700"
                  component="li"
                  sx={{
                    paddingBottom: "1rem",
                  }}
                >
                  500+ Projects Completed
                </Typography>
                <Typography
                  variant="body2"
                  color="#fff"
                  fontWeight="700"
                  component="li"
                  sx={{
                    paddingBottom: "1rem",
                  }}
                >
                  10+ Years of Experience
                </Typography>
                <Typography
                  variant="body2"
                  color="#fff"
                  fontWeight="700"
                  component="li"
                  sx={{
                    paddingBottom: "1rem",
                  }}
                >
                  280+ Global Customers
                </Typography>
                <Typography
                  variant="body2"
                  color="#fff"
                  fontWeight="700"
                  component="li"
                  sx={{
                    paddingBottom: "1rem",
                  }}
                >
                  50+ Development Staff
                </Typography>
                <Typography
                  variant="body2"
                  color="#fff"
                  fontWeight="700"
                  component="li"
                  sx={{
                    paddingBottom: "1rem",
                  }}
                >
                  35+ Country we Serve
                </Typography>
                <Typography
                  variant="body2"
                  color="#fff"
                  fontWeight="700"
                  component="li"
                >
                  15+ Specific Industry
                </Typography>
              </Box>
              <Box
                sx={{
                  background: "#fff",
                  mx: 2,
                  py: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 5,
                  cursor: "pointer",
                }}
              >
                <Typography variant="body1" color="#0E3347" fontWeight="700">
                  <Link
                    href="skype:hardik.rupareliya44?chat"
                    style={{ color: "#0E3347" }}
                  >
                    Talk to Our Expert
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Card>
        </Box>
      </Box>
      {/* video */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box sx={{ ml: { xs: 0, md: 2 } }}>
          <Card
            sx={{
              borderRadius: "10px",
              backgroundColor: "#E0F6F9",
              // height: "400px",
              width: { xs: "280px", md: "280px", lg: "300px", xl: "350px" },
              display: "flex",
              pb: 2,
            }}
          >
            <Box>
              <Box>
                <img
                  src={API_URL.techFestImage + Techfest?.image}
                  alt=""
                  width="100%"
                  height="100%"
                  style={{
                    aspectRatio: "510/560",
                  }}
                />
              </Box>

              <Box>
                <Typography
                  variant="h2"
                  color="#295768"
                  fontWeight="800"
                  sx={{ px: 2, mt: 1.5 }}
                >
                  {/* {Techfest?.title} */}
                  {Techfest?.title && (Techfest?.title + "").slice(0, 24)}
                  {Techfest?.title && Techfest?.title.length >= 24 && "..."}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ pl: 1.5, py: 1, px: 2 }}>
                  <Typography
                    variant="body2"
                    color="#252525"
                    fontWeight="800"
                    textTransform="capitalize"
                  >
                    {Techfest?.sub_title}
                  </Typography>
                </Box>
                <Box sx={{ pr: 1.5 }}>
                  <Button
                    onClick={() => navigator("/contact-us")}
                    sx={{
                      textTransform: "none",
                      backgroundColor: "#295768",
                      borderRadius: "50px",
                      px: 2.2,
                      "&:hover": {
                        background:
                          "linear-gradient(180deg, #295768 0%, #183038 100%)",
                      },
                    }}
                  >
                    <Typography variant="body2" color="#fff">
                      Book
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Card>
          <Box sx={{ mt: { xs: "2.5rem" } }}>
            <Box sx={{ my: 1.5 }}>
              <Typography variant="h6" color="#252525" fontWeight="700">
                Corporate Video{" "}
              </Typography>
            </Box>
            <Box
              sx={{
                width: { xs: "280px", md: "280px", lg: "300px", xl: "350px" },
              }}
            >
              {/* <img src={Blogvideo} alt="" width="100%"/> */}
              <video
                controls
                style={{
                  width: "100%",
                  borderRadius: "10px",
                }}
                src={VideoData}
              ></video>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

function Gridview({ children }) {
  return (
    <Grid item xs={12} sm={6} md={4}>
      {children}
    </Grid>
    // <Grid item xs={12} sm={6} md={12} lg={6}>
    //   {children}
    // </Grid>
  );
}
