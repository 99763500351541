import React from "react";

import { AnimationOnScroll } from "react-animation-on-scroll";

import { Box, Container, Grid, Link, Paper, Typography } from "@mui/material";

import { achievementData } from "../../../utils/Constent/Home/HomeConstent";
import Typographyview from "../../CommonSectionweb/Typographyview";
import Rating from "react-rating";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
const Achievement = () => {
  return (
    <Container
      sx={{ maxWidth: { xs: "xs", sm: "md", md: "md", lg: "lg", xl: "xl" } }}
    >
      <Box id="Achievement" sx={{ marginTop: { xs: "4rem", md: "5rem" } }}>
        <Box>
          <Typographyview
            value=" Our Achievements"
            subtitle="  Awards & Industry Recognition"
          />
        </Box>
        <Box sx={{
          width:'100%'
        }}>
          <Grid
            container
            spacing={3}
            // spacing={{
            //   xs: 3,
            //   sm: 2,
            //   md: 5,
            //   lg: 2,
            //   xl: 4,
            // }}
          >
              {achievementData.map((v, n) => {
                  return <Grid item xs={12} md={3} lg={3} xl={3}>
                    <Achievementview {...v} key={n} />
                    </Grid>
                })}
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default Achievement;

function Achievementview({ value, subtitle, image, href, rating }) {
  return (
    <AnimationOnScroll animateIn="animate__fadeIn">
      <Link href={href} target="_blank" sx={{ textDecoration: "none" }}>
        <Paper elevation={0}>
          <Box
            sx={{
              width: "100%",
              height: "200px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              // maxWidth: "250px",
              px: 5,
              background: "rgba(224, 246, 249, 1)",
              border: "5px solid rgba(224, 246, 249, 1)",
              transition: "border 0.5s",
              "&:hover": {
                border: "5px solid rgba(41, 87, 104, 1)",
              },
              borderRadius: "8px",
            }}
          >
            <Box>
              <img src={image} alt="" width="80px" />
            </Box>
            <Box
              sx={{
                textAlign: "center",
                marginTop: "1rem",
              }}
            >
              <Typography
                variant="body1"
                color="#0E3347"
                sx={{
                  fontWeight: "700",
                }}
              >
                {value}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="body1"
                  color="#0E3347"
                  sx={{
                    fontWeight: "700",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {rating}
                </Typography>
                {rating ? (
                  <Rating
                    emptySymbol={
                      <StarOutlineIcon
                        sx={{ fontSize: "22px", color: "gold" }}
                      />
                    }
                    fullSymbol={
                      <StarIcon sx={{ fontSize: "22px", color: "gold" }} />
                    }
                    size={6}
                    initialRating={rating}
                    readonly={true}
                  />
                ) : (
                  ""
                )}
              </Box>
              {/* <Typography
                variant="body2"
                fontWeight="400"
                color="#484848"
                sx={{
                  paddingTop: "0.5rem",
                }}
              >
                {subtitle}
              </Typography> */}
            </Box>
          </Box>
        </Paper>
      </Link>
    </AnimationOnScroll>
  );
}

function GrideView({ children }) {
  return (
    <Grid item xs={12} sm={6} lg={2}>
      {children}
    </Grid>
  );
}
