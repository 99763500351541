import { Box} from "@mui/material";


import NoSQL from "../../../Image/NoSQL.png";

import ChildBanner from "../../CommonSection/ChildBanner";

const Nosql = () => {
  return (
    <Box>
      <ChildBanner
        value={"Database Management"}
        title={"NoSQL"}
        subtitle={
          "Step into the future of data management with NoSQL databases. Our NoSQL solutions offer the flexibility to handle unstructured and complex data, supporting modern applications' dynamic requirements."
        }
        subtitle1={
          "Whether it's document-oriented, key-value, or graph databases, we tailor NoSQL solutions to fit your specific needs, enabling innovation and agility in your digital journey."
        }
        image={NoSQL}
        
        none1={"none"}
        ex={{pr:{xs:"0",md:4,xl:6}}}
        aos={"fade-down-left"}
        i={"row"}
      />
    </Box>
  )
}

export default Nosql

