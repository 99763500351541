import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";

const YourProject = ({ url }) => {
  const navigator = () => {
    window.open(url);
  };
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#0E3347",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: { xs: "100%", sm: "300px" },
        marginTop: { xs: "4rem", sm: "5rem" },
        // mb: "5rem",
      }}
    >
      <Box sx={{ py: 5 }}>
        <Box>
          <Typography
            variant="h1"
            color="#fff"
            sx={{
              textAlign: "center",
              fontWeight: "700",
              pb: 3,
              letterSpacing: "1px",
            }}
          >
            LET’S DISCUSS YOUR IDEA
          </Typography>

          <Typography
            variant="body2"
            color="#eee"
            sx={{
              letterSpacing: "1.2px",
              textAlign: "center",
              fontWeight: "400",
              px: 2,
            }}
          >
            Get a free consultation and let us know your vision to pivot it into
            a unique digital creation.
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <AnimationOnScroll animateIn="animate__rubberBand" delay={100}>
            <Button
              onClick={() => navigator((url = "/contact-us"))}
              className="arrow"
              sx={{
                border: "1px solid #eee",
                borderRadius: "50px",
                px: 3,
                mt: { xs: 3, md: 5 },
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body2"
                color="#fff"
                textAlign="center"
                sx={{ pr: 2, fontWeight: "400", letterSpacing: "1px" }}
              >
                Talk to our Proficients
              </Typography>
            </Button>
          </AnimationOnScroll>
        </Box>
      </Box>
    </Box>
  );
};

export default YourProject;
