import React from 'react';

import {
  Box,
  Container,
} from '@mui/material';
import Typography from '@mui/material/Typography';

const HeaderView = ({value,value1,subtitle}) => {
    return (
        <Box
            sx={{
               
                marginTop: { xs: "3rem", sm: "4.4rem" },
                background: "linear-gradient(180deg, #295768 0%, #183038 100%)",
                display: "flex",
                justifyContent: { xs: "center", sm: "start" },
                alignItems: "center",
            }}
        >
            <Container sx={{maxWidth:{xs:"xs",sm:"md",md:"md",lg:"lg",xl:"xl"}}}>
            <Box
             data-aos="fade-right" data-aos-duration="1500"  data-aos-offset="200"
                sx={{
                    marginBlock: "3rem",
                }}
            >
                <Typography
                 maxWidth="65rem"
                    variant="h3"
                    color="#fff"
                    fontWeight="700"
                    sx={{ textAlign:"start" }}
                >
                  {value}
                </Typography>
                <Typography
                    variant="body1"
                    color="#fff"
                    fontWeight="400"
                    sx={{
                        paddingTop: "1rem",
                        textAlign:  "start",
                        
                    }}
                >
                   {subtitle}
                </Typography>
            </Box>
            </Container>
        </Box>
    )
}

export default HeaderView