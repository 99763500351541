import { Box } from "@mui/material";
import React from "react";
import BlogHeaderChild from "../../CommonSection/BlogHeaderChild";

const Details = ({ blog }) => {
  return (
    <Box>
      <BlogHeaderChild
        none1={"none"}
        value={blog?.title}
        title={blog?.sub_title}
        value1={"Application Design"}
        value2={"Web Design"}
        value3={"Android Design"}
        value4={"IOS Design"}
        tags={blog?.tags}
      />
    </Box>
  );
};

export default Details;
