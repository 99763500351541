
import { Box} from "@mui/material";
import FrontBackEnd1 from "../../../Image/FrontBackEnd.png";
import Next from "../../../Image/Next.png";
import React from "../../../Image/React.png";
import Js from "../../../Image/Js.png";
import Firebase from "../../../Image/Firebase.png";
import Laravel from "../../../Image/Laravel.png";
import Express from "../../../Image/Express.png";

import ChildBanner from "../../CommonSection/ChildBanner";

const FrontbackEnd = () => {
  return (
    <Box>
      <ChildBanner
        value={"Web Developer"}
        title={"Front-End, Back-End Developer "}
        subtitle={
          "We have a highly skilled team of front-end developers who are dedicated to delivering business solutions that add value to your organization. You can hire dedicated front-end developers who can assist you in achieving the most iconic design language and user-friendly applications or websites."
        }
        subtitle1={
          "Our Backend development services are designed to contribute to your business's success significantly. We have highly skilled Backend developers dedicated to building scalable, robust, and secure backend solutions."
        }
        media={Next}
        media1={React}
        media2={Express}
        media3={Js}
        media4={Laravel}
        media5={Firebase}
        none3={"none"}
        image={FrontBackEnd1}
        i={"row"}
        ex={{pr:{xs:"0",md:4,xl:6}}}
        aos={"fade-down-left"}
      />
    </Box>
  )
}

export default FrontbackEnd

