import React from "react";

import { Box, Button, Container, Typography } from "@mui/material";

const HeaderChild = ({
  value,
  subtitle,
  title,
  title1,
  none,
  none1,
  value1,
  value2,
  value3,
  value4,
  none2,
  tags,
}) => {
  return (
    <Container
      sx={{ maxWidth: { xs: "xs", sm: "md", md: "md", lg: "lg", xl: "xl" } }}
    >
      <Box sx={{ marginTop: { xs: "8rem", sm: "8rem", lg: "8rem" } }}>
        <Box
          sx={{
            textAlign: "center",
            px: 3,
            pb: { xs: 2, md: 2, lg: 3, xl: 3 },
          }}
        >
          <Typography
            variant="h3"
            textTransform="capitalize"
            color="#295768"
            fontWeight="700"
          >
            {value}
          </Typography>
        </Box>

        <Box>
          <Webview
            subtitle={subtitle}
            title={title}
            title1={title1}
            none={none}
            none1={none1}
            value1={value1}
            value2={value2}
            value3={value3}
            value4={value4}
            none2={none2}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default HeaderChild;

function Webview({
  subtitle,
  title,
  title1,
  none,
  none1,
  value1,
  value2,
  value3,
  value4,
  url,
  none2,
}) {
  const navigator = () => {
    window.open(url);
  };

  return (
    <Box>
      <Box>
        <Box>
          <Typography
            variant="h5"
            color="#0E3347"
            fontWeight="700"
            sx={{
              pb: { xs: 3, md: 3, lg: 3.8, xl: 3 },
              textAlign: "center",
              display: none1,
            }}
          >
            {subtitle}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h1"
              color="#252525"
              fontWeight="500"
              sx={{
                width: { xs: "100%", sm: "100%", md: "60rem", lg: "75rem" },
                textAlign: "center",
                // pb: { xs: 2, md: 2, lg: 3, xl: 3 },
                lineHeight: 1.2,
              }}
            >
              {title}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="body2"
            color="#484848"
            fontWeight="400"
            sx={{
              pt: { xs: 2, md: 2, lg: 3, xl: 3 },
              pb: { xs: 2, md: 3 },
              width: { xs: "100%", sm: "100%", md: "60rem", lg: "65rem" },
              textAlign: "center",
              display: none1,
            }}
          >
            {title1}
          </Typography>
        </Box>
        <Box sx={{ display: none }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",

              mb: { xs: 2, md: 5 },
            }}
          >
            <Box
              sx={{
                display: { sm: "flex", md: "inline-block" },
              }}
            >
              <Box
                sx={{
                  display: { sm: "inline-block ", md: "flex" },
                  pr: { sm: 2, md: 0 },
                }}
              >
                <Box>
                  <TypographyView value={value1} />
                </Box>
                <Box sx={{ px: { xs: 0, md: 2 }, py: { xs: 2, md: 0 } }}>
                  <TypographyView value={value2} />
                </Box>
                <Box>
                  <TypographyView value={value3} />
                </Box>
                <Box sx={{ px: { xs: 0, md: 2 }, py: { xs: 2, md: 0 } }}>
                  <TypographyView value={value4} />
                </Box>
                <Box sx={{ display: { xs: "none", md: "flex" } }}>
                  <Box sx={{ display: none1 }}>
                    <TypographyView value="IOS developer" />
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: none1 }}>
                <Box
                  sx={{
                    display: { xs: "inline-block ", md: "flex" },
                    justifyContent: "center",
                    mt: { xs: 0, md: 2 },
                    pl: { sm: 2, md: 0 },
                  }}
                >
                  <Box sx={{ display: { xs: "flex", md: "none" } }}>
                    <TypographyView value="IOS developer" />
                  </Box>
                  <Box sx={{ py: { xs: 2, md: 0 } }}>
                    <TypographyView value="Cloud App developer " />
                  </Box>

                  <Box sx={{ px: { xs: 0, md: 2 } }}>
                    <TypographyView value="Cloud Web developer " />
                  </Box>
                  <Box sx={{ py: { xs: 2, md: 0 } }}>
                    <TypographyView value="Digital Marketing " />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: none2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 1,
            }}
          >
            <Button
              onClick={() => navigator((url = "/portfolio"))}
              sx={{
                display: none1,
                color: "#fff",
                backgroundColor: "#0E3347",
                borderRadius: "50px",
                textTransform: "none",
                border: "2px solid #0E3347  ",
                fontWeight: "700",
                fontSize: { xs: "12px", sm: "15px" },
                px: { xs: 4, sm: 4.5 },

                "&:hover": {
                  border: "2px solid #0E3347",

                  color: "#295768",
                },
              }}
            >
              Portfolio
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function TypographyView({ value }) {
  return (
    <Box
      sx={{
        mt: 1.5,
        mx: { xs: 0, md: 1 },
        background: "#E0F6F9",
        py: 1,
        px: { xs: 3, md: 2, lg: 3 },
        width: "100%",
        borderRadius: "5px",
      }}
    >
      <Box sx={{ textAlign: { xs: "center", md: "center" } }}>
        <Typography variant="body1" color="#0E3347" fontWeight="700">
          {value}
        </Typography>
      </Box>
    </Box>
  );
}
