import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import Contact from '../../Service/ServiceScreen/Contact'
import YourProject from '../Screen/AppPage/YourProject'
import CreativeUi from '../Screen/HirePage/CreativeUi'
import FrontbackEnd from '../Screen/HirePage/FrontbackEnd'
import Hireapp from '../Screen/HirePage/Hireapp'
import HireCloudApp from '../Screen/HirePage/HireCloudApp'
import Hirededicated from '../Screen/HirePage/Hirededicated'
import Hiredeveloper from '../Screen/HirePage/Hiredeveloper'

const HirePage = () => {
  useEffect(() => window.document.scrollingElement?.scrollTo(0, 0), [])
  return (
  <Box>
    <Hiredeveloper/>
    <YourProject/>
    <CreativeUi/>
    <Hireapp/>
    <FrontbackEnd/>
    <HireCloudApp/>
    <Hirededicated/>
    <Contact/>
  </Box>
  )
}

export default HirePage