import { Box } from "@mui/material";
import React, { useEffect } from "react";
import Counter from "../../Home/Screen/Counter";
import Banner from "../ServiceScreen/Banner";
import Contact from "../ServiceScreen/Contact";
import Dedicated from "../ServiceScreen/Dedicated";
import Offer from "../ServiceScreen/Offer";
import ServiceScreen from "../ServiceScreen/ServiceScreen";

const Service = () => {
  useEffect(() => window.document.scrollingElement?.scrollTo(0, 0), []);
  return (
    <Box>
      <ServiceScreen />
      <Banner />
      <Dedicated />
      <Offer/>
      <Counter />
      <Contact />
    </Box>
  );
};

export default Service;
