import pythonapp from "../../../Image/pythonapp.png";


import ChildBanner from "../../CommonSection/ChildBanner";
import { Box } from "@mui/material";

const AiDigital = () => {
  return (
<Box>
      <ChildBanner
        value={"AI"}
        title={"Digital Virtual Assistance"}
        subtitle={
          "Introducing your digital workforce - a team of virtual assistants that work tirelessly to enhance your productivity. Our digital virtual assistance services leverage AI and automation to handle repetitive tasks, manage schedules, and streamline workflows."
        }
        subtitle1={
          "Experience increased efficiency and focus on what matters most – growing your business. Embrace the future of work with digital virtual assistance."
        }
        none1={"none"}
        image={pythonapp}
        i={"row"}
        width="150%"
      ex={{pr:{xs:"0",md:4,xl:6}}}
      aos={"fade-down-left"}

      />
    </Box>  )
}

export default AiDigital