import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React from "react";

import Slider from "react-slick";

import { Box, Button, Container, Typography } from "@mui/material";

import Hire from "../../../Component/Image/Hire.svg";
import {
  dedicatedData,
  teamData,
} from "../../../utils/Constent/Service/ServiceConstent";
import Typographyview from "../../CommonSectionweb/Typographyview";

const Dedicated = ({ url }) => {
  var settings = {
    dots: true,
    slidesToShow: 2,
    rows: 2,
    infinite: true,
    autoplay: true,
    // cssEase: "linear",
    // overflow:"hidden",
    dotsClass: "button__bar",
    pauseOnHover: false,

    responsive: [
      {
        breakpoint: 1528,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 899,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const navigator = () => {
    window.open(url);
  };
  return (
    <Container
      sx={{ maxWidth: { xs: "xs", sm: "md", md: "md", lg: "lg", xl: "xl" } }}
    >
      <Box sx={{ marginTop: { xs: "4rem", sm: "5rem" } }}>
        <Box>
          <Typographyview
            value="Hire Dedicated Developer from Equitysoft"
            subtitle="Visionary behind Equitysoft"
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              {dedicatedData.map((v, n) => {
                return (
                  <Box key={n}>
                    <Hireview {...v} />
                  </Box>
                );
              })}
              <Box
                sx={{
                  marginTop: "2rem",
                }}
              >
                <Button
                  onClick={() => navigator((url = "/hire-software-developers"))}
                  sx={{
                    color: "#fff",
                    backgroundColor: "#295768",
                    border: "1px solid #295768",
                    borderRadius: "50px",
                    textTransform: "none",
                    fontWeight: "500",
                    fontSize: { xs: "12px", sm: "15px" },
                    px: { xs: 4, sm: 4.5 },
                    py: { xs: 1, sm: 1 },
                    "&:hover": {
                      border: "1.5px solid #295768",
                      color: "#295768",
                    },
                  }}
                >
                  Hire Dedicated Developers
                </Button>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              maxWidth: { xs: "100%", md: "50%", lg: "50%" },
              mt: { xs: 4, md: 0 },
            }}
          >
            <Slider {...settings}>
              {teamData.map((e) => (
                <Box key={e}>
                  <Teamview {...e} />
                </Box>
              ))}
            </Slider>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Dedicated;

function Hireview({ value, subtitle }) {
  return (
    <Box
      sx={{
        marginBottom: "1rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: "1",
        }}
      >
        <Box>
          <img src={Hire} alt="" width="20px" />
        </Box>
        <Box sx={{ paddingLeft: "0.5rem" }}>
          <Typography
            variant="h2"
            color="#252525"
            fontWeight="700"
            sx={{
              paddingBottom: "1rem",
              textTransform: "capitalize",
            }}
          >
            {value}
          </Typography>
          <Typography
            variant="body2"
            color="#484848"
            fontWeight="400"
            sx={{
              width: { xs: "100%", md: "95%" },
            }}
          >
            {subtitle}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

function Teamview({
  value,
  subtitle,
  media,
  image,
  bgcolor,
  hovercolor,
  mx,
  mr,
}) {
  return (
    <Box
      sx={{
        display: "flex",
        overflow: "hidden",
        mb: 2,
        justifyContent: "center",
        mx: mx,
        mr: mr,
      }}
    >
      <Box
        className="container2"
        sx={{
          p: 2,
          width: "95%",
          borderRadius: "15px",
          height: { xs: "250px", md: "230px", lg: "250px" },
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          border: "1px solid #20B69F",

          "&:hover": {
            border: "none",
          },
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "1rem",
            }}
          >
            <img src={media} alt="" width="100px" />
          </Box>
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <Typography
              variant="body1"
              fontWeight="500"
              color="#20B69F"
              sx={{
                paddingTop: "1rem",
                textTransform: "capitalize",
              }}
            >
              {value}
            </Typography>
          </Box>
        </Box>

        {/* Overview Hover */}

        <Box
          className="overlay2"
          sx={{ overflow: " hidden", borderRadius: "15px" }}
        >
          <Box
            className="text2"
            sx={{
              width: "100%",
              borderRadius: "15px",
              mb: 2,
              height: "250px",
              overflow: "hidden",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: bgcolor,
              border: "none",
              "&:hover": {
                border: "none",
              },
            }}
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "1rem",
                }}
              >
                <img src={image} alt="" width="100px" />
              </Box>
              <Box
                sx={{
                  textAlign: "center",
                  "&:hover": {
                    color: "#fff",
                  },
                }}
              >
                <Typography
                  variant="body1"
                  fontWeight="700"
                  sx={{
                    paddingTop: "1rem",
                    color: hovercolor,
                    textTransform: "capitalize",
                  }}
                >
                  {subtitle}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
