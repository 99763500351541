import { Box} from "@mui/material";
import ChatGPT1 from "../../../Image/ChatGPT.png";
import React from "../../../Image/React.png";
import ChildBanner from "../../CommonSection/ChildBanner";

const ChatGPT = () => {
  return (
    <Box>
      <ChildBanner
        value={"AI"}
        title={"Chat GPT"}
        subtitle={
          "Meet your AI-powered virtual assistant, Chat GPT. It's more than just a chatbot; it's a conversational AI that understands and engages with your customers on a whole new level."
        }
        subtitle1={
          "With Chat GPT, you can deliver personalized customer experiences, automate routine tasks, and provide instant support. Elevate your customer engagement and streamline operations with the help of Chat GPT."
        }
       
        none1={"none"}
        image={ChatGPT1}
        i={"row"}
       
      ex={{pr:{xs:"0",md:4,xl:6}}}
      aos={"fade-down-left"}

      />
    </Box>
  );
};

export default ChatGPT;

