import React from 'react';

import { AnimationOnScroll } from 'react-animation-on-scroll';

import {
  Box,
  Container,
  Grid,
  Typography,
} from '@mui/material';

import { visionariesData } from '../../../utils/Constent/About/AboutConstent';
import Typographyview from '../../CommonSectionweb/Typographyview';

const Visionaries = () => {
  return (
    <Box sx={{ marginTop: { xs: "4rem", sm: "5rem" } }}>
      
      <Box>
    <Typographyview value="Our Visionaries" subtitle="Who sowed the seeds of Equitysoft"/>
   </Box>
   <Container sx={{maxWidth:{xs:"xs",sm:"md",md:"md",lg:"lg",xl:"xl"}}}>
      <Box>
        <Grid
          container
          spacing={{
            xs: 3,
            sm: 2,
            md: 5,
            lg: 3,
            xl: 4,
          }}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          {visionariesData.map((e,n) => {
            return (
              <GrideView key={n}>
                <Visionariesview
                {...e}
                />
              </GrideView>
            );
          })}
        </Grid>
      </Box>
      </Container>
    </Box>
  );
};

export default Visionaries;

function Visionariesview({ image, value, subtitle, detail, name }) {
  return (
    <AnimationOnScroll animateIn="animate__fadeInUpBig">
      <Box   className="hovereffect"  sx={{ display: "flex", justifyContent: "center", transition: "all 0.5s ease 0s" }}>
        <Box
          className="container"
          sx={{
            width: { xs: "100%", sm: "100%" },
            height: "380px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#E0F6F9",
            
          }}
        >
          <Box>
            <Box sx={{ display: "flex", justifyContent: "center"}}>
              <img src={image} alt="" width="130px" height="130px" style={{borderRadius:"100%" ,objectFit:"cover",objectPosition:"top",background:"#fff"}} />
            </Box>
            <Box
              sx={{
                textAlign: "center",
                "&:hover": {
                  color: "#fff",
                },
              }}
            >
              <Typography
                variant="h5"
                fontWeight="800"
                color="#0E3347"
                sx={{
                  paddingTop: "1rem",
                  textTransform:"capitalize"
                }}
              >
                Mr. {value}
              </Typography>
              <Typography
                fontWeight="500"
                variant="body2"
                color="#484848"
                sx={{
                  paddingTop: "0.5rem",
                  
                }}
              >
                {subtitle}
              </Typography>
            </Box>
          </Box>

          {/* Overview Hover */}

          <Box className="overlay">
            <Box
              className="text"
              sx={{
                width: "100%",
                p: 2,
                height: "380px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                boxShadow: " 0px 4px 8px rgba(38, 38, 38, 0.2)",
                backgroundColor: "#0E3347",
              }}
            >
              <Box
                sx={{
                  textAlign: "center",

                  color: "#fff",
                }}
              >
                <Typography
                  variant="body2"
                  color="#E0F6F9"
                  sx={{
                    fontWeight: "300",
                  }}
                >
                  {detail}
                </Typography>
                <Typography
                  variant="body1"
                  color="#fff"
                  sx={{
                    paddingTop: "1rem",
                    fontWeight: "500",
                    textTransform:"capitalize"
                  }}
                >
                    Mr. {name}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </AnimationOnScroll>
  );
}

function GrideView({ children }) {
  return (
    <Grid item xs={12} sm={6} md={6} lg={4}>
      {children}
    </Grid>
  );
}
