import { Box } from "@mui/material";
import AIconsulting1 from "../../../Image/AIconsulting.png";

import ChildBanner from "../../CommonSection/ChildBanner";

const AIconsulting = () => {
  return (
    <Box>
    <ChildBanner
      value={"AI consulting"}
      title={"AI consulting Service"}
      subtitle={
        "Empower your business with AI-driven applications that revolutionize the way you operate. Our AI app development services combine cutting-edge technology with tailored solutions to address your unique needs."
      }
      subtitle1={
        "From predictive analytics to intelligent automation, we bring the future of AI to your fingertips. Stay ahead of the curve and transform your business with our AI app development expertise."
      }
      
      image={AIconsulting1}
      none1={"none"}
      i={"row-reverse"}
      none={"none"}
      ex={{pl:{xs:"0",md:4,xl:6}}}
      width="150%"
      aos={"fade-down-right"}
    />
    </Box>
  );
};

export default AIconsulting;

