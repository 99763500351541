import Affordablelunch from "../../../Component/Image/Affordablelunch.png";
import AppDeveloper from "../../../Component/Image/AppDeveloper.png";
import Bde from "../../../Component/Image/BDE.png";
import Drinks from "../../../Component/Image/Drinks.png";
import Flexiblehiring from "../../../Component/Image/Flexiblehiring.png";
import FlutterDeveloper from "../../../Component/Image/FlutterDeveloper.png";
import HealthInsurance from "../../../Component/Image/HealthInsurance.png";
import Leave from "../../../Component/Image/Leave.png";
import PerformanceBonus from "../../../Component/Image/PerformanceBonus.png";
import Trip from "../../../Component/Image/Trip.png";
import UIUXDesigner from "../../../Component/Image/UIUXDesigner.png";
import Webdeveloper from "../../../Component/Image/Webdeveloper.png";
import er from "../../../Component/Image/01er.png";
import pr from "../../../Component/Image/02pr.png";
import tr from "../../../Component/Image/03tr.png";
import hr from "../../../Component/Image/hrr.png";
import hr1 from "../../../Component/Image/04hr1.png";


//OpenPositionsData
export const OpenPositionsData = [
  { media: UIUXDesigner, value: "UI UX Designer" },
  { media: AppDeveloper, value: "App Developer" },
  { media: Webdeveloper, value: "Web developer" },
  { media: FlutterDeveloper, value: "Flutter Developer" },
  { media: Bde, value: "BDE" },
];

//Interview ProcessData

export const interviewprocessData = [
  {
    image: er,
    value: "Evaluation Skill Round",
    data: [
      {
        subtitle: "1. Accept candidate profiles",
      },
      {
        subtitle: "2. Screen Profiles",
      },
      {
        subtitle: "3. Evaluate & Eligibility ",
      },
      {
        subtitle: "4. initial HR Interview ",
      },
    ],
  },
  {
    image: pr,
    value: "Practical Round",
    data: [
      {
        subtitle: "1. Aptitude test ",
      },
      {
        subtitle: "2. Technical Test",
      },
      {
        subtitle: "3. Skill Test (as applicable) ",
      },
    ],
  },
  {
    image: tr,
    value: "Technical Round",
    data: [
      {
        subtitle: "1. Basic level",
      },
      {
        subtitle: "2. Advance level",
      },
      {
        subtitle: "3. Expert level",
      },
    ],
  },
  {
    image: hr,
    image1: hr1,
    none: "none",
    value: "HR Round",

    data: [
      {
        subtitle: "1. Company Policies",
      },
      {
        subtitle: "2. Company culture & perks",
      },
      {
        subtitle: "3. Expert level",
      },
      {
        subtitle: "4. Answer your questions",
      },
    ],
  },
];

// Who We Are Looking For Data

export const BoxviewData = [
  {
    fadedown: "animate__fadeInDown",
    no: "01.",
    value: "Initiators",
    subtitle:
      "We don't practice hand-holding. We welcome ideas from every individual be it from a junior or senior.",
  },
  {
    fadedown: "animate__fadeInUp",
    no: "02.",
    value: "Passionate",
    subtitle:
      "We are all passionate individuals. Passionate about a variety of things. This passion motivates us to work",
  },
  {
    fadedown: "animate__fadeInDown",
    no: "03.",
    value: "Lit Communication Skills",
    subtitle:
      "We require individuals with quick decision-making skills and excellent communication skills.",
  },
  {
    fadedown: "animate__fadeInUp",
    no: "04.",
    value: "Zealous",
    subtitle:
      "Perseverance is the secret to success in any field. We're here to accomplish some incredible work, and we're sincere in what we're doing.",
  },
  {
    fadedown: "animate__fadeInDown",
    no: "05.",
    value: "Esth Cutlets",
    subtitle:
      "People who are enthusiastic for work & learnings and believe in growing together.",
  },
];

// What You Can Expect Equitysoft Data

export const ExpectData = [
  {
    value: "Technical Training",
    subtitle:
      "We offer technical training in Flutter Development from the basic to the advanced level.",
  },
  {
    value: "Soft-Skill Training",
    subtitle:
      "We organize different skilled workshops for employees' enhancement in soft skills.",
  },
  {
    value: "Personal Development",
    subtitle:
      "Not just one’s forte, we give chances to employees to work above their streams and experience new things on the go.",
  },
  {
    value: "Environment",
    subtitle:
      "A culture-rich environment of like-minded people to work and grow together. Ideas are always welcomed, be it from a senior or junior colleague.",
  },
];

//Why Join Equitysoft Data

export const WhyjoinData = [
  {
    fadedown: "animate__fadeInDown",
    rem: { xs: "3rem", sm: "5rem" },
    textcolor: "#5B0000",
    media: Flexiblehiring,
    value: "Flexible Hiring",
    subtitle: "Models",
  },
  {
    fadedown: "animate__fadeInUp",
    textcolor: "#6A5300",
    media: Affordablelunch,
    value: "Affordable",
    subtitle: "Lunch",
  },
  {
    fadedown: "animate__fadeInDown",
    rem: { xs: "3rem", sm: "5rem" },
    textcolor: "#6A5300",
    media: Drinks,
    value: "Free Snacks &",
    subtitle: "Drinks",
  },
  {
    fadedown: "animate__fadeInUp",
    textcolor: "#006D53",
    media: PerformanceBonus,
    value: "Performance",
    subtitle: "Bonus",
  },
  {
    fadedown: "animate__fadeInDown",
    rem: { xs: "3rem", sm: "5rem" },
    textcolor: "#07005E",
    media: HealthInsurance,
    value: "Health",
    subtitle: "Insurance",
  },
  {
    fadedown: "animate__fadeInUp",
    textcolor: "#420061",
    media: Leave,
    value: "Maternity -",
    subtitle: "Paternity Leave",
  },
  {
    fadedown: "animate__fadeInDown",
    rem: { xs: "3rem", sm: "5rem" },
    textcolor: "#66004F",
    media: Trip,
    value: "Yearly Company",
    subtitle: "Trip",
  },
];
