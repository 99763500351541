import React from 'react';

import { AnimationOnScroll } from 'react-animation-on-scroll';

import {
  Box,
  Container,
  Typography,
} from '@mui/material';

const OurProcess = () => {
  return (
    <Box>
      <Box
        sx={{
          textAlign: "center",
          marginTop: { xs: "4rem", sm: "4rem" } ,
          mb:{xs:"3rem",sm:"6.8rem"}
        }}
      >
       <AnimationOnScroll animateIn="animate__fadeInDown" delay={100}>
        <Typography variant="h1" color="#0E3347" fontWeight="700" className='Typographyview' sx={{cursor:"pointer"}} >
          Our Process
        </Typography>
        </AnimationOnScroll>

        <AnimationOnScroll animateIn="animate__fadeInUp" delay={100}>
        <Typography
          variant="body1"
          fontWeight="400"
          color="#0E3347"
          sx={{
            paddingTop: "0.8rem",
         
          }}
        >
          The process we work by to craft stunning visuals.
        </Typography>
      </AnimationOnScroll>
      </Box>
      <Box    boxShadow={6} sx={{ backgroundColor: "#0E3347",}}>
      <Container sx={{maxWidth:{xs:"xs",sm:"md",md:"md",lg:"lg",xl:"xl"}}}>
      <Box
        sx={{
          pb:5,
          display:"flex",
          justifyContent:"space-between",
          flexDirection:{xs:"column",sm:"row"},
          
        }}
      >
        
        <Box>
          <Processview number="1" value="Understanding the design needs" />
        </Box>
        <Box>
          <Processview number="2" value="Research & Analysis " />
        </Box>
        <Box>
          <Processview number="3" value="Wire Framing & UI Designing "  />
        </Box>
        <Box>
          <Processview number="4" value="Prototyping and  Testing" />
        </Box>
      </Box>
      </Container>
      </Box>
    </Box>
  );
};

export default OurProcess;

function Processview({number,value}) {
  return (
    <Box sx={{  position:"relative", }}>
      <Box sx={{
    
      }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            
            pt:2
          }}
        >
          <Typography
            boxShadow={6}
            variant="h4"
            color="#295768"
            fontWeight="700"
            sx={{ px: 2.5, py: 1.2, backgroundColor: "#fff", mr:2,position:{xs:"unset",sm:"absolute"},
            bottom:"90%",}}
          >
            {number}
          </Typography>
          <Typography variant="body1" color="#fff" fontWeight="400" sx={{pt:{xs:0,sm:5},width:{sm:"9rem",md:"12rem",lg:"13rem"},textTransform:"capitalize"}}>
       

            {value}
          </Typography>
        </Box>
        <Box >
          
        </Box>
      </Box>
    </Box>
  );
}
