import React from 'react'
import { Box,Typography } from "@mui/material";

const Typographyview1 = ({value,subtitle}) => {
  return (
    <Box
        sx={{
          textAlign: "center",
          letterSpacing:"1px",
          mb:{xs:"3rem",sm:"4rem"},
        
        }}
      >
        <Typography
          variant="h1"
          color="#0E3347"
          fontWeight="700"
          data-aos="fade-down"
          className='Typographyview'
           sx={{cursor:"pointer"}}
        >
         {value}
        </Typography>
        <Typography
          data-aos="fade-up"
          variant="body1"
          color="#484848"
          sx={{
            paddingTop: "1.5rem",
          }}
        >
          {subtitle}
        </Typography>
      </Box>
  )
}

export default Typographyview1