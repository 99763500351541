import { Box } from "@mui/material";
import React from "react";
import ourworkdata from "../../../Image/ourworkdata.png";
import Ourwork from "../../CommonSection/Ourwork";

const OurWorkdata = () => {
  return (
    <Box>
      <Ourwork
        media={ourworkdata}
        i="row-reverse"
        ex={{ pl: { xs: "0", md: 4, xl: 6 } }}
      />
    </Box>
  );
};

export default OurWorkdata;
