import React, { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { Tooltip } from "@mantine/core";
import { Box, Container, Divider, Link } from "@mui/material";
import Typography from "@mui/material/Typography";

import Behance1 from "../../Image/Behance.svg";
import EquityLogo from "../../Image/EquityLogo.png";
import Facebook from "../../Image/Facebook.svg";
import Insta from "../../Image/Insta.svg";
import Linkedin from "../../Image/Linkedin.svg";
import Location from "../../Image/Location.svg";
import Mail from "../../Image/Mail.svg";
import Phone from "../../Image/Phone.svg";
import Skype from "../../Image/Skype.svg";
import FooterCard from "./FooterCard";
import { LastData } from "../../../utils/Constent/Home/HomeConstent";
import { Companyinfodata } from "../../../utils/Constent/FooterConstent/FooterConstent";

const Footer = () => {
  const location = useLocation();
  const isCareerPage = location.pathname === "/career";
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentYear(new Date().getFullYear());
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);
  const socialMediaData = [
    {
      media: Linkedin,
      label: "Linkedin",
      href: "https://www.linkedin.com/company/equitysoft",
    },
    {
      media: Skype,
      label: "Skype",
      href: isCareerPage
        ? "skype:live:.cid.24dd4a6e3e1d2c33?chat"
        : "skype:hardik.rupareliya44?chat",
    },
    {
      media: Facebook,
      label: "Facebook",
      href: "https://www.facebook.com/equitysoft.in?mibextid=ZbWKwL",
    },
    {
      media: Insta,
      label: "Instagram",
      href: "https://www.instagram.com/equitysoft/",
    },
    {
      media: Behance1,
      label: "Behance",
      href: "https://www.behance.net/equitysoft",
    },
  ];

  return (
    <Box
      sx={{
        position: "relative",
        // marginTop: { xs: "12rem", md: "12rem" },
        mt : 6,
        display: "flex",
        justifyContent: "center",
      }}
    >
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          top: 0,
          mt: "-7rem",
          zIndex: "1",
        }}
      >
        <FooterCard />
      </Box> */}

      <Box
        sx={{
          backgroundColor: "#0E3347",
          width: "100%",
        }}
      >
        <Container
          sx={{
            maxWidth: { xs: "xs", sm: "md", md: "md", lg: "lg", xl: "xl" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "space-between",
              alignItems: "center",
              // paddingTop: { xs: "9rem", sm: "9rem", md: "8.6rem" },
              pt: 7,
            }}
          >
            <Box
              sx={{
                width: { xs: "200px", md: "250px" },
                marginBottom: { xs: "0.5rem", md: "0" },
              }}
            >
              <img src={EquityLogo} alt="" width="100%" />
            </Box>
            <Box
              sx={{
                width: { xs: "100%", md: "60%" },
                textAlign: { xs: "center", md: "start" },
              }}
            >
              <Typography variant="body2" color="#DDDDDD" fontWeight="300">
                A versatile Mobile App Development Company in Gujarat, India. We
                develop and design, web and mobile apps, Cloud Computing and
                Digital Marketing.
              </Typography>
            </Box>
          </Box>
          <Box>
            <Dividerview />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: { xs: "column", sm: "row" },
              marginBlock: "2rem",
            }}
          >
            <Box>
              <Box>
                {Companyinfodata.map((n, index) => (
                  <Box key={index} sx={index % 2 !== 0 ? { my: 2 } : {}}>
                    <Footerview {...n} />
                  </Box>
                ))}
              </Box>
              <Box sx={{ display: "flex", mt: 1, flexWrap: "wrap" }}>
                {socialMediaData.map((item, index) => (
                  <Box key={index} sx={index % 2 !== 0 ? { px: 2 } : {}}>
                    <SocialIcon {...item} index={index} />
                  </Box>
                ))}
              </Box>
            </Box>
            <div></div>
            <Box sx={{ display: { xs: "none", sm: "inline-block" } }}>
              <Box>
                <Linkview
                  heading="Help"
                  value="Contact us"
                  navigate="/contact-us"
                />
                <Linkview
                  blank={"_blank"}
                  none="none"
                  value="Privacy & Policy"
                  navigate="/privacy-policy"
                />

                <Linkview
                  none="none"
                  blank={"_blank"}
                  value="Terms of Service"
                  navigate="/term-of-service"
                />
                <Linkview
                  none="none"
                  blank={"_blank"}
                  value="Refund Policy"
                  navigate="/refund-policy"
                />

                <Linkview none="none" value="Career" navigate="/career" />
              </Box>
              <Box sx={{ mt: 3 }}>
                <Linkview
                  heading="Our Expertise"
                  value="UI UX Design"
                  navigate="/ui-ux-design-agency"
                />
                <Linkview
                  none="none"
                  value="Mobile App Development"
                  navigate="/app-development-services"
                />

                <Linkview
                  none="none"
                  value="Web Development"
                  navigate="/web-development-services"
                />

                <Linkview
                  none="none"
                  value="Hire Dedicated Developers"
                  navigate="/hire-software-developers"
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: "none", sm: "inline-block" },
                // justifyContent: "center",
              }}
            >
              <Box>
                <Linkview
                  heading="About Equitysoft"
                  value="About Us"
                  navigate="/about"
                />
                <Linkview
                  none="none"
                  value="Our Portfolio"
                  navigate="/portfolio"
                />

                <Linkview none="none" value="Our Service" navigate="/service" />

                <Linkview none="none" value="Our Blog" navigate="/blog" />
                <Box
                  sx={{ mx: { xs: 0, sm: 1.5 }, mr: { xs: 1, sm: 0 }, mt: 0.8 }}
                >
                  <Typography
                    component={Link}
                    href="https://www.google.com/maps/dir//Equitysoft+Technologies+Pvt.+Ltd.,+C+405,+Ganesh+Glory+11+Sarkhej+-+Gandhinagar+Highway,+Jagatpur+Rd,+Ahmedabad,+Gujarat+382470/@23.114166,72.5381015,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x395e83174e8dff25:0x9986b25f59688893!2m2!1d72.5402493!2d23.11408?entry=ttu"
                    target="_blank"
                    variant="body1"
                    color="#DDDDDD"
                    fontWeight="300"
                    sx={{
                      cursor: "pointer",
                      textDecoration: "none",
                      mb: 3,
                      "&:hover": {
                        color: "#7AC0DA",
                      },
                    }}
                  >
                    Sitemap
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ mt: 3 }}>
                <Linkview
                  heading="Tech Expertise"
                  value="AI Development"
                  navigate="/ai-development-services"
                />
                <Linkview
                  none="none"
                  value="Database Management"
                  navigate="database-management-services"
                />

                <Linkview
                  none="none"
                  value="Cloud Computing"
                  navigate="/cloud-computing-services"
                />

                <Linkview
                  none="none"
                  value="Digital Marketing"
                  navigate="/digital-marketing-agency"
                />
              </Box>
            </Box>

            <Box sx={{ display: { xs: "flex", sm: "none" } }}>
              <Box
                sx={{
                  display: { xs: "flex", sm: "none" },
                  // justifyContent: "center",
                  marginTop: { xs: "1rem", sm: "0" },
                }}
              >
                <Box sx={{ pr: 0 }}>
                  <Linkview
                    heading="Help"
                    value="Contact us"
                    navigate="/contact-us"
                  />
                  <Linkview
                    blank={"_blank"}
                    none="none"
                    value="Privacy & Policy"
                    navigate="/privacy-policy"
                  />

                  <Linkview
                    blank={"_blank"}
                    none="none"
                    value="Terms of Service"
                    navigate="/term-of-service"
                  />
                  <Linkview
                    blank={"_blank"}
                    none="none"
                    value="Refund Policy"
                    navigate="/refund-policy"
                  />

                  <Linkview none="none" value="Career" navigate="/career" />

                  <Box sx={{ pr: 0, mt: 2 }}>
                    <Linkview
                      heading="Our Expertise"
                      value="UI UX Design"
                      navigate="/ui-ux-design-agency"
                    />
                    <Linkview
                      none="none"
                      value="Mobile App Development"
                      navigate="/app-development-services"
                    />

                    <Linkview
                      none="none"
                      value="Web Development"
                      navigate="/web-development-services"
                    />

                    <Linkview
                      none="none"
                      value="Hire Dedicated Developers"
                      navigate="/hire-software-developers"
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: { xs: "flex", sm: "none" },
                  // justifyContent: "center",
                  marginTop: { xs: "1rem", sm: "0" },
                }}
              >
                <Box>
                  <Box sx={{ pl: 0 }}>
                    <Linkview
                      heading="About US"
                      value="Our Service"
                      navigate="/service"
                    />
                    <Linkview
                      none="none"
                      value="Our Portfolio"
                      navigate="/portfolio"
                    />
                    <Box>
                      <Linkview
                        none="none"
                        value="Case Study"
                        navigate="Casestudy"
                      />
                    </Box>

                    <Linkview none="none" value="Our Blog" navigate="/blog" />
                    <Box
                      sx={{
                        mx: { xs: 0, sm: 1.5 },
                        mr: { xs: 1, sm: 0 },
                        mt: 0.8,
                      }}
                    >
                      <Typography
                        component={Link}
                        href="https://www.google.com/maps/dir//Equitysoft+Technologies+Pvt.+Ltd.,+C+405,+Ganesh+Glory+11+Sarkhej+-+Gandhinagar+Highway,+Jagatpur+Rd,+Ahmedabad,+Gujarat+382470/@23.114166,72.5381015,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x395e83174e8dff25:0x9986b25f59688893!2m2!1d72.5402493!2d23.11408?entry=ttu"
                        target="_blank"
                        variant="body1"
                        color="#DDDDDD"
                        fontWeight="300"
                        sx={{
                          cursor: "pointer",
                          textDecoration: "none",
                          mb: 3,
                          "&:hover": {
                            color: "#7AC0DA",
                          },
                        }}
                      >
                        Sitemap
                      </Typography>
                    </Box>

                    {/* <Linkview
                      blank={"_blank"}
                      none="none"
                      value="Sitemap"
                      href="https://www.google.com/maps/dir//Equitysoft+Technologies+Pvt.+Ltd.,+C+405,+Ganesh+Glory+11+Sarkhej+-+Gandhinagar+Highway,+Jagatpur+Rd,+Ahmedabad,+Gujarat+382470/@23.114166,72.5381015,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x395e83174e8dff25:0x9986b25f59688893!2m2!1d72.5402493!2d23.11408?entry=ttu"
                    /> */}
                  </Box>
                  <Box sx={{ pl: 0, mt: 2 }}>
                    <Linkview
                      heading="Tech Expertise"
                      value="AI Development"
                      navigate="/ai-development-services"
                    />
                    <Linkview
                      none="none"
                      value="Database Management"
                      navigate="database-management-services"
                    />

                    <Linkview
                      none="none"
                      value="Cloud Computing"
                      navigate="/cloud-computing-services"
                    />

                    <Linkview
                      none="none"
                      value="Digital Marketing"
                      navigate="/digital-marketing-agency"
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            <Dividerview />
          </Box>
          <Box
            sx={{
              marginBlock: "1.5rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              {" "}
              <LastView value={`@${currentYear}`} />
              {LastData.map((n, i) => {
                return (
                  <Box key={i}>
                    <LastView {...n} index={i} />
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Footer;
function Dividerview() {
  return (
    <Box>
      <Divider
        sx={{
          background: "#AAAAAA",
          marginTop: "2rem",
        }}
      />
    </Box>
  );
}
function Footerview({
  image,
  value,

  numbertext,
  numbertext1,
  href,
  href1,
  href2,

  title,
  media,
}) {
  return (
    <Box>
      <Typography variant="body1" color="#fff" fontWeight="700">
        {title}
      </Typography>
      <Box sx={{ display: "flex", my: 1 }}>
        <Box sx={{ mr: 1 }}>
          <img src={image} alt="" width="20px" />
        </Box>

        <Link href={href} target={"_blank"} sx={{ textDecoration: "none" }}>
          <Typography
            variant="body2"
            color="#DDDDDD"
            fontWeight="300"
            sx={{
              maxWidth: { xs: "12rem", md: "18rem" },
              "&:hover": {
                color: "#7AC0DA",
              },
            }}
          >
            {value}
          </Typography>
        </Link>
      </Box>
      <Box sx={{ display: "flex", alignItems: "start" }}>
        <Box sx={{ mr: 1 }}>
          <img src={media} alt="" width="20px" />
        </Box>

        <Box>
          <Link href={href1} target={"_blank"} sx={{ textDecoration: "none" }}>
            <Typography
              variant="body2"
              color="#DDDDDD"
              fontWeight="300"
              sx={{
                mt: 0.2,
                "&:hover": {
                  color: "#7AC0DA",
                },
              }}
            >
              {numbertext}
            </Typography>
          </Link>
          <Link href={href2} target={"_blank"} sx={{ textDecoration: "none" }}>
            <Typography
              variant="body2"
              color="#DDDDDD"
              fontWeight="300"
              sx={{
                mt: 0.2,
                "&:hover": {
                  color: "#7AC0DA",
                },
              }}
            >
              {numbertext1}
            </Typography>
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
function Linkview({ value, heading, navigate, href, url, none }) {
  const navigator = () => {
    window.open(url);
  };
  return (
    <Box sx={{ mx: { xs: 0, sm: 1.5 }, mr: { xs: 1, sm: 0 } }}>
      <Box sx={{ mb: 1 }}>
        <Typography
          variant="body1"
          color="#fff"
          fontWeight="700"
          sx={{ display: none, marginBottom: "1.2rem" }}
        >
          {heading}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "grid",
        }}
      >
        <Box
          onClick={() => navigator((url = navigate))}
          // onClick={() => navigator(navigate)}
        >
          <Typography
            component={Link}
            href={href}
            target="_blank"
            variant="body1"
            color="#DDDDDD"
            fontWeight="300"
            sx={{
              cursor: "pointer",
              textDecoration: "none",
              mb: 3,
              "&:hover": {
                color: "#7AC0DA",
              },
            }}
          >
            {value}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

function LastView({ value, index }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Typography
        sx={{ display: "flex", justifyContent: "center" }}
        variant="body1"
        color="#EEE"
        fontWeight="400"
      >
        {value}
        <Typography
          sx={{
            px: { xs: 0.7, sm: 2 },
            display: index - 1 !== 0 ? "flex" : "none",
          }}
        >
          |
        </Typography>
      </Typography>
    </Box>
  );
}

function SocialIcon({ label, media, href, index }) {
  return (
    <Box>
      <Link href={href} target={index === 1 ? "_self" : "_blank"}>
        <Tooltip
          label={label}
          color="dark"
          position="bottom"
          transitionProps={{ transition: "scale-y", duration: 300 }}
          withArrow
        >
          <img src={media} alt="" width="35px" />
        </Tooltip>
      </Link>
    </Box>
  );
}
