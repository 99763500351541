import React from "react";
import { Box } from "@mui/material";
import HeaderChild from "../../CommonSection/HeaderChild";
const WebDevelopment = () => {
  return (
    <Box>
      <HeaderChild
        none={"none"}
        value={"Web Development"}
        subtitle={"WHAT WE DO"}
        title={
          "We make all kinds of web-based software and ensure an excellent experience for web users."
        }
        title1={
          "We build various solutions, including web design, front-end development, back-end development, and server-side scripting. Not only build, we maintain them, too."
        }
      />
    </Box>
  );
};

export default WebDevelopment;
