import { Box } from "@mui/material";
import React from "react";
import HeaderChild from "../../CommonSection/HeaderChild";

const AppDevelopment = () => {
  return (
    <Box>
      <HeaderChild
        none={"none"}
        value={"App Development"}
        subtitle={"WHAT WE DO"}
        title={
          "We mould Custom-Built Android and iOS Solutions for diverse industries."
        }
        title1={
          "Equitysoft's Design team is a separate unit comprising a skilled team of UXDesigners, UI Designers, and Graphic Designers."
        }
      />
    </Box>
  );
};

export default AppDevelopment;
