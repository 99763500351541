import pythonapp from "../../../Image/pythonapp.png";
import Android1 from "../../../Image/Android.png";
import Kotlin from "../../../Image/Kotlin.png";
import Java from "../../../Image/Java.png";
import React from "../../../Image/React.png";
import ChildBanner from "../../CommonSection/ChildBanner";
import { Box } from "@mui/material";

const Pythonapp = () => {
  return (
<Box>
      <ChildBanner
        value={"Python Mobile Application"}
        title={"Python Development"}
        subtitle={
          "Simplicity and efficiency are the cornerstones of Python Development for mobile apps. Python's ease of use, combined with frameworks like Kiva and BeeWare, helps create user-friendly, dynamic mobile apps."
        }
        subtitle1={
          "Whether you're a seasoned entrepreneur or new to the scene, Python provides a welcoming platform to transform your app dreams into reality. Join innovators who've embraced Python's capabilities and embark on a journey of powerful, user-centric app development. Your vision, realized with Python."
        }
        media={Android1}
        media1={React}
        media2={Kotlin}
        media3={Java}
        none2={"none"}
        image={pythonapp}
        i={"row"}
        width="150%"
      ex={{pr:{xs:"0",md:4,xl:6}}}
      aos={"fade-down-left"}

      />
    </Box>  )
}

export default Pythonapp