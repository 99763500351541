import React, { useState } from 'react';

import { AnimationOnScroll } from 'react-animation-on-scroll';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';

import {
  Box,
  Button,
  Container,
  Link,
  Typography,
} from '@mui/material';

import Behance1 from '../../../Component/Image/Behance1.png';
import Dribbble1 from '../../../Component/Image/Dribbble1.png';
import Pinterest from '../../../Component/Image/Pinterest.png';

const Ourwork = ({media,i,ex}) => {
  return (
    <Box
    sx={{
      backgroundColor: "#E0F6F9",
      marginTop: { xs: "4rem", sm: "5rem" } ,
      py: 6,
      

    }}
  >
     <Container sx={{maxWidth:{xs:"xs",sm:"md",md:"md",lg:"lg",xl:"xl"}}}>
    <Box>
    <Box
            sx={{
             
              display: "flex",
              justifyContent: { xs: "start", md: "start" },
              pt:0,
              mb:{xs:"2rem",sm:"2rem",lg:"3rem"}
            }}
          >
            <Box sx={{ pl: 0 }}>
              <AnimationOnScroll animateIn="animate__fadeInDown" delay={100}>
                <Typography
                  variant="h1"
                  color="#0E3347"
                  fontWeight="700"
                  className='Typographyview'
                  sx={{ textAlign: { xs: "start", md: "start" } }}
                >
                  Our Work
                </Typography>
              </AnimationOnScroll>
              <AnimationOnScroll animateIn="animate__fadeInUp" delay={100}>
                <Typography
                  variant="body2"
                  fontWeight="400"
                  color="#0E3347"
                  sx={{ textAlign: { xs: "start", md: "start" }, pt:2 }}
                >
                  We treat our team the same way we treat our clients - with
                  honesty and respect.
                </Typography>
              </AnimationOnScroll>
            </Box>
          </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: i },
        }}
      >
        <Box sx={{ ...ex}}>
        
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              
            }}
          >
            <Box sx={{ width: { xs: "100%", md: "100%", lg: "100%" } }}>
              <img src={media} alt="" width="100%" />
            </Box>
          </Box>
        </Box>
        <Box>
          <Counterview />
        </Box>
      </Box>
    </Box>
    </Container>
  </Box>
  )
}

export default Ourwork

function Cradview({ media }) {
    return (
      <Box
      
        sx={{
          display: "flex",
          justifyContent: "start",
          borderRadius: "10px",
        }}
      >
        <img src={media} alt="" width="50px" />
      </Box>
    );
  }
  function Counterview({url}) {
    const navigator = () =>{
      window.open(url)
    }
    return (
      <Box
        sx={{
          mt: { xs: 2, md: 0},
          display: "flex",
          justifyContent: "start",
        }}
      >
        <Box>
          <Box sx={{ mb: 2, textAlign: { xs: "start", md: "start" } }}>
            <Typography variant="h2" color="#0E3347" fontWeight="700">
              What we done
            </Typography>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "block" } }}>
            <Box sx={{ mb: 5, pr: { xs: 4, md: 0 } }}>
              <Counterboxview value="300" subtitle="Projects" />
            </Box>
            <Box sx={{ mb: 5 }}>
              <Counterboxview value="2000" subtitle="Hours" />
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Link href="https://www.behance.net/equitysoft" target={"_blank"}>
            <Cradview media={Behance1} />
            </Link>
            <Box
              sx={{
                px: 1.5,
              }}
            >
              {" "}
              <Link href="https://dribbble.com/equitysoft" target={"_blank"}>
              <Cradview media={Dribbble1} />
              </Link>
            </Box>
  
            <Cradview media={Pinterest} />
          </Box>
          <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "start", md: "start" },
            pt: { xs: 4, md: 4},
          }}
        >
          <Button
          onClick={() => navigator(url = ("/Ourportfolio"))}
            sx={{
              color: "#fff",
              backgroundColor: "#0E3347",
              borderRadius: "50px",
              textTransform: "none",
              border: "2px solid #0E3347  ",
              fontWeight: "700",
              fontSize: { xs: "12px", sm: "12px" },
              
              px: { xs: 4, sm:4 },
              "&:hover": {
                border: "2px solid #0E3347",
                color: "#295768",
              },
            }}
          >
            Portfolio
          </Button>
        </Box>
        </Box>
      </Box>
    );
  }
  
  function Counterboxview({ value, subtitle }) {
    const [counterOn, setCounterOn] = useState(false);
    return (
      <ScrollTrigger
        onEnter={() => setCounterOn(true)}
        onExit={() => setCounterOn(false)}
      >
        <Box>
          <Typography variant="body1" color="#252525" fontWeight="700">
            {subtitle}
          </Typography>
          <Typography variant="h1" color="#295768" fontWeight="700">
            {counterOn && <CountUp end={value} start={0} duration="1" />}+
          </Typography>
        </Box>
      </ScrollTrigger>
    );
  }
  