import React from 'react';

import {
  Box,
  Container,
  Typography,
} from '@mui/material';

const Statement = ({ data }) => {
  return (
    <Container sx={{ maxWidth: { xs: "xs", sm: "md", md: "md", lg: "lg", xl: "xl" } }}>
      <Box sx={{ marginTop: { xs: "4rem", sm: "4rem" }, }}>
        <Box

          sx={{
            textAlign: "center",
            pb: { xs: 4, md: 4, lg: 7, xl: 9 },


          }}

        >
          <Box className='Typographyview'>
            <Typography variant="h3" color="#0E3347" fontWeight="700">
              Problem statement
            </Typography>
            <Typography variant="h3" color="#0E3347" fontWeight="700">
              and challenges
            </Typography>
          </Box>
        </Box>
        <Box >
          <Box >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: { xs: "column", sm: "row" },
           
            height: "100%",

          }}
        >
          <Box sx={{
            pr: { xs: 0, sm: 1 },
            width: "100%",   
            
          }}>
            <Box sx={{
              border: "2px solid #DDD",
              height: "100%",
              p: 2, borderRadius: "5px", mx: { xs: 0, sm: 0 }
            }}>
              <Box sx={{ mb: { xs: 2, sm: 4 } }}>
                <Typography
                  variant="h4"
                  color="#0E3347"
                  sx={{

                    letterSpacing: "2px",
                    fontWeight: "700",
                  }}
                >
                  Problem{" "}
                  <span style={{ color: "#295768", fontWeight: "400" }}>
                  Challenges{" "}
                  </span>
                </Typography>
              </Box>
              <Box >
                <Box sx={{ ml: { xs: 3, sm: 4 },}}>
                  <Typography variant="body1" color="#484848"  maxWidth="40rem" dangerouslySetInnerHTML={{ __html: data?.prblm_stat }}>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ mt: { xs: 3, sm: 0 }, pl: { xs: 0, sm: 1 }, width: "100%",    }}>
            <Box sx={{
              border: "2px solid #DDD",
              height: "100%",
              p: 2, borderRadius: "5px", mx: { xs: 0, sm: 0 }
            }}>
              <Box sx={{ mb: { xs: 2, sm: 4 } }}>
                <Typography
                  variant="h4"
                  color="#0E3347"
                  sx={{

                    letterSpacing: "2px",
                    fontWeight: "700",
                  }}
                >
                  Problem{" "}
                  <span style={{ color: "#295768", fontWeight: "400" }}>
                    Solutions{" "}
                  </span>
                </Typography>
              </Box>
              <Box >
                <Box sx={{ ml: { xs: 3, sm: 4 } }}>
                  <Typography variant="body1" maxWidth="40rem" color="#484848" dangerouslySetInnerHTML={{ __html: data?.prblm_sol }}>

                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Statement;

function Statementview({ value, subtitles, title, title1, title2, title3 }) {
  return (
    <Box sx={{
      border: "2px solid #DDD",

      p: 2, borderRadius: "5px", mx: { xs: 0, sm: 0 }
    }}>
      <Box sx={{ mb: { xs: 2, sm: 4 } }}>
        <Typography
          variant="h4"
          color="#0E3347"
          sx={{

            letterSpacing: "2px",
            fontWeight: "700",
          }}
        >
          Problem{" "}
          <span style={{ color: "#295768", fontWeight: "400" }}>
            Statement{" "}
          </span>
        </Typography>
      </Box>
      <Box component="ul">
        <Box sx={{ ml: { xs: 3, sm: 4 } }}>
          <Typography variant="body1" color="#484848" component="li">
            {value}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
