import { Box } from "@mui/material";
import React from "react";
import Ui from "../../../Image/Uidesign1.png";
import xd from "../../../Image/XD.png";
import Sketch1 from "../../../Image/Sketch1.png";
import Figma1 from "../../../Image/Figma1.png";
import ChildBanner from "../../CommonSection/ChildBanner";

const UIDesign = () => {
  return (
    <Box>
      <ChildBanner
        value={"Visual Design "}
        title={"UI Design"}
        subtitle={
          "We create an appealing and consistent look and feel for your website. This process includes choosing colors, typography, images, icons, and other graphic elements that align with your brand and user preferences. "
        }
        subtitle1={
          "We do not create just aesthetically pleasing designs but are also functional and easy to use. Our design aligns with the brand and user preferences and helps to communicate the purpose and value of the website or application."
        }
        media={Figma1}
        media1={xd}
        media2={Sketch1}
        image={Ui}
        i={"row-reverse"}
        ex={{pl:{xs:"0",md:4,xl:6}}}
        none={"none"}
        aos={"fade-down-right"}
        
        none2={"none"}
      />
    </Box>
  );
};

export default UIDesign;

