import { Box } from "@mui/material";
import wordpress from "../../../Image/wordpress.png";
import Express from "../../../Image/Express.png";
import Word from "../../../Image/Word.png";
import Firebase from "../../../Image/Firebase.png";
import Js from "../../../Image/Js.png";
import ChildBanner from "../../CommonSection/ChildBanner";

const Wordpress = () => {
  return (
<Box>
    <ChildBanner
      value={"Web Development "}
      title={"Wordpress "}
      subtitle={
        "Simplify your website management with WordPress. Our tailored solutions ensure effortless content updates, keeping your online presence current and appealing."
      
      }
      subtitle1={
        "We offer a user-friendly platform that empowers you to control your content effortlessly. Discover the ease and versatility of WordPress with us."
      }
      media={Express}
      media1={Js}
      media2={Word}
      media3={Firebase}
      image={wordpress}
      i={"row-reverse"}
      none2={"none"}
      ex={{pl:{xs:"0",md:4,xl:6}}}
      aos={"fade-down-right"}
    />
    </Box>
  )
}

export default Wordpress