import React, { useEffect } from "react";

import { Box } from "@mui/material";

import Blog from "../../Home/Screen/Blog";
import Contact from "../../Service/ServiceScreen/Contact";
import { PortfolioCasestudy } from "../Screen/CasestudyChildPage/PortfolioCasestudy";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../Firebase";

const CasestudyChildPage = () => {
  useEffect(() => {
    logEvent(analytics, "page_view", {
      page_location: window.location.href + window.location.search,
      page_path: window.location.href + window.location.search,
      page_title: "CaseStudy Page",
    });
  }, []);
  useEffect(() => window.document.scrollingElement?.scrollTo(0, 0), []);

  return (
    <Box>
      {/* <ChildCase /> */}
      <PortfolioCasestudy />
      <Blog />
      <Contact />
    </Box>
  );
};

export default CasestudyChildPage;
