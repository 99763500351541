import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React from "react";

import Slider from "react-slick";

import { Box, Skeleton } from "@mui/material";

import { API_URL } from "../../../../api/config";

const Mobilescreen = ({ data, loading }) => {
  var settings = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    overflow: "hidden",
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1536,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Box sx={{ mt: 7, overflow: "hidden" }}>
      <Slider {...settings}>
        {loading
          ? [1, 2, 3, 4, 5, 6, 7, 8, 9].map(() => (
              <Box>
                <Skeleton
                  variant="rounded"
                  animation="wave"
                  width="90%"
                  sx={{
                    borderRadius: "1rem",
                    height:"90vh"
                  }}
                />
              </Box>
            ))
          : data?.images?.map((n, e) => {
              return (
                <Box key={e}>
                  <Systemview data={n} index={e} />
                </Box>
              );
            })}
      </Slider>
    </Box>
  );
};

export default Mobilescreen;
function Systemview({ data }) {
  console.log("Image URL:", API_URL.portfolioImages + data?.image);
  return (
    <Box
      className="hovereffect"
      sx={{
        display: "flex",
        justifyContent: "center",
        pt: 3,
        transition: "all 0.5s ease 0s",
      }}
    >
      <Box
        sx={{
          overflow: "scroll",
          borderRadius: "32px",
          width: { xs: "90%", md: "85%" },
          // height: "100vh",

          aspectRatio: "375 / 812",
          filter: "drop-shadow(2px 2px 5px rgba(38, 38, 38, 0.3))",
          // pt: index % 2 !== 0 ? "2rem" : "0",
          "&::-webkit-scrollbar": { display: "none" },
        }}
      >
        <Box>
          <img
            src={API_URL.portfolioImages + data?.image}
            alt=""
            width="100%"
            height="100%"
            style={{
              cursor: "pointer",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}



// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// import React from "react";

// import Slider from "react-slick";

// import { Box, Skeleton } from "@mui/material";

// import { API_URL } from "../../../../api/config";
// import pod from "../../../Image/pod3.png"
// import pod1 from "../../../Image/pod2.png"


// const Mobilescreen = ({ data, loading }) => {
//   var settings = {
//     infinite: true,
//     slidesToShow:2,
//     slidesToScroll: 1,
//     autoplay: true,
//     speed: 3000,
//     autoplaySpeed: 3000,
//     cssEase: "linear",
//     overflow: "hidden",
//     pauseOnHover: false,
//     responsive: [
//       {
//         breakpoint: 1536,
//         settings: {
//           slidesToShow: 3,
//           slidesToScroll: 1,
//           infinite: true,
//         },
//       },
//       {
//         breakpoint: 1440,
//         settings: {
//           slidesToShow: 3,
//           slidesToScroll: 1,
//           infinite: true,
//         },
//       },
//       {
//         breakpoint: 950,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 1,
//           infinite: true,
//         },
//       },
//       {
//         breakpoint: 650,
//         settings: {
//           slidesToShow: 1.5,
//           slidesToScroll: 2,
//           initialSlide: 2,
//         },
//       },
//       {
//         breakpoint: 500,
//         settings: {
//           slidesToShow: 1.5,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };
//   return (
//     <Box sx={{ mt: 7, overflow: "hidden" }}>
//       <Slider {...settings}>
//         {loading
//           ? [1, 2, 3, 4, 5, 6, 7, 8, 9].map(() => (
//               <Box>
//                 <Skeleton
//                   variant="rounded"
//                   animation="wave"
//                   width="90%"
//                   sx={{
//                     borderRadius: "1rem",
//                     height:"90vh"
//                   }}
//                 />
//               </Box>
//             ))
//           : data?.images?.map((n, e) => {
//               return (
//                 <Box key={e}>
//                   <Systemview data={n} index={e} />
//                 </Box>
//               );
//             })}
//       </Slider>
//     </Box>
//   );
// };

// export default Mobilescreen;
// function Systemview({ data }) {
//   console.log("Image URL:", API_URL.portfolioImages + data?.image);
//   return (
//     <Box
//       className="hovereffect"
//       sx={{
//         display: "flex",
//         justifyContent: "center",
//         pt: 3,
//         transition: "all 0.5s ease 0s",
//       }}
//     >
//       <Box
//         sx={{
//           // overflow: "scroll",
//           // borderRadius: "32px",
//           width: { xs: "90%", md: "95%" },
//           // height: "100vh",
         
//           filter: "drop-shadow(2px 2px 5px rgba(38, 38, 38, 0.3))",
//           // pt: index % 2 !== 0 ? "2rem" : "0",
//           "&::-webkit-scrollbar": { display: "none" },
//         }}
//       >
//         <Box>
//           <img
//             src={pod}
//             alt=""
//             width="100%"
//             height="100%"
//             style={{
//               cursor: "pointer",
            
//             }}
//           />
        
//         </Box>
//       </Box>
//     </Box>
//   );
// }

