import React, { useState } from 'react';

import ExpandCircleDownOutlinedIcon
  from '@mui/icons-material/ExpandCircleDownOutlined';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Divider,
  Typography,
} from '@mui/material';

import { OfferData } from '../../../utils/Constent/Service/ServiceConstent';
import Typographyview from '../../CommonSectionweb/Typographyview';
import Blog2 from '../../Image/Blog2.png';

const Offer = ({expand}) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange =
    (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <Container sx={{maxWidth:{xs:"xs",sm:"md",md:"md",lg:"lg",xl:"xl"}}}>
    <Box sx={{ marginTop: { xs: "4rem", sm: "5rem" } ,}}>
      <Box>
        <Typographyview value="What We Offer"  none="none" />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: {xs:"center",md:"space-between"},
          flexDirection:{xs:"column",md:"row"},
        }}
      >
     
        <Box sx={{
          display: "flex",
          justifyContent:"center",
        }}>
        
          <Box sx={{ 
            width:"100%" ,
            pr:{xs:"0",lg:2,xl:2}
            }}>
            <img src={Blog2} alt="" width="100%" />
          </Box>
        </Box>
        <Box sx={{ display: "flex",
          justifyContent:"center",}}>
        <Box sx={{ width:"100%",pl:{xs:"0",md:2,lg:2,xl:2} }}>
          {OfferData.map((e,n) => {
            return (
              <Box key={n}>
                <Box>
                  <Offerview {...e} handleChange={handleChange} expanded={expanded} />
                </Box>
              </Box>
            );
          })}
          </Box>
        </Box>
      </Box>
    </Box>
    </Container>
  );
};

export default Offer;
function Offerview({ value, subtitle,panel,expanded ,handleChange}) {
   
  return (
    <Box sx={{
      marginTop:{xs:"2rem",md:"0"}
    }}>
      <Accordion
      expanded={expanded === panel} onChange={handleChange(panel)}
       
        sx={{
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          sx={{}}
          expandIcon={
            <ExpandCircleDownOutlinedIcon
              sx={{ color: "#0E3347", fontSize: "25px" }}
            />
          }
        >
          <Typography variant="body1" color="#252525" fontWeight="700">
            {value}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ my: "-1rem" }}>
          <Typography
            variant="body2"
            color="#868686"
            fontWeight="400"
            maxWidth="50rem"
            sx={{
              // width: { xs: "100%", md: "18rem", lg: "25.5rem",xl: "42rem" },
            }}
          >
            {subtitle}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Divider sx={{ my: "0.5rem" }} />
    </Box>
  );
}
