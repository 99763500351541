import "animate.css/animate.min.css";

import React from "react";

import { Box, Container, Grid, Typography } from "@mui/material";

import { ourserviceData } from "../../../utils/Constent/Home/HomeConstent";
import Typographyview from "../../CommonSectionweb/Typographyview";

const Ourservice = () => {
  return (
    <Container
      sx={{ maxWidth: { xs: "xs", sm: "md", md: "md", lg: "lg", xl: "xl" } }}
    >
      <Box
        sx={{
          marginTop: { xs: "7rem", sm: "7rem", lg: "7rem" },
        }}
      >
        <Box>
          <Typographyview
            value="Our range of services"
            subtitle="We are one the best technology outsourcing company for your digital growth."
          />
        </Box>

        <Box
          sx={{
            display: "flex",
          }}
        >
          <Grid
            container
            spacing={{
              xs: 3,
              sm: 2,
              md: 5,
              lg: 3,
              xl: 4,
            }}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            {ourserviceData.map((n, i) => {
              return (
                <Gridview key={i}>
                  <Ourview {...n} />
                </Gridview>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default Ourservice;

function Ourview({ url, image, value, subtitle, img, media, navigation }) {
  // const navigator = useNavigate("")
  const navigator = () => {
    window.open(url);
  };
  return (
    // <AnimationOnScroll animateIn="animate__fadeInUpBig">
    <Box
      data-aos="fade-up"
      data-aos-duration="1500"
      data-aos-offset="000"
      onClick={() => navigator((url = navigation))}
      sx={{ display: "flex", justifyContent: "center", cursor: "pointer" }}
    >
      <Box
        className="container"
        sx={{
          p: { xs: 1, sm: 1, md: 2, lg: 2, xl: 2 },
          width: { xs: "100%", sm: "100%" },
          height: { xs: "350px", xl: "400px" },
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          boxShadow: " 0px 4px 8px rgba(38, 38, 38, 0.2)",
        }}
      >
        <Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img src={image} alt="" width="80px" />
          </Box>
          <Box
            sx={{
              textAlign: "center",
              "&:hover": {
                color: "#fff",
              },
            }}
          >
            <Typography
              variant="h4"
              color="#0E3347"
              fontWeight="700"
              sx={{
                paddingTop: "1rem",
              }}
            >
              {value}
            </Typography>
            <Typography
              variant="body2"
              color="#484848"
              fontWeight="400"
              sx={{
                paddingTop: "0.5rem",
              }}
            >
              {subtitle}
            </Typography>
          </Box>
        </Box>

        {/* Overview Hover */}

        <Box className="overlay">
          <Box
            className="text"
            sx={{
              width: { xs: "100%" },
              p: { xs: 1, sm: 1, md: 2, lg: 2, xl: 2 },
              height: { xs: "350px", xl: "400px" },
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              boxShadow: " 0px 4px 8px rgba(38, 38, 38, 0.2)",
              backgroundImage: "url(" + media + ")",
              backgroundSize: "400",
              backgroundPosition: "center",
            }}
          >
            <Box
              sx={{
                "&:hover": {
                  color: "#fff !important",
                },
              }}
            >
              <img src={img} alt="" width="80px" />
            </Box>
            <Box
              sx={{
                textAlign: "center",
                "&:hover": {
                  color: "#fff",
                },
              }}
            >
              <Typography
                variant="h4"
                color="#fff"
                fontWeight="700"
                sx={{
                  paddingTop: "1rem",
                }}
              >
                {value}
              </Typography>
              <Typography
                variant="body2"
                color="#fff"
                fontWeight="400"
                sx={{
                  paddingTop: "0.5rem",
                }}
              >
                {subtitle}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
    // </AnimationOnScroll>
  );
}
function Gridview({ children }) {
  return (
    <Grid item xs={12} sm={6} lg={4}>
      {children}
    </Grid>
  );
}
