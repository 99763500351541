import { API_URL, API_GET } from "./config";

export const getTestimonialsById = (id) => {
  const data = API_GET(API_URL.getTestimonialsById + id);
  return data;
};

export const getAllTestimonials = () => {
  const data = API_GET(API_URL.getAllTestimonials);
  return data;
};

export const getClientImages = () => {
  return API_GET(API_URL.getAllClientImage);
};
