import React from 'react';

import {
  Box,
  Link,
  Typography,
} from '@mui/material';

import Behance1 from '../../../Image/Behance1.png';
import Dribbble1 from '../../../Image/Dribbble1.png';
import Smedium from '../../../Image/MediumLogo.png';

const Watching = () => {
  return (
    <Box sx={{ marginTop: { xs: "4rem", sm: "5rem" } }}>
      <Box sx={{ background: "#0E3347" }}>
        <Box sx={{py:5}}>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h4" color="#fff" fontWeight="700" sx={{textTransform:"capitalize"}}>
              Thank you for watching
            </Typography>
            <Typography variant="h5" color="#fff" fontWeight="400" sx={{pt:1}}>
              Show Our Portfolio
            </Typography>
          </Box>
          <Box sx={{  display: "flex",justifyContent: "center",pt:3}}>
            <Box>
              
              <Iconview media={Behance1} href="https://www.behance.net/equitysoft"/>
            </Box>
            <Box sx={{px:2}}>
            <Iconview media={Smedium} href="https://medium.com/@equitysoft"/>
             
            </Box>
            <Box>
            <Iconview media={Dribbble1} href="https://dribbble.com/equitysoft"/>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Watching;

function Iconview({ media,href }) {
  return (
   
    <Box>
       <Link href={href} target={"_blank"}>
    <img src={media} alt="" width="50px" />
    </Link>
  </Box>

  );
}
