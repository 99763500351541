import React from "react";
import { Box } from "@mui/material";
import ux from "../../../Image/UxDesign.png";
import Flutter1 from "../../../Image/Flutter.png";
import Kotlin from "../../../Image/Kotlin.png";
import Java from "../../../Image/Java.png";
import Dart from "../../../Image/Dart.png";
import ChildBanner from "../../CommonSection/ChildBanner";

const Flutter = () => {
  return (
    <Box>
      <ChildBanner
        value={"Hybrid App Development "}
        title={"Flutter Development "}
        subtitle={
          "Google has introduced Flutter, a cross-platform software development kit for creating mobile apps on Android, iOS, Windows, Mac, Linux, and the web. Flutter is intended for fast development, is open source, and is available at no cost."
        }
        subtitle1={
          "Flutter offers several advantages, such as the ability to use a single codebase for multiple platforms, access to high-quality graphics libraries, faster development resulting in quicker app launch times, the ability to customize any on-screen user interface, and a cross-platform rendering engine that delivers excellent performance."
        }
        media={Flutter1}
        media1={Dart}
        media2={Kotlin}
        media3={Java}
        image={ux}
        i={"row"}
        none2={"none"}
        ex={{pr:{xs:"0",md:4,xl:6}}}
        width="150%"
        aos={"fade-down-left"}
      />
    </Box>
  );
};

export default Flutter;
