import React from "react";
import { Box } from "@mui/material";
import HeaderChild from "../../CommonSection/HeaderChild";

const CloudComputing = () => {
  return (
    <Box>
      <HeaderChild
      none={"none"}
        value={"Cloud Computing"}
        subtitle={"WHAT WE DO"}
        title={
          "We build robust application services and features while ensuring security and quality standards are not compromised"
        }
        title1={
          "We offer various computing resources and services to businesses and individuals. These services include storage, processing power, networking, software applications, and other IT infrastructure resources."
        }
      />
    </Box>
  );
};

export default CloudComputing;
