import { Container, Typography } from "@mui/material";
import React, { useEffect } from "react";

const Refundpolicy = () => {
  useEffect(() => window.document.scrollingElement?.scrollTo(0, 0), []);
  return (
    <Container
      sx={{
        maxWidth: { xs: "xs", sm: "md", md: "md", lg: "lg", xl: "xl" },
        mt: "7rem",
      }}
    >
      {" "}
      <div
        style={{
          textAlign: "center",
          color: "rgba(0, 0, 0, 0.75)",
          fontSize: "16px",
        }}
      >
        <Typography
          variant="h7"
          sx={{
            mb: "1.5rem",
            fontWeight: "700",
            color: "rgb(var(--color-foreground))",
            fontSize: "calc(var(--font-heading-scale) * 4rem)",
          }}
        >
          Refund policy
        </Typography>
      </div>
      <div
        style={{
          textAlign: "start",
          color: "rgba(0, 0, 0, 0.75)",
          fontSize: "16px",
        }}
      >
        <div>
          <p>
            We have a 7-day return policy, which means you have&nbsp;7 days
            after receiving your item to request a return.
            <br />
            <br />
            To be eligible for a return, your item must be in the same condition
            that you received it, unworn or unused, with tags, and in its
            original packaging. You&rsquo;ll also need the receipt or proof of
            purchase.
            <br />
            <br />
            To start a return, you can contact us at&nbsp;
            <a
              href="mailto:info@equitysoft.in"
              style={{ color: "rgba(var(--color-link), var(--alpha-link))" }}
            >
              info@equitysoft.in
            </a>
            . Please note that returns will need to be sent to the following
            address:&nbsp;C-405 Ganesh Glory 11, SG Highway, Jagatpur Road,
            Ahmedabad, GJ India 382470.
            <br />
            <br />
            If your return is accepted, we&rsquo;ll send you a return shipping
            label, as well as instructions on how and where to send your
            package. Items sent back to us without first requesting a return
            will not be accepted.
            <br />
            <br />
            You can always contact us for any return question at&nbsp;
            <a
              href="mailto:info@equitysoft.in"
              style={{ color: "rgba(var(--color-link), var(--alpha-link))" }}
            >
              info@equitysoft.in
            </a>
            .
          </p>

          <p>
            <strong>Damages and issues</strong>
            <br />
            Please inspect your order upon reception and contact us immediately
            if the item is defective, damaged or if you receive the wrong item,
            so that we can evaluate the issue and make it right.
          </p>

          <p>
            <strong>Exceptions / non-returnable items</strong>
            <br />
            Certain types of items cannot be returned, like perishable goods
            (such as food, flowers, or plants), custom products (such as special
            orders or personalized items), and personal care goods (such as
            beauty products). We also do not accept returns for hazardous
            materials, flammable liquids, or gases. Please get in touch if you
            have questions or concerns about your specific item.
            <br />
            <br />
            Unfortunately, we cannot accept returns on sale items or gift cards.
          </p>

          <p>
            <strong>Exchanges</strong>
            <br />
            The fastest way to ensure you get what you want is to return the
            item you have, and once the return is accepted, make a separate
            purchase for the new item.
          </p>

          <p>
            <strong>Refunds</strong>
            <br />
            We will notify you once we&rsquo;ve received and inspected your
            return, and let you know if the refund was approved or not. If
            approved, you&rsquo;ll be automatically refunded on your original
            payment method within 10 business days. Please remember it can take
            some time for your bank or credit card company to process and post
            the refund too.
            <br />
            If more than 15 business days have passed since we&rsquo;ve approved
            your return, please contact us at info@equitysoft.in
          </p>
        </div>
      </div>
    </Container>
  );
};

export default Refundpolicy;
