import { createTheme } from '@mui/material/styles';

export const theme = (mode = "ligh") => {
  return createTheme({
    typography: {
      fontFamily: ["Manrope", "sans-serif"].join(","),
      body1: {
        fontSize: "1rem",
        [createTheme().breakpoints.up("xs")]: {
          fontSize: "0.9rem",
        },
        [createTheme().breakpoints.up("sm")]: {
          fontSize: "0.9rem",
        },
        [createTheme().breakpoints.up("md")]: {
          fontSize: "0.9rem",
        },
        [createTheme().breakpoints.up("lg")]: {
          fontSize: "1.2rem",
        },
        [createTheme().breakpoints.up("xl")]: {
          fontSize: "1.2rem",
        },
        // backgroundColor:colors.grey["700"]
      },
      body2: {
        fontSize: "1rem",
        [createTheme().breakpoints.up("xs")]: {
          fontSize: "0.8rem",
        },
        [createTheme().breakpoints.up("sm")]: {
          fontSize: "0.9rem",
        },
        [createTheme().breakpoints.up("md")]: {
          fontSize: "0.8rem",
        },
        [createTheme().breakpoints.up("lg")]: {
          fontSize: "1rem",
        },
        [createTheme().breakpoints.up("xl")]: {
          fontSize: "1rem",
        },
        // color:colors.grey["700"]
      },
      body5: {
        fontSize: "1rem",
        [createTheme().breakpoints.up("xs")]: {
          fontSize: "0.7rem",
        },
        [createTheme().breakpoints.up("sm")]: {
          fontSize: "0.8rem",
        },
        [createTheme().breakpoints.up("md")]: {
          fontSize: "0.9rem",
        },
        [createTheme().breakpoints.up("lg")]: {
          fontSize: "0.9rem",
        },
        [createTheme().breakpoints.up("xl")]: {
          fontSize: "0.9rem",
        },
        // color:colors.grey["700"]
      },
      body4: {
        fontSize: "1rem",
        [createTheme().breakpoints.up("xs")]: {
          fontSize: "2rem",
        },
        [createTheme().breakpoints.up("sm")]: {
          fontSize: "2.5rem",
        },
        [createTheme().breakpoints.up("md")]: {
          fontSize: "3rem",
        },
        [createTheme().breakpoints.up("lg")]: {
          fontSize: "4rem",
        },
        [createTheme().breakpoints.up("xl")]: {
          fontSize: "4rem",
        },
        // backgroundColor:colors.grey["700"]
      },
      body3: {
        fontSize: "1rem",
        [createTheme().breakpoints.up("xs")]: {
          fontSize: "1rem",
        },
        [createTheme().breakpoints.up("sm")]: {
          fontSize: "1.1rem",
        },
        [createTheme().breakpoints.up("md")]: {
          fontSize: "1rem",
        },
        [createTheme().breakpoints.up("lg")]: {
          fontSize: "1rem",
        },
        [createTheme().breakpoints.up("xl")]: {
          fontSize: "1.1rem",
        },
        // backgroundColor:colors.grey["700"]
      },
      body6: {
        fontSize: "1rem",
        [createTheme().breakpoints.up("xs")]: {
          fontSize: "14px",
        },

        // backgroundColor:colors.grey["700"]
      },
      body7: {
        fontSize: "1rem",
        [createTheme().breakpoints.up("xs")]: {
          fontSize: "18px",
        },

        // backgroundColor:colors.grey["700"]
      },
      h1: {
        fontSize: "1rem",
        [createTheme().breakpoints.up("xs")]: {
          fontSize: "1.5rem",
        },
        [createTheme().breakpoints.up("sm")]: {
          fontSize: "1.5rem",
        },
        [createTheme().breakpoints.up("md")]: {
          fontSize: "1.5rem",
        },
        [createTheme().breakpoints.up("lg")]: {
          fontSize: "1.8rem",
        },
        [createTheme().breakpoints.up("xl")]: {
          fontSize: "2rem",
        },
        // color:colors.grey["700"]
      },
      h2: {
        fontSize: "1rem",
        [createTheme().breakpoints.up("xs")]: {
          fontSize: "1rem",
        },
        [createTheme().breakpoints.up("sm")]: {
          fontSize: "1.1rem",
        },
        [createTheme().breakpoints.up("md")]: {
          fontSize: "1.1rem",
        },
        [createTheme().breakpoints.up("lg")]: {
          fontSize: "1.3rem",
        },
        [createTheme().breakpoints.up("xl")]: {
          fontSize: "1.3rem",
        },
        // color:colors.grey["700"]
      },
      h3: {
        fontSize: "1rem",
        [createTheme().breakpoints.up("xs")]: {
          fontSize: "1.8rem",
        },
        [createTheme().breakpoints.up("sm")]: {
          fontSize: "2.5rem",
        },
        [createTheme().breakpoints.up("md")]: {
          fontSize: "3rem",
        },
        [createTheme().breakpoints.up("lg")]: {
          fontSize: "3rem",
        },
        [createTheme().breakpoints.up("xl")]: {
          fontSize: "3rem",
        },
        // color:colors.grey["700"]
      },
      h4: {
        fontSize: "1rem",
        fontWeight: "400",
        [createTheme().breakpoints.up("xs")]: {
          fontSize: "1.1rem",
        },
        [createTheme().breakpoints.up("sm")]: {
          fontSize: "1.5rem",
        },
        [createTheme().breakpoints.up("md")]: {
          fontSize: "1.5rem",
        },
        [createTheme().breakpoints.up("lg")]: {
          fontSize: "2rem",
        },
        [createTheme().breakpoints.up("xl")]: {
          fontSize: "2.2rem",
        },
        // color:colors.grey["700"]
      },
      h5: {
        fontSize: "1rem",
        [createTheme().breakpoints.up("xs")]: {
          fontSize: "1.2rem",
        },
        [createTheme().breakpoints.up("sm")]: {
          fontSize: "1.4rem",
        },
        [createTheme().breakpoints.up("md")]: {
          fontSize: "1.4rem",
        },
        [createTheme().breakpoints.up("lg")]: {
          fontSize: "1.4rem",
        },
        [createTheme().breakpoints.up("xl")]: {
          fontSize: "1.4rem",
        },
      },
      h6: {
        fontSize: "1rem",
        [createTheme().breakpoints.up("xs")]: {
          fontSize: "1.4rem",
        },
        [createTheme().breakpoints.up("sm")]: {
          fontSize: "1.4rem",
        },
        [createTheme().breakpoints.up("md")]: {
          fontSize: "1.2rem",
        },
        [createTheme().breakpoints.up("lg")]: {
          fontSize: "1.5rem",
        },
        [createTheme().breakpoints.up("xl")]: {
          fontSize: "1.5rem",
        },
      },

      h7: {
        fontSize: "1rem",
        fontWeight: "400",
        [createTheme().breakpoints.up("xs")]: {
          fontSize: "1.8rem",
        },
        [createTheme().breakpoints.up("sm")]: {
          fontSize: "2rem",
        },
        [createTheme().breakpoints.up("md")]: {
          fontSize: "2rem",
        },
        [createTheme().breakpoints.up("lg")]: {
          fontSize: "2rem",
        },
        [createTheme().breakpoints.up("xl")]: {
          fontSize: "2.2rem",
        },
        // color:colors.grey["700"]
      },
    },

    palette: {
      mode: mode,

      primary: {
        main: "#fff",
      },
      secondary: {
        main: "#000",
      },
      tertiary: {
        main: "#868686",
      },
      four: {
        main: "#484848",
      },
      five: {
        main: "#0E3347",
      },
      six: {
        main: "#20B69F",
      },
      default: {
        background: "#fff",
        paper: "#F2F2F2",
      },
    },
  });
};







    