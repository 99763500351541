
import { Box } from "@mui/material";
import Postgre from "../../../Image/Postgre.png";

import ChildBanner from "../../CommonSection/ChildBanner";


const PostgreSQL = () => {
  return (
    <Box>
    <ChildBanner
      value={"Database Management"}
      title={"PostgreSQL"}
      subtitle={
        "Experience the power of PostgreSQL, an open-source relational database management system. Our PostgreSQL solutions offer robust data integrity, scalability, and extensibility."
      
      }
      subtitle1={
        "We tailor PostgreSQL databases to your needs, ensuring optimal performance for your applications. Trust in our expertise to leverage PostgreSQL's capabilities for data-driven decision-making and application success."
      }
      
      image={Postgre}
      i={"row-reverse"}
      none1={"none"}
      ex={{pl:{xs:"0",md:4,xl:6}}}
      aos={"fade-down-right"}
    />
    </Box>
  )
}

export default PostgreSQL



  