import { Box} from "@mui/material";
import HireDedicated from "../../../Image/HireDedicated.png";
import Meta1 from "../../../Image/Meta1.png";
import Ads from "../../../Image/Ads.png";
import Qe from "../../../Image/Qe.png";
import Reddit from "../../../Image/Reddit.png";
import ChildBanner from "../../CommonSection/ChildBanner";

const Hirededicated = () => {
  return (
    <Box>
      <ChildBanner
        value={"Digital Marketing"}
        title={"Hire A Dedicated Digital Marketer"}
        subtitle={
          "To unlock digital growth, consider hiring a dedicated digital marketer. Marketing involves communicating with people, with over half of the world's population on the internet, and it is the best platform to reach a limitless audience."
        }
        subtitle1={
          "We empower you to compete with the big players in the industry at a reasonable cost by systematically implementing well-designed digital marketing strategies. Our digital marketing professionals bring due diligence and affordability to the digital marketing field, making it a level playing field for millions of people worldwide."
        }
        image={HireDedicated}
        media={Meta1}
        media1={Ads}
        media2={Qe}
        media3={Reddit}
        none2={"none"}
        i={"row"}
        aos={"fade-down-left"}
        ex={{pr:{xs:"0",md:4,xl:6}}}
      />
    </Box>
  )
}

export default Hirededicated

