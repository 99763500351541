import { Box} from "@mui/material";
import React from "react";
import ux from "../../../Image/UxDesign1.png";
import Toolkit from "../../../Image/Toolkit.png";
import Protopie from "../../../Image/Protopie.png";
import Axure from "../../../Image/Axure.png";
import ChildBanner from "../../CommonSection/ChildBanner";

const UXDesign = () => {
  return (
    <Box >
      <ChildBanner
        value={"Research and Wireframe "}
        title={"UX Design"}
        subtitle={
          "We perform user research, gathering and analyzing data to understand your users' needs, behaviors, and preferences. This process involves methods such as surveys, user testing, and interviews."
        }
        subtitle1={
          "Through information architecture, we organise and structure the content on your website to make it easy to find and navigate. This process involves creating a sitemap, wireframes, and user flow diagrams."
        }
        none={"none"}
        media={Toolkit}
        media1={Axure}
        media2={Protopie}
        none2={"none"}
       
        image={ux}
        ex={{pr:{xs:"0",md:4,xl:5}}}
        i={"row"}
        aos={"fade-down-left"}
        
      />
    </Box>
  );
};

export default UXDesign;
