import { Box} from "@mui/material";
import FrontEnd1 from "../../../Image/FrontEnd.png";
import Next from "../../../Image/Next.png";
import Word from "../../../Image/Word.png";
import laravelaweb from "../../../Image/laravelweb.png";
import React from "../../../Image/React.png";
import Laravela from "../../../Image/Laravel.png";

import ChildBanner from "../../CommonSection/ChildBanner";

const Laravel = () => {
  return (
    <Box>
      <ChildBanner
        value={"Web Development"}
        title={"Laravel"}
        subtitle={
          "Experience the future of web applications with Laravel. Its elegant syntax and robust features enable us to create secure, scalable solutions that meet your unique needs."
        }
        subtitle1={
          "Trust in our expertise to build web applications that are not only powerful but also reliable, setting you up for success."
        }
        image={laravelaweb}
        media={React}
        media1={Next}
        media2={Laravela}
        media3={Word}
        none2={"none"}
        ex={{pr:{xs:"0",md:4,xl:6}}}
        aos={"fade-down-left"}
        i={"row"}
      />
    </Box>
  )
}

export default Laravel

