import React, { useState, useEffect } from 'react';
import { TweenMax } from 'gsap';

const Cursor = () => {
  const [posX, setPosX] = useState(0);
  const [posY, setPosY] = useState(0);
  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1024); // Set the width threshold according to your definition of desktop
    };

    handleResize(); // Call initially to set the initial state
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!isDesktop) return; // Return if not desktop

    const cursor = document.querySelector('.cursor-Animated');
    const follower = document.querySelector('.cursor-follower-Animated');

    TweenMax.to({}, 0.016, {
      repeat: -1,
      onRepeat: function() {
        setPosX(posX + (mouseX - posX) / 9);
        setPosY(posY + (mouseY - posY) / 9);

        follower.style.left = posX - 12 + 'px';
        follower.style.top = posY - 12 + 'px';

        cursor.style.left = mouseX + 'px';
        cursor.style.top = mouseY + 'px';
      },
    });

    const handleMouseMove = (e) => {
      setMouseX(e.clientX);
      setMouseY(e.clientY);
    };

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isDesktop, mouseX, mouseY, posX, posY]);

  if (!isDesktop) return null; // Render nothing if not desktop

  return (
    <>
      <div className="cursor-Animated" />
      <div className="cursor-follower-Animated" />
    </>
  );
};

export default Cursor;
