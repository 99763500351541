import { Box, Typography } from "@mui/material";
import React from "react";
import Avtar from "./Avtar";

const Description = ({ blog }) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box flex=" 1 1 15%" sx={{ display: { xs: "none", md: "flex" } }}>
          <Avtar blog={blog} />
        </Box>
        <Box sx={{ flex: { xs: " 1 1 100%", md: " 1 1 85%" } }}>
          {/* content */}
          <Box>
            <Box></Box>

            <Box maxWidth="70rem" textAlign="justify">
              <Box p={1} mt={1}>
                <Box sx={{ display: { xs: " flex", md: "none" }, mb: 2 }}>
                  <Avtar blog={blog} />
                </Box>
                <Box>
                  <Typography
                    variant="body7"
                    color="rgb(36, 36, 36)"
                    fontWeight="700"
                    sx={{ mb: 2 }}
                  >
                    Description
                  </Typography>

                  <Box sx={{ mt: 2 }}>
                    <Typography
                      variant="body7"
                      fontWeight="400"
                      color="rgb(36, 36, 36)"
                      dangerouslySetInnerHTML={{
                        __html: blog?.description?.replace(
                          /(?:\r\n|\r|\n)/g,
                          "<br>"
                        ),
                      }}
                    ></Typography>
                  </Box>
                </Box>
                <Typography
                  component="div"
                  dangerouslySetInnerHTML={{ __html: blog?.content }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Description;
