import React, { useState } from "react";

import {
  Box,
  Button,
  Checkbox,
  Container,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import { createConntact } from "../../../api/PostApis";
import Attachment from "../../Image/Attachment.svg";
import Ellipse from "../../Image/parthsir 1 1.png";
import Vector2 from "../../Image/Vector2.png";
import { useNavigate } from "react-router-dom";

const Contact = () => {
  const navigate = useNavigate("");
  const errorvalue = {
    name: false,
    email: false,
    number: false,
    country: false,
    company: false,
    message: false,
    checkbox: false,
    media: false,
  };
  const dataValue = {
    name: "",
    email: "",
    number: "",
    country: "",
    company: "",
    message: "",
    checkbox: "",
    media: null,
  };

  const [text, setText] = useState(errorvalue);
  const [data, setData] = useState(dataValue);

  // const [error, setError] = useState(errorvalue);

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handelOnChange = (e) => {
    setData((p) => ({
      ...p,
      [e.target.name]: e.target.value,
    }));
    if (e.target.value.length > 0) {
      setText(false);
    }
  };

  //   if (!data.name) {
  //     setText((p) => ({ ...p, name: "Please enter your first name" }));
  //   } else if (!data.email) {
  //     setText((p) => ({ ...p, email: "Please enter your email address" }));
  //   } else if (!isValidEmail(data.email)) {
  //     setText((p) => ({ ...p, email: "Please enter a valid email address" }));
  //   } else if (!data.message) {
  //     setText((p) => ({
  //       ...p,
  //       message: "Please enter your valuable message",
  //     }));
  //   } else {
  //     const res = await createConntact(data);

  //     if (res && res.s) {
  //       navigate("/contact-us/thankyou");

  //       setData({
  //         name: "",
  //         number: "",
  //         company: "",
  //         email: "",
  //         media: null,
  //         message: "",
  //         country: "",
  //       });
  //     }
  //   }
  // };

  const handelOnClick = async () => {
    let validationErrors = {};

    if (!data.name) {
      validationErrors = {
        ...validationErrors,
        name: "Please enter your first name",
      };
    }

    if (!data.email) {
      validationErrors = {
        ...validationErrors,
        email: "Please enter your email address",
      };
    } else if (!isValidEmail(data.email)) {
      validationErrors = {
        ...validationErrors,
        email: "Please enter a valid email address",
      };
    }

    if (!data.message) {
      validationErrors = {
        ...validationErrors,
        message: "Please enter your valuable message",
      };
    }

    if (Object.keys(validationErrors).length > 0) {
      setText(validationErrors);
    } else {
      const res = await createConntact(data);

      if (res && res.s) {
        navigate("/contact-us/thankyou");

        setData({
          name: "",
          number: "",
          company: "",
          email: "",
          media: null,
          message: "",
          country: "",
        });
      }
    }
  };

  return (
    <Container
      sx={{ maxWidth: { xs: "xs", sm: "md", md: "md", lg: "lg", xl: "xl" } }}
    >
      <Box
        id="contact"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Paper
          elevation={5}
          sx={{
            width: "100%",
            mb: { xs: 1, md: 4 },
            mt: { xs: "4rem", sm: "5rem" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              flexDirection: { xs: "column", lg: "row" },
              paddingBlock: "3rem",
            }}
          >
            <Box sx={{ width: { xs: "90%", sm: "auto" } }}>
              <Box>
                <Typography
                  variant="h1"
                  color="#0E3347"
                  fontWeight="700"
                  sx={{ cursor: "pointer" }}
                  className="Typographyview"
                >
                  CONTACT US
                </Typography>
              </Box>
              <Box
                sx={{
                  marginTop: "2.5rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                  }}
                >
                  <TextFieldview
                    label="Name *"
                    value={data.name}
                    errortext={text.name}
                    // error={error.name}
                    type="name"
                    name="name"
                    onChange={handelOnChange}
                  />

                  <Box
                    sx={{
                      paddingLeft: {
                        xs: "0",
                        sm: "2rem",
                        md: "1.5rem",
                        xl: "2rem",
                      },
                      paddingBlock: { xs: "1rem", sm: "0" },
                    }}
                  >
                    <TextFieldview
                      label="Email *"
                      type="email"
                      // error={error.email}
                      value={data.email}
                      errortext={text.email}
                      name="email"
                      onChange={handelOnChange}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    paddingBlock: { xs: "0", sm: "1rem" },
                    flexDirection: { xs: "column", sm: "row" },
                  }}
                >
                  <TextFieldview
                    label="Phone number"
                    type="number"
                    value={data.number}
                    name="number"
                    // errortext={text.number}
                    onChange={handelOnChange}
                  />
                  <Box
                    sx={{
                      paddingLeft: {
                        xs: "0",
                        sm: "2rem",
                        md: "1.5rem",
                        xl: "2rem",
                      },
                      paddingBlock: { xs: "1rem", sm: "0" },
                    }}
                  >
                    <TextFieldview
                      label="Country"
                      value={data.country}
                      name="country"
                      // errortext={text.country}
                      // error={error.country}
                      onChange={handelOnChange}
                    />
                  </Box>
                </Box>
                <Box>
                  <TextFieldview
                    label="Company Name"
                    value={data.company}
                    name="company"
                    // error={error.company}
                    // errortext={text.company}
                    onChange={handelOnChange}
                  />

                  <Box sx={{ paddingTop: "1rem" }}>
                    <TextFieldview
                      label="Message *"
                      value={data.message}
                      name="message"
                      // error={error.message}
                      errortext={text.message}
                      onChange={handelOnChange}
                      type="message"
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  pt: { xs: 2.5, md: 3.5 },
                  pb: { xs: 2, md: 2.5 },
                }}
              >
                <Box component="label">
                  <img
                    src={Attachment}
                    alt=""
                    width="35px"
                    style={{ cursor: "pointer" }}
                  />
                  <input
                    hidden
                    accept="image/*, application/pdf, .doc, .docx"
                    multiple
                    type="file"
                    onChange={(e) => {
                      setData({ ...data, media: e.target.files[0] });
                      setText(false);
                      return (e.target.value = null);
                    }}
                  />
                </Box>
                <Box>
                  <Typography
                    variant="body1"
                    color="initial"
                    sx={{
                      px: 1,
                      fontWeight: "550",
                    }}
                  >
                    Add an Attachment{" "}
                  </Typography>

                  {data.media && (
                    <Typography variant="body5" sx={{ ml: 1, color: "red" }}>
                      Selected File: {data.media.name}
                    </Typography>
                  )}
                  {/* <Typography
                    fontSize="0.8rem !important"
                    sx={{ color: "red", ml: 1 }}
                  >
                    {text.media}
                  </Typography> */}
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: { xs: "start", sm: "center" },
                }}
              >
                <Box>
                  <Checkbox
                    type="checkbox"
                    onChange={handelOnChange}
                    value={data.checkbox}
                    variant="plain"
                    color="four"
                    sx={{ p: 0 }}
                    style={{
                      transform: "scale(1)",
                    }}
                  />
                </Box>
                <Box>
                  <Typography
                    variant="body2"
                    color="initial"
                    sx={{
                      px: 1,
                      fontWeight: "400",
                    }}
                  >
                    I want to receive news and update once in a while
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  pt: { xs: 2.5, md: 3 },
                }}
              >
                <Button
                  onClick={handelOnClick}
                  sx={{
                    backgroundColor: "#0E3347",
                    color: "#fff",
                    borderRadius: "50px",
                    textTransform: "none",
                    fontWeight: "500",
                    fontSize: { xs: "12px", sm: "16px" },
                    px: { xs: 5, sm: 6 },
                    py: { xs: 1, sm: 1 },
                    "&:hover": {
                      color: "#fff",
                      background:
                        "linear-gradient(180deg, #295768 0%, #183038 100%)",
                    },
                  }}
                >
                  Submit
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                width: { xs: "90%", sm: "75%", lg: "auto" },
                display: { xs: "none", lg: "flex" },
              }}
            >
              <Box>
                <Boxview />
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default Contact;

function TextFieldview({
  label,
  value,
  type,
  onChange,
  name,
  error,
  errortext,
}) {
  return (
    <Box>
      <Box>
        <TextField
          autoComplete="off"
          name={name}
          focused
          onChange={onChange}
          value={value}
          type={type}
          // error={error}
          helperText={errortext}
          id="standard-basic"
          label={label}
          variant="standard"
          color="tertiary"
          sx={{
            width: {
              xs: "100%",
              sm: "232px",
              md: "280px",
              lg: "250px",
              xl: "280px",
            },
            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
              {
                display: "none",
              },
          }}
          InputLabelProps={{
            style: { fontSize: "1rem" },
          }}
          InputProps={{
            style: {
              fontSize: "1rem",
            },
          }}
        />
      </Box>
    </Box>
  );
}
function Boxview() {
  return (
    <Box sx={{}}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#0E3347",
            borderRadius: "10px",
            px: 4,
            py: 0,
            height: {
              xs: "100%",
              sm: "360px",
              md: "300px",
              lg: "400px",
              xl: "380px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              pt: 2,
            }}
          >
            <img src={Vector2} alt="" width="60px" />
          </Box>
          <Box>
            <Typography
              variant="body2"
              color="#DDDDDD"
              fontWeight="700"
              sx={{
                width: { xs: "95%", lg: "23rem", xl: "27rem" },
                pb: 5,
                pt: 4,
                textAlign: "start",
              }}
            >
              “I am incredibly proud to witness the satisfaction our clients
              experience. Our commitment to delivering exceptional service and
              innovative solutions is reflected in the smiles of our satisfied
              clients. Their trust and positive feedback inspire us to
              continually raise the bar, ensuring we exceed expectations and
              remain a trusted partner in their success.”
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              // justifyContent:{sm:"center",md:"start"}
            }}
          >
            <Box
              sx={{
                marginRight: "1rem",
              }}
            >
              <img
                src={Ellipse}
                alt=""
                style={{
                  width: "60px",
                  height: "60px",
                  borderRadius: "50%",
                  boxShadow: "0px 2px 6px rgba(38, 38, 38, 0.1)",
                  background: "#fff",
                }}
              />
            </Box>
            <Box>
              <Typography variant="body2" color="#EEEEEE" fontWeight="500">
                Mr.Parth Rajani
              </Typography>
              <Typography variant="body2" color="#DDDDDD" fontWeight="700">
                Ceo of Equitysoft
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
