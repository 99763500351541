import { Box, Button, Link,  Typography } from '@mui/material';
import React, { useEffect } from 'react'
import thank from "../../Component/Image/thank-you-bg.webp";
import icon from "../../Component/Image/thank-you-icon.svg"
import { SocialData } from '../../utils/Constent/ChildPage/CasestudyConstent';
import { Tooltip } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

const Thankyou = () => {
   useEffect(() => window.document.scrollingElement?.scrollTo(0, 0), []);
    const navigator = useNavigate("")

  return (
    <Box sx={{ mt: "5rem" }}>
      
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          backgroundImage: "url(" + thank + ")",
          backgroundSize: "cover",
          backgroundPosition: "center",
          objectFit: "cover",
          width: "100%",
          height: "93vh",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "93vh",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            // opacity: "0.6",
            background:
              "linear-gradient(180deg,  rgb(41, 87, 104,0.7)  0%, rgb(24, 48, 56,0.7) 100%)",
          }}
        ></Box>
        <Box
          sx={{
            zIndex: 1,
            textAlign: "center",
            display: "flex",
            justifyContent: "center",

            flexDirection: "column",
          }}
        >
          <Box
            sx={
              {
                //   display: "flex",
                //   justifyContent: "center",
                //   alignItems: "center",
              }
            }
          >
            <img src={icon} alt="" />
          </Box>
          <Box>
            <Box>
              <Typography variant="body4" color="#fff" fontWeight="700">
                Thank You
              </Typography>
              <Typography variant="body1" color="#fff">
                Your Message has been sent successfully.
              </Typography>
              <Typography variant="body1" color="#fff">
                As we make sure the right person get back to you ASAP!
              </Typography>
            </Box>
          </Box>
          <Box sx={{ my: 3 }}>
            <Box>
              <Typography variant="body1" color="#fff" fontWeight="800">
                Connect With Us
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {SocialData.map((item, index) => {
                return (
                  <Box key={index} sx={index % 2 !== 0 ? { px: {xs:1,md:1.5} } : {}}>
                    <IconView {...item} />
                  </Box>
                );
              })}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
          
            }}
          >
            <Button
            onClick={() => navigator ("/")}
              sx={{
                backgroundColor: "#fff",
                color: "#0E3347",
                borderRadius: "10px",
                textTransform: "none",
                fontWeight: "700",
                fontSize: { xs: "12px", sm: "16px" },
                px: { xs: 5, sm: 6 },
                py: { xs: 1, sm: 1 },
                "&:hover": {
                  color: "#0E3347",
                  background: "#fff",
                },
              }}
            >
              Back to Website
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Thankyou

function IconView({ media, title, href }) {
  return (
    <Link href={href} target={"_blank"}>
      <Box className="hover-effect5" sx={{ mt: 1 }}>
        <Tooltip
          label={title}
          color="dark"
          position="bottom"
          transitionProps={{ transition: "scale-y", duration: 300 }}
          withArrow
        >
          <img src={media} alt="" width="45px" href={href} />
        </Tooltip>
      </Box>
    </Link>
  );
}
