import { Box } from '@mui/material'
import React from 'react'
import HeaderView from '../../CommonSectionweb/HeaderView'

const Careerscreen = () => {
  return (
    <Box>
        <HeaderView value="Are you tech-savvy, success-driven and passionate about the IT world?"  value1="" 
        subtitle="If yes, then join Equitysoft and make a difference in your career graph!" />
    </Box>
  )
}

export default Careerscreen