import React from "react";
import { Box } from "@mui/material";
import HeaderChild from "../../CommonSection/HeaderChild";

const Digitalmarketing = () => {
  return (
    <Box>
      <HeaderChild
        none={"none"}
        value={"Digital marketing"}
        subtitle={"WHAT WE DO"}
        title={
          "We make your digital presence stand out in the market and help you soar high in the digital space."
        }
        title1={
          "We provide various services to help businesses promote their products or services through digital channels such as search engines, social media, email, and mobile applications. We help brands to make a solid online presence."
        }
      />
    </Box>
  );
};
export default Digitalmarketing;
