import { API_URL, API_GET } from "./config";

export const getAllServices = () => {
  const data = API_GET(API_URL.getAllServices);
  return data;
};

export const getAllCareer = () => {
  const data = API_GET(API_URL.getAllCareer);
  return data;
};

export const getAllImages = () => {
  const data = API_GET(API_URL.getAllGellaryimages);
  return data;
};

export const getVideo = () => {
  const data = API_GET(API_URL.getGalleryVideo);
  return data;
};
