import React from "react";
import ChildBanner from "../../CommonSection/ChildBanner";
import Mail from "../../../Image/Mail1.png";
import Play from "../../../Image/Play.png";
import Ads from "../../../Image/Ads.png";
import GoogleMarketing1 from "../../../Image/GoogleMarketing.png";
import { Box } from "@mui/material";

const GoogleMarketing = () => {
  return (
    <Box>
      <ChildBanner
        value={"Google Ads, Gmail, play store"}
        title={"Google Marketing"}
        subtitle={
          "Looking to grow your business and reach more customers? Look no further than Google! With our suite of marketing tools and services, you can connect with the right audience at the right time and drive real results for your business."
        }
        subtitle1={
          "From search engine optimization (SEO) to pay-per-click (PPC) advertising, we have the expertise and technology to help you succeed online. Our data-driven approach ensures that you get the most out of your marketing budget and achieve your goals."
        }
        media={Ads}
        media1={Mail}
        media2={Play}
        image={GoogleMarketing1}
        i={"row-reverse"}
        // none={"none"}
        none={"none"}
        none2={"none"}
        aos={"fade-down-right"}
        ex={{pl:{xs:"0",md:4,xl:6}}}
       
      />
    </Box>
  );
};

export default GoogleMarketing;
