import "react-toastify/dist/ReactToastify.css";
import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
// firebase
import { logEvent } from "firebase/analytics";
import { BrowserRouter as Brows, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { Box, ThemeProvider } from "@mui/material";

import About from "./Component/About/Screen/About";
import BlogPage from "./Component/Blog/Screen/BlogPage";
import Career from "./Component/Career/Screen/Career";
import AppPage from "./Component/ChildPage/ChildScreen/AppPage";
import { BlogDetail } from "./Component/ChildPage/ChildScreen/BlogDetail";
import CasestudyChildPage from "./Component/ChildPage/ChildScreen/CasestudyChildPage";
import CasestudyPage from "./Component/ChildPage/ChildScreen/CasestudyPage";
import CloudComputingPage from "./Component/ChildPage/ChildScreen/CloudComputingPage";
import DigitalmarketingPage from "./Component/ChildPage/ChildScreen/DigitalmarketingPage";
import HirePage from "./Component/ChildPage/ChildScreen/HirePage";
import ProjectView from "./Component/ChildPage/ChildScreen/ProjectView";
import UiuxPage from "./Component/ChildPage/ChildScreen/UiuxPage";
import WebPage from "./Component/ChildPage/ChildScreen/WebPage";
import Database from "./Component/ChildPage/ChildScreen/Databasepage";
import Home from "./Component/Home/Home";
import Footer from "./Component/Home/Screen/Footer";
import Navbar from "./Component/Home/Screen/Navbar";
import { Quote } from "./Component/Quote/Quote";
// import businesswhatsapp from "./Component/Image/business whatsapp1.png";
import Circle from "./Component/Image/Circle.svg";
// import Whatsapp from "./Component/Image/send2.png";
// import whatsapp1 from "./Component/Image/whatsapp.png";
import Loader from "./Component/Loader/Loader";
import Ourportfolio from "./Component/OurPortfolio/Screen/Ourportfolio";
import PrivacyPolicy from "./Component/Privacy&Policy/PrivacyPolicy";
import Service from "./Component/Service/Screen/Service";
import { analytics } from "./Firebase";
import { theme } from "./utils/Theme";
import AIDevelopmentpage from "./Component/ChildPage/ChildScreen/AIDevelopmentpage";
import Thankyou from "./Component/Quote/Thankyou";
import TermsofService from "./Component/TermsofService/TermsofService";
import Refundpolicy from "./Component/Refundpolicy/Refundpolicy";
import Cursor from "./Component/Cursor/Cursor";

function App() {
  const [active, setactive] = useState(true);

  useEffect(() => {
    logEvent(analytics, "page_view", {
      page_location: window.location.href + window.location.search,
      page_path: window.location.href + window.location.search,
      page_title: "Home Page",
    });

    AOS.init({
      duration: 900,
      easing: "ease-in-out-sine",
    });
    AOS.refresh();

    setTimeout(() => setactive(false), 400);
  }, []);

  const calcScrollValue1 = () => {
    const scrollProgress = document.getElementById("progress");
    const pos = document.documentElement.scrollTop;
    const calcHeight =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrollValue = Math.round((pos * 102) / calcHeight);
    if (pos > 102) {
      scrollProgress.style.display = "grid";
    } else {
      scrollProgress.style.display = "none";
    }
    scrollProgress.addEventListener("click", () => {
      document.documentElement.scrollTop = 0;
    });
    scrollProgress.style.background = `conic-gradient(#295768 ${scrollValue}%, #EEEEEE ${scrollValue}%)`;
  };
  window.addEventListener("scroll", calcScrollValue1);

  if (active) return <Loader />;

  return (
    <ThemeProvider theme={theme("light")}>
      <Cursor />
      <Brows>
        <Box onClick={calcScrollValue1} id="progress">
          <Box sx={{ mt: 0.6 }} title="Go to top">
            <img src={Circle} alt="" width="50px" title="Go to top" />
          </Box>
        </Box>

        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} exact={true} />
          <Route path="/service" element={<Service />} />
          <Route path="/portfolio" element={<Ourportfolio />} />
          <Route path="/career" element={<Career />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/case-study" element={<CasestudyChildPage />} />
          {/* ChildPage */}
          <Route path="/ui-ux-design-agency" element={<UiuxPage />} />
          <Route path="/app-development-services" element={<AppPage />} />
          <Route path="/web-development-services" element={<WebPage />} />
          <Route
            path="/ai-development-services"
            element={<AIDevelopmentpage />}
          />
          <Route path="database-management-services" element={<Database />} />
          <Route path="/hire-software-developers" element={<HirePage />} />
          <Route
            path="/cloud-computing-services"
            element={<CloudComputingPage />}
          />
          <Route
            path="/digital-marketing-agency"
            element={<DigitalmarketingPage />}
          />
          <Route path="case-study/:id/:title" element={<CasestudyPage />} />

          <Route path="case-study" element={<CasestudyChildPage />} />
          <Route path="projectview/:id/:title" element={<ProjectView />} />
          <Route path="contact-us" element={<Quote />} />
          <Route path="contact-us/thankyou" element={<Thankyou />} />
          <Route path="blog-details/:id" element={<BlogDetail />} />
          <Route path="/term-of-service" element={<TermsofService />} />
          <Route path="/refund-policy" element={<Refundpolicy />} />
        </Routes>
        <Footer />
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          closeButton={false}
        />
      </Brows>
    </ThemeProvider>
  );
}

export default App;
