import { API_GET, API_POST, API_URL } from "./config";

export const getAllBlog = (category) => {
  try {
    if (category) {
      const q = `?category_id=${category}`;
      const data = API_GET(API_URL.getAllBlog + q);
      return data;
    } else {
      const data = API_GET(API_URL.getAllBlog);
      return data;
    }
  } catch (error) {
    return error;
  }
};

export const getBlogById = (id) => {
  try {
    const data = API_GET(API_URL.getBlogById + id);
    return data;
  } catch (error) { 
    return error;
  }
};

export const updateBlogViewCount = (id) => {
  try {
    const f = new FormData();
    f.append("id" , id)
    const data = API_POST(API_URL.updateViewCount ,f);
    return data;
  } catch (error) {
    return error;
  }
};



// techFest 

export const getTechFest = () => {
  const data = API_GET(API_URL.getAllTechFest)
  return data;
}