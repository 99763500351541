import Face from '../../../Component/Image/Face.png';
import Instagram from '../../../Component/Image/Instagram.png';
import SBehance from '../../../Component/Image/SBehance.png';
import SDribbble from '../../../Component/Image/SDribbble.png';
import SLinkedin from '../../../Component/Image/SLinkedin.png';
import Smedium from '../../../Component/Image/Smedium.png';


//Social Media Data

export const SocialData = [
  {
    media:Smedium,
    title:"Medium",
    href:"https://medium.com/@equitysoft"
  },
  {
    media:SLinkedin,
    title:"Linkedin",
    href:"https://www.linkedin.com/company/equitysoft/"

  },
  {
    media:Instagram,
    title:"Instagram",
    href:"https://www.instagram.com/equitysoft/"
    
  },
  {
    media:Face,
    title:"Facebook",
    href:"https://www.facebook.com/equitysoft.in?mibextid=ZbWKwL"
  },
  {
    media:SDribbble,
    title:"Dribbble",
    href:"https://dribbble.com/equitysoft"
  },
  {
    media:SBehance,
    title:"Behance",
    href:"https://www.behance.net/equitysoft"
  },

  
]
