
import { Box } from "@mui/material";
import python from "../../../Image/pythonweb.png";
import Express from "../../../Image/Express.png";
import Laravel from "../../../Image/Laravel.png";
import Firebase from "../../../Image/Firebase.png";
import Js from "../../../Image/Js.png";
import ChildBanner from "../../CommonSection/ChildBanner";


const Pythonweb = () => {
  return (
    <Box>
    <ChildBanner
      value={"Web Development "}
      title={"Python"}
      subtitle={
        "Python isn't just for mobile apps; it's a powerful tool in web development too. We utilize Python to create feature-rich web applications with clean code and seamless functionality."
      
      }
      subtitle1={
        "Join us on a journey of innovation where Python meets your web development needs. Your website, your vision, our expertise."
      }
      media={Express}
      media1={Js}
      media2={Laravel}
      media3={Firebase}
      image={python}
      i={"row-reverse"}
      none2={"none"}
      ex={{pl:{xs:"0",md:4,xl:6}}}
      aos={"fade-down-right"}
    />
    </Box>
  )
}

export default Pythonweb



  