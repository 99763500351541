import { Box } from '@mui/material'
import React from 'react'
import HeaderView from '../../CommonSectionweb/HeaderView'

const BlogScreen = () => {
  return (

    <Box>
      <HeaderView value="We write about technology, web and mobile app trends, UIUX design, and more."
        subtitle="From brand new products to overhauls, Equitysoft provides everything a digital product needs to thrive." />
    </Box>
  )
}

export default BlogScreen