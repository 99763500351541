import { Box } from "@mui/material";
import ChildBanner from "../../CommonSection/ChildBanner";
import Next from "../../../Image/Next.png";
import Angular1 from "../../../Image/Angular1.png";
import Word from "../../../Image/Word.png";
import React from "../../../Image/React.png";
import Search from "../../../Image/Search.png";

const SearchEngine = () => {
  return (
    <Box>
      <ChildBanner
        value={"Website The Ranking Boost"}
        title={"Search Engine Optimization "}
        subtitle={
          "Search Engine Optimization, or SEO, is the process of optimizing your website's content to improve its visibility and ranking on search engine results pages (SERPs). One crucial aspect of SEO is the body text of your website."
        }
        subtitle1={
          "We have expertise in optimizing a website's body text using headings and subheadings with relevant keywords that can help search engines understand the structure of your content and can improve your website's ranking."
        }
        media={React}
        media1={Next}
        media2={Angular1}
        media3={Word}
        image={Search}
        // none={"none"}
        aos={"fade-down-left"}
        none2={"none"}
        i={"row"}
        ex={{pr:{xs:"0",md:4,xl:6}}}

      />
    </Box>
  );
};

export default SearchEngine;
