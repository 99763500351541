import { Box } from '@mui/material'
import React, { useEffect } from 'react'

import AIDevelopment from '../Screen/AIDevelopmentpage/AIDevelopment'
import YourProject from '../Screen/AppPage/YourProject'
import AIappdevelopment from '../Screen/AIDevelopmentpage/AIappdevelopment'
import AIconsulting from '../Screen/AIDevelopmentpage/AIconsulting'
import ChatGPT from '../Screen/AIDevelopmentpage/ChatGPT'
import Machine from '../Screen/AIDevelopmentpage/Machine'
import AiDigital from '../Screen/AIDevelopmentpage/AiDigital '
import OurWorkAi from '../Screen/AIDevelopmentpage/OurWorkAi'
import Contact from '../../Service/ServiceScreen/Contact'

const AIDevelopmentpage = () => {
  useEffect(() => window.document.scrollingElement?.scrollTo(0, 0), [])
  return (
   <Box>
    <AIDevelopment/>
    <YourProject/>
    <AIappdevelopment/>
    <AIconsulting/>
    <ChatGPT/>
    <Machine/>
    <AiDigital/>
    <OurWorkAi/>
    <Contact/>
   </Box>
  )
}

export default AIDevelopmentpage