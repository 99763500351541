import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import {
  useEffect,
  useState,
} from 'react';

import Slider from 'react-slick';

import { Box, Skeleton } from '@mui/material';

import { API_URL } from '../../../api/config';
import { getAllImages } from '../../../api/Services';

const Photos = () => {
  const [ImagesData, setImagesData] = useState([]);

  const [loading,Setloading] = useState("");

  useEffect(() => {
    Setloading(true)
    const getData = async () => {
      const data = await getAllImages();

      if (data?.s) setImagesData(data?.r);
        Setloading(false);
    };

    getData();
  }, []);
  var settings = {
    infinite: true,
    slidesToShow: 4,
    rows: 2,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    overflow: "hidden",
    pauseOnHover: false,

    responsive: [
      {
        breakpoint: 1536,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box
      sx={{
        marginTop: { xs: "4rem", sm: "5rem" },
        overflow: "hidden",
      }}
    >
      <Box>
        <Slider {...settings}>
          {loading
            ? [1, 2, 3, 4, 5, 6, 7, 8].map((e) => (
                <Box key={e}>
                  <Skeleton
                    variant="rounded"
                    animation="wave"
                    width="99%"
                    sx={{
                      p:1,
                      
                      borderRadius: "1rem",
                      height: {
                        xs: "240px",
                        lg: "284px",
                      },
                    }}
                  />
                </Box>
              ))
            : ImagesData?.map((n, e) => (
                <Box key={e}>
                  <Photoview {...n} />
                </Box>
              ))}
        </Slider>
      </Box>
    </Box>
  );
};

export default Photos;

function Photoview({ image }) {
  return (
    <Box sx={{height:"284px",  width: "99%",  objectFit:"cover"}}>
      <img
      
        src={API_URL.galleryImages + image}
        alt=""
        style={{
          width: "100%",
          height: "100%",
          objectFit:"cover",
          borderRadius: "10px",
          // boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 25px;",
        }}
      />
    </Box>
  );
}
