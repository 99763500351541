import { Box } from "@mui/material";
import BackEnd1 from "../../../Image/BackEnd.png";
import Express from "../../../Image/Express.png";
import Laravel from "../../../Image/Laravel.png";
import Firebase from "../../../Image/Firebase.png";
import Js from "../../../Image/Js.png";
import ChildBanner from "../../CommonSection/ChildBanner";


const BackEnd = () => {
  return (
    <Box>
    <ChildBanner
      value={"Web Development "}
      title={"Back-end Development "}
      subtitle={
        "Back-end development involves creating and maintaining the server-side components of a website or web application, such as the database, server-side scripting, and APIs."
      
      }
      subtitle1={
        "Server-side scripting involves programming languages such as PHP, Python, or Ruby to create dynamic web applications that can interact with databases, handle user authentication, and perform other server-side functions"
      }
      media={Express}
      media1={Js}
      media2={Laravel}
      media3={Firebase}
      image={BackEnd1}
      i={"row-reverse"}
      none2={"none"}
      ex={{pl:{xs:"0",md:4,xl:6}}}
      aos={"fade-down-right"}
    />
    </Box>
  )
}

export default BackEnd



  