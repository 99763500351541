import { Box } from "@mui/material";
import DatabaseHosting from "../../../Image/DatabaseHosting.png";

import ChildBanner from "../../CommonSection/ChildBanner";


const Hosting = () => {
  return (
    <Box>
    <ChildBanner
      value={"Database Hosting"}
      title={"Database Hosting  (AWS, Digital Ocean, Heroku, Microsoft Azure)"}
      subtitle={
        "Experience top-tier database hosting with seamless integration into leading platforms like AWS, Digital Ocean, Heroku, and Microsoft Azure. Our hosting services offer reliability, scalability, and security for your data."
      
      }
      subtitle1={
        "We handle the technical intricacies, allowing you to focus on your core business. Trust us to provide a hosting solution that ensures your database is always accessible, responsive, and ready to support your applications."
      }
           image={DatabaseHosting}
      i={"row-reverse"}
      none1={"none"}
      ex={{pl:{xs:"0",md:4,xl:6}}}
      aos={"fade-down-right"}
    />
    </Box>
  )
}

export default Hosting



  