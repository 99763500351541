import { Box } from "@mui/system";
import React from "react";
import HeaderView from "../../CommonSectionweb/HeaderView";

const ServiceScreen = () => {
  return (
    <Box>
      {/* <HeaderView value=" People will love your product,  You'll love our process" 
        subtitle="From raw to real, you will appreciate our results." /> */}
      <HeaderView
        value="End to end digital transformation solution for your digital success."
        subtitle="Create your own by hiring a dedicated team of developers and marketing experts from us."
      />
    </Box>
  );
};

export default ServiceScreen;
