
import { Box } from "@mui/material";
import Hirea from "../../../Image/Hirea.png";
import Apple1 from "../../../Image/Apple1.png";
import Swift from "../../../Image/Swift.png";
import Kotlin from "../../../Image/Kotlin.png";
import Android from "../../../Image/Android.png";
import Flutter1 from "../../../Image/Flutter1.png";
import React from "../../../Image/React.png";
import Dart from "../../../Image/Dart.png";
import ChildBanner from "../../CommonSection/ChildBanner";


const Hireapp = () => {
  return (
    <Box>
    <ChildBanner
      value={"App Developer"}
      title={"Hire App Developer "}
      subtitle={
        "Our innovative and forward-looking mobile app development services help brands worldwide achieve their goals. We provide the option of hiring mobile app developers, proficient and knowledgeable in creating mobile solutions using the latest technologies.."
      }
      subtitle1={
        " We are a leading Android app development company with a unique portfolio. Our team of experts uses the latest tools and technologies to build the industry-best Android apps. Hiring us not just increases your client base and business revenues"
      }
      media={Flutter1}
      media1={Dart}
      media2={Android}
      media3={React}
      media4={Kotlin}
      media5={Apple1}
      media6={Swift}
      image={Hirea}
      ex={{pl:{xs:"0",md:4,xl:6}}}
      i={"row-reverse"}
      aos={"fade-down-right"}
    
    />
  </Box>
  );
};

export default Hireapp;

