import { Box } from "@mui/material";
import React from "react";
import HeaderView from "../../CommonSectionweb/HeaderView";

const PortfolioScreen = () => {
  return (
    <Box>
      <HeaderView
        value="A portrayal of what we have done so far In the design & development."
        subtitle="A canvas of our vivid creations that aims to bring favorable outcomes."
      />
    </Box>
  );
};

export default PortfolioScreen;
