import React from "react";

import { AnimationOnScroll } from "react-animation-on-scroll";

import { Box, Typography } from "@mui/material";

const Typographyview = ({ value, subtitle, none, custom }) => {
  return (
    <Box>
      <Box
        sx={{
          textAlign: "center",
          letterSpacing: "1px",
          mb: { xs: "3rem", sm: "4rem" },
          display: "grid",
          placeItems: "center",
          width: "100%",
        }}
      >
        <AnimationOnScroll animateIn="animate__fadeInDown">
          <Typography
            variant="h2"
            color="#0E3347"
            fontWeight="700"
            sx={{ cursor: "pointer", textTransform: "capitalize" }}
            className="Typographyview"
          >
            {value}
          </Typography>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInUp" delay={100}>
          <Box
            sx={{
              display: none,
            }}
          >
            {subtitle ? (
              <Typography
                variant="body1"
                color="#484848"
                fontWeight="400"
                sx={{
                  paddingTop: "1.5rem",
                }}
              >
                {subtitle}
              </Typography>
            ) : (
              custom
            )}
          </Box>
        </AnimationOnScroll>
      </Box>
    </Box>
  );
};

export default Typographyview;
