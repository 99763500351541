import React from "react";
// import Ellipse1 from "../../../Image/Ellipse1.png";
import { Box, Typography } from "@mui/material";
import { API_URL } from "../../../../api/config";

const Avtar = ({ blog }) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "start" }}>
      <Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img
            src={API_URL.blogImage + blog?.creater_profile}
            alt=""
            style={{
              width: "70px",
              height: "70px",
              borderRadius: "50%",
              boxShadow: "0px 2px 6px rgba(38, 38, 38, 0.1)",
              background: "#fff",
            }}
          />
        </Box>
        <Box sx={{ textAlign: { xs: "start", md: "start" } }}>
          <Typography
            variant="body2"
            noWrap
            color="initial"
            fontWeight="700"
            textTransform="capitalize"
            sx={{ mr: 3 }}
          >
            {blog?.creater_name}
          </Typography>
          <Typography variant="body5" color="#484848" fontWeight="700">
            {blog?.creater_designation}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Avtar;
