import React from 'react';

import { AnimationOnScroll } from 'react-animation-on-scroll';

import {
  Box,
  Container,
  Paper,
  Typography,
} from '@mui/material';

import Mission from '../../Image/Mission.svg';
import Vision from '../../Image/Vision.svg';
import Who1 from '../../Image/Who.png';

const Who = () => {
  return (
    <Box id="Who">
      <Box
        sx={{
          position: "relative",
          marginTop: { xs: "4rem", sm: "5rem" },
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            backgroundImage: "url(" + Who1 + ")",
            backgroundSize: "cover",
            backgroundPosition: "center",
            objectFit: "cover",
          }}
        >
          
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: "rgba(0,0,0,0.6)",
            }}
          ></Box>
          <Container
            sx={{
              maxWidth: { xs: "xs", sm: "md", md: "md", lg: "lg", xl: "xl" },
            }}
          >
            <Box>
              <Box
                sx={{
                  textAlign: "center",
                  mt: "2rem",
                }}
              >
                <Box>
                  <AnimationOnScroll animateIn="animate__fadeInDown">
                    <Typography variant="h4" color="#fff" fontWeight="700">
                      Who we are ?
                    </Typography>
                  </AnimationOnScroll>
                  <AnimationOnScroll animateIn="animate__fadeInUp">
                    <Typography
                      variant="h2"
                      fontWeight="700"
                      color="#fff"
                      sx={{
                        paddingTop: { xs: "1rem", sm: "1rem" },
                        px: { xs: 1, sm: 0 },
                      }}
                    >
                      A creative tech corps who designs & develops the best
                      solutions.
                    </Typography>
                  </AnimationOnScroll>
                </Box>
                <Box>
                  <AnimationOnScroll animateIn="animate__fadeInUp">
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Box
                        maxWidth="61rem"
                        
                      >
                        <Typography
                          variant="body1"
                          color="#fff"
                          fontWeight="300"
                          sx={{ paddingBlock: { xs: "1rem", sm: "1.5rem" } }}
                        >
                          We create the most inventive and captivating websites
                          and mobile apps. We provide a wide range of
                          customization in the development of mobile apps,
                          websites, AR-VR applications, games, blockchain
                          technology, and much more. Our high-calibre team and
                          our product innovations are designed to grow your
                          business. Without sacrificing quality or timeliness,
                          we believe in delivering the best services.
                        </Typography>
                        <Typography
                          variant="body1"
                          color="#fff"
                          fontWeight="300"
                        >
                          We take pride in our ability to deliver user-friendly,
                          scalable solutions that drive accurate business
                          results. Our commitment to providing exceptional
                          customer service and support is second to none, and
                          our clients' success is our success.
                        </Typography>
                      </Box>
                    </Box>
                  </AnimationOnScroll>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  // alignItems: "center",
                  flexDirection: { xs: "column", md: "row" },
                  marginBlock: { xs: "1rem", md: "0rem" },
                }}
              >
                <Box
                  sx={{
                    marginRight: { xs: "0", md: "0.5rem", lg: "0.5rem" },
                  }}
                >
                  <Cardview
                    image={Vision}
                    value="Our Vision"
                    title="A company that is well-known among its clients on a global scale for its broad range of services. Equitysoft Technologies envisions itself as a global leader in delivering cutting-edge technology solutions that drive digital transformation for businesses of all sizes."
                  />
                </Box>
                <Box
                  sx={{
                    marginLeft: { xs: "0", md: "0.5rem", lg: "0.5rem" },
                  }}
                >
                  <Cardview
                    image={Mission}
                    value="Our Mission"
                    title="Our mission is to grow organizations with the tools and expertise they need to succeed in a rapidly changing digital landscape. Cultivating strong relationships with clients, partners, and the wider technology Continuously improving processes and practices to deliver the highest quality solutions.  
                    "
                  />{" "}
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export default Who;

function Cardview({ value, title, image }) {
  return (
    <AnimationOnScroll animateIn="animate__fadeInUpBig">
      <Box
        className="hovereffect"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 0,
          transition: "all 0.5s ease 0s",
        }}
      >
        <Paper
          sx={{
            width: { xs: "100%", sm: "480px", md: "100%", xl: "480px" },
            marginBlock: { xs: "1rem", md: "2rem" },
          }}
        >
          <Box
            className="item"
            sx={{
              px: 3,
              py: 3.5,
            
              height: { xs: "100%", sm: "100%", md:"240px",lg: "260px", xl: "280px" },
              display: "flex",
              justifyContent: "center",
              // alignItems: "center",
            }}
          >
            <Box>
              <Box
                sx={{
                  paddingBottom: "0.5rem",
                }}
              >
                <img src={image} alt="" width="50px" />
              </Box>
              <Box>
                <Typography
                  variant="h2"
                  color="#0E3347"
                  sx={{
                    paddingBottom: "0.5rem",
                    fontWeight: "700",
                  }}
                >
                  {value}
                </Typography>
                <Typography
                  variant="body2"
                  color="#484848"
                  fontWeight="300"
                  sx={{
                    lineHeight: "1.4",
                  }}
                >
                  {title}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    </AnimationOnScroll>
  );
}
