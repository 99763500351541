import React from 'react';

import {
  Box,
  Container,
  Skeleton,
  Typography,
} from '@mui/material';
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css"; // Import the blur effect CSS
import { API_URL } from '../../../../api/config';
import { useState } from 'react';

const Casestudy = ({ data }) => {
  const [imageLoaded,setimageLoaded] = useState(false);
  const handleImageload =() =>{
    setimageLoaded(true);
  }
  return (
    <Container
      sx={{ maxWidth: { xs: "xs", sm: "md", md: "md", lg: "lg", xl: "xl" } }}
    >
      <Box sx={{ marginTop: { xs: "7rem", sm: "8rem", lg: "8rem" } }}>
        <Box
          sx={{
            textAlign: "center",
            pb: { xs: 4, md: 4, lg: 7, xl: 9 },
          }}
        >
          <Typography variant="h3" color="#295768" fontWeight="700">
            Case Study
          </Typography>
        </Box>
        <Box>
          
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box sx={{ width: "100%", height: "100%" }}>

              {!imageLoaded ?(
                 <Skeleton variant="rect"  animation="wave" width="100%" height={700} sx={{borderRadius: "30px" }}/>
              ) : null}
               <LazyLoadImage
        src={API_URL.casestudyImages + data?.cover_photo}
        alt=""
        width="100%"
        loading="lazy"
        effect="blur"
        style={{ borderRadius: "30px" }}
        onLoad={handleImageload}
      />
            
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Casestudy;
