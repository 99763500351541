import React, { useState } from 'react';

import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';

import {
  Box,
  Container,
  Typography,
} from '@mui/material';

import Figma1 from '../../../Image/Figma1.png';
import GoogleDoc from '../../../Image/GoogleDoc.png';
import Protopie from '../../../Image/Protopie.png';
import Skype from '../../../Image/Skype1.png';
import Toolkit from '../../../Image/Toolkit.png';

const ProjectsDuration = () => {
  return (
    <Box sx={{backgroundColor: "#B7E1EB",}}>
    <Container sx={{maxWidth:{xs:"xs",sm:"md",md:"md",lg:"lg",xl:"xl"}}}>
    <Box>
    <Durationview />
  </Box>
  </Container>
  </Box>
  )
}

export default ProjectsDuration

function Durationview() {
    return (
      <Box
        sx={{marginTop: { xs: "4rem", sm: "5rem" } , }}
      >
        <Box sx={{
          display:"flex",
          flexDirection:{xs:"column",sm:"row"},
          justifyContent:{xs:"center",md:"start"},
          py:8
        }}>
          <Box>
            <Box sx={{mb:{xs:2,sm:5}}}>
              <Typography
                variant="h5"
                fontWeight="400"
                color="#295768"
                sx={{ textAlign: { xs: "center", sm: "start" },letterSpacing:"2px"}}
              >
                Projects {" "}
                <span style={{ color: "#0E3347", fontWeight: "700" }}>
                    Duration{" "}
                </span>
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent:{xs:"center",md:"start"}, }}>
              <Box>
                <Counterboxview value="3" subtitle="Week" />
              </Box>
              <Box sx={{pl:10}}>
                <Counterboxview value="85" subtitle="Screen" />
              </Box>
            </Box>
          </Box>
          <Box sx={{ml: { xs: 0, sm: 2, md: 4, lg: 16 },mt:{xs:3,sm:0}}}>
            <Box sx={{mb:3.1}}>
              <Typography
                variant="h5"
                color="#295768"
                fontWeight="400"
                sx={{ textAlign: { xs: "center", sm: "start" },letterSpacing:"2px" }}
              >
                Projects{" "}
                <span style={{ color: "#0E3347", fontWeight: "700" }}>
                  Duration{" "}
                </span>
              </Typography>
            </Box>
            <Box sx={{display:"flex", justifyContent:{xs:"center",md:"start"},}}>
              <Box>
                <Imageview media={Figma1} value="Figma" />
              </Box>
              <Box sx={{px:{xs:1,md:3}}}>
                <Imageview media={Toolkit} value="Miro" />
              </Box>
              <Box>
                <Imageview media={Protopie} value="ProtoPie" />
              </Box>
              <Box sx={{px:{xs:1,md:3}}}>
                <Imageview media={Skype} value="Skype" />
              </Box>
              <Box>
                <Imageview media={GoogleDoc} value="Google Doc" width="2rem"/>
              </Box>
             
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
  
  function Counterboxview({ value, subtitle }) {
    const [counterOn, setCounterOn] = useState(false);
    return (
      <ScrollTrigger
        onEnter={() => setCounterOn(true)}
        onExit={() => setCounterOn(false)}
      >
        <Box>
          <Typography variant="h1" color="#295768" fontWeight="700">
            {counterOn && <CountUp end={value} start={0} duration="1" />}+
          </Typography>
          <Typography variant="body1" color="#295768" fontWeight="400" >
            {subtitle}
          </Typography>
        </Box>
      </ScrollTrigger>
    );
  }
  
  function Imageview({ value, media ,width}) {
    return (
      <Box>
        <Box sx={{width:{xs:"45px",sm:"55px"}}}>
          <img src={media} alt="" width="100%" />
        </Box>
        <Box>
          <Typography variant="body2" fontWeight="400" color="#295768" sx={{textAlign:"center",width:width,}}>
            {value}
          </Typography>
        </Box>
      </Box>
    );
  }
  