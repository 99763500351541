import React, { useEffect, useState } from "react";
import "react-lazy-load-image-component/src/effects/blur.css"; // Import the blur effect CSS

import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";

import { Box, Button, Container, Grid, Typography } from "@mui/material";

import { API_URL } from "../../../api/config";
import { getAllPortfolio } from "../../../api/Portfolio";
import Typographyview from "../../CommonSectionweb/Typographyview";

const Portfolio = ({ url }) => {
  const navigator = useNavigate("");
  // Portfolio
  const [PortfoioList, setPortfoioList] = useState([]);

  useEffect(() => {
    const getPortfolio = async () => {
      const data = await getAllPortfolio();
      if (data?.s) setPortfoioList(data?.r);
    };
    getPortfolio();
  }, []);

  return (
    <Container
      sx={{ maxWidth: { xs: "xs", sm: "md", md: "md", lg: "lg", xl: "xl" } }}
    >
      <Box sx={{ marginTop: { xs: "4rem", md: "5rem" } }}>
        <Box>
          <Typographyview
            value="Our unique portfolio"
            custom={
              <Typography
                variant="body1"
                color="#484848"
                fontWeight="400"
                sx={{
                  paddingTop: "1.5rem",
                }}
              >
                As a web & mobile app development company here is a glimpse of
                our work done. <br /> We have a strong work portfolio.
              </Typography>
            }
          />
        </Box>
        <Box>
          {/* portfolio list */}
          <Grid
            container
            spacing={{
              xs: 3,
              sm: 2,
              md: 5,
              lg: 3,
              xl: 4,
            }}
          >
            {PortfoioList?.slice(0, 6).map((data, i) => {
              return (
                <Gridview key={i}>
                  <Portfolioview data={data} />
                </Gridview>
              );
            })}
          </Grid>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2rem",
          }}
        >
          <Button
            onClick={() => navigator("portfolio")}
            sx={{
              color: "#fff",
              backgroundColor: "#295768",
              border: "1px solid #295768",
              borderRadius: "50px",
              textTransform: "none",
              fontWeight: "500",
              fontSize: { xs: "12px", sm: "15px" },
              px: { xs: 4, sm: 4.5 },
              py: { xs: 1, sm: 1 },
              "&:hover": {
                border: "1.5px solid #295768",
                color: "#295768",
              },
            }}
          >
            See Portfolio
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Portfolio;

function Portfolioview({ data, url }) {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const image = new Image();
    image.src = API_URL.portfolioImages + data?.image;
    image.onload = () => {
      setImageLoaded(true);
    };
  }, [data?.image]);
  const navigator1 = () => {
    window.open(url);
  };
  return (
    // <AnimationOnScroll animateIn="animate__fadeInUpBig">
    <Box
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-offset="0"
      onClick={() =>
        navigator1(
          (url = `/projectview/${data?.id}/${data?.title.replace(/\s+/g, "-")}`)
        )
      }
      sx={{
        position: "relative",
        cursor: "pointer",
        borderRadius: "30px",
        // overflow: "hidden",
      }}
    >
      <Box className="hovereffect" sx={{ transition: "all 0.5s ease 0s" }}>
        <Box
          sx={{
            width: "100%",
            position: "relative",
            transition: "1s ease",
          }}
        >
          <Box
            sx={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // maxHeight: "769px",
            }}
          >
            {imageLoaded ? (
              <LazyLoadImage
                src={API_URL.portfolioImages + data?.image}
                alt=""
                effect="blur"
                width="100%"
                height="100%"
                style={{
                  borderRadius: "15px",
                  // boxShadow: "0 0 8px 8px #c0c0c0 inset",
                  aspectRatio: "1280 / 769",
                }}
              />
            ) : (
              <img
                src={API_URL.portfolioImages + data?.image}
                alt=""
                width="100%"
                height="100%"
                style={{
                  position: "relative",
                  borderRadius: "15px",
                  aspectRatio: "1280 / 769",
                  boxShadow: "0 0 8px 8px #c0c0c0 inset",
                  //filter: "blur(10px)", // Apply the blur effect to the placeholder image
                }}
              />
            )}
          </Box>
          <Box
            sx={{
              background:
                "linear-gradient(180deg, rgba(0, 0, 0, 0) 71.35%, rgba(0, 0, 0, 0.7) 100%)",
              position: "absolute",
              filter: "drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.2))",
              bottom: "0",
              mb: "0.3rem",
              width: "100%",
              height: "100%",
              zIndex: "1",
              borderRadius: "13px",
            }}
          ></Box>
        </Box>

        <Box
          sx={{
            position: "absolute",
            top: { xs: "82%", sm: "82%" },
            bottom: "0",
            left: "6%",
            right: "0",
            zIndex: "1",
          }}
        >
          <Typography variant="h2" color="#fff" sx={{ fontWeight: 700 }}>
            {data?.title}
          </Typography>
        </Box>
      </Box>
    </Box>
    // </AnimationOnScroll>
  );
}

function Gridview({ children }) {
  return (
    <Grid item xs={12} sm={6} lg={4}>
      {children}
    </Grid>
  );
}
