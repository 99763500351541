import { Box } from '@mui/material'
import React from 'react'
import Ourwork from '../../CommonSection/Ourwork'
import Ourwork1 from "../../../Image/OurWork1.png"

const OurWorkDigital = () => {
  return (
    <Box>
    <Ourwork
      media={Ourwork1}
      i="row-reverse"
      ex={{ pl: { xs: "0", md: 4, xl: 6 } }}
    />
  </Box>
  )
}

export default OurWorkDigital