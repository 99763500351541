import axios from "axios";

const apiURL = "https://api.equitysoft.in";

export const API_URL = {
  // Base Url
  // baseUrl: "https://website-api.equitysofttechnologies.com",
  baseUrl: apiURL,

  // gallery images
  getAllGellaryimages: "/api/banner/get-gallery-images",
  galleryImages:
    // "https://website-api.equitysofttechnologies.com/uploads/banner/images/",
    apiURL + "/uploads/banner/images/",
  // gallery video
  getGalleryVideo: "/api/banner/get-video",
  galleryVideo:
    // "https://website-api.equitysofttechnologies.com/uploads/banner/video/",
    apiURL + "/uploads/banner/video/",

  // BLog Url

  getAllBlog: "/api/blog/get-all",
  getBlogById: "/api/blog/get-by-id?id=",
  //Image
  blogImage:
    // "https://website-api.equitysofttechnologies.com/uploads/blog/media/",
    apiURL + "/uploads/blog/media/",
  updateViewCount: "view-count",

  //   Category url
  //BLOG
  getALlCategory: "/api/category/get-all",

  //tech fest blog

  getAllTechFest: "/api/blog/get-all-fest",
  techFestImage:
    // "https://website-api.equitysofttechnologies.com/uploads/upcoming_festival_media/image/",
    apiURL + "/uploads/upcoming_festival_media/image/",

  //Ourportfolio
  getportfoliocategory: "/api/category/get-all-portfolio",

  //Casestudy
  getcasestudycategory: "/api/category/get-all-casestudy",

  // subscribe api

  createSubscribe: "/api/subscribe/add",

  // Services
  getAllServices: "/api/services/get-by-id?id=",
  servicesimages:
    // "https://website-api.equitysofttechnologies.com/uploads/services/image/",
    apiURL + "/uploads/services/image/",

  // portfolio
  getAllportfolio: "/api/portfolio/get-all",
  portfolioImages:
    // "https://website-api.equitysofttechnologies.com/uploads/portfolio/image/",
    apiURL + "/uploads/portfolio/image/",
  getPotfolioById: "/api/portfolio/get-by-id?id=",

  // case Study
  getAllCasestudy: "/api/casestudy/get-all",
  casestudyImages:
    // "https://website-api.equitysofttechnologies.com/uploads/case_study/image/",
    apiURL + "/uploads/case_study/image/",
  getCasestudyById: "/api/casestudy/get-by-id?id=",
  gethighlightsAll: "/api/casestudy/highlights/get?case_id=",

  // Testimonials

  getAllTestimonials: "/api/testimonials/get-all",
  getTestimonialsById: "/api/testimonials/get-client-by-id?id=",
  testimonialsImages:
    // "https://website-api.equitysofttechnologies.com/uploads/testimonials/image/",
    apiURL + "/uploads/testimonials/image/",

  // contact us
  createContact: "/api/contacts/add",
  // Career

  getAllCareer: "/api/career/get-all",
  careerImage:
    // "https://website-api.equitysofttechnologies.com/uploads/career/image/",
    apiURL + "/uploads/career/image/",

  // Client image
  getAllClientImage: "/api/testimonials/get-img",
  clientImage:
    // "https://website-api.equitysofttechnologies.com/uploads/testimonials/image/",
    apiURL + "/uploads/testimonials/image/",
};

const Axios = axios.create({
  baseURL: API_URL.baseUrl,
});

export const API_GET = async (url, data = "") => {
  try {
    const res = await Axios.get(url + data, { headers: null });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const API_POST = async (url, data) => {
  try {
    const res = await Axios.post(url, data);
    return res.data;
  } catch (error) {
    return error;
  }
};
