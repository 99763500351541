import { useState } from 'react';

// import React from 'react'
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';

import {
  Box,
  Typography,
} from '@mui/material';

const Counter = () => {

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#0E3347",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: { xs: "100%", sm: "250px" },
        marginTop: { xs: "4rem", md: "5rem" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row", alignItems: "center" },
          marginBlock: { xs: "1.5rem", sm: "0" },
        }}
      >
        <Box>
          <CounterView value="500" subtitle="Projects Delivered" />
        </Box>
        <Box
          sx={{
            marginInline: { xs: "0", sm: "1.5rem", md: "6rem", lg: "10rem" },
            marginBlock: { xs: "1rem", sm: "0" },
          }}
        >
          <CounterView value="280" subtitle="Happy Clients" />
        </Box>
        <Box
          sx={{
            marginRight: { xs: "0", sm: "1.5rem", md: "6rem", lg: "10rem" },
            marginBottom: { xs: "1rem", sm: "0" },
          }}
        >
          <CounterView value="10" subtitle="Years Experience" />
        </Box>
        <Box>
          <CounterView value="50" subtitle="People Inhouse Team" />
        </Box>
      </Box>
    </Box>
  );
};

export default Counter;


function CounterView({ value, subtitle }) {
  const [counterOn, setCounterOn] = useState(false);
  return (
    <ScrollTrigger
      onEnter={() => setCounterOn(true)}
      onExit={() => setCounterOn(false)}
    >
      <Box>
        <Typography
          variant="h3"
          color="#fff"
          fontWeight="700"
          sx={{
            textAlign: "center",
          }}
        >
          {counterOn && <CountUp end={value} start={0} duration="1" />}+
        </Typography>
        <Typography variant="body1" fontWeight="700" color="#E0F6F9" sx={{
          paddingTop:"0.8rem"
        }}>
          {subtitle}
        </Typography>
      </Box>
    </ScrollTrigger>
  );
}

