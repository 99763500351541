import React from "react";
import Typography from "@mui/material/Typography";
import { Box, Container } from "@mui/material";
import Welcome1 from "../../Image/Welcome.png";

const Welcome = () => {
  return (
    <Container
      sx={{ maxWidth: { xs: "xs", sm: "md", md: "md", lg: "lg", xl: "xl" } }}
    >
      <Box
        id="Welcome"
        sx={{
          flex: "1",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: { xs: "center", md: "space-between" },
            marginTop: { xs: "4rem", sm: "5rem" },
            // px:{ xs:3,sm:"3rem",md:"5rem",lg:"6rem",xl:"8rem"},
            alignItems: "center",
          }}
        >
          <Box sx={{ mr: { xs: 0, md: 1, lg: 4, xl: 6 } }}>
            <Box>
              <TextView />
            </Box>
          </Box>
          <Box>
            <Box
              sx={{
                marginTop: { xs: "1rem", lg: "0.5rem" },
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box data-aos="fade-up" sx={{ width: "100%" }}>
                <img src={Welcome1} alt="" width="100%" />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Welcome;
function TextView() {
  return (
    <Box>
      <Box
        sx={{
          textAlign: { xs: "center", md: "start" },
        }}
      >
        <Typography
          data-aos="fade-down"
          variant="h1"
          color="#0E3347"
          fontWeight="700"
          sx={{
            paddingBottom: "1rem",
          }}
        >
          Welcome To Equitysoft
        </Typography>
        <Typography
          variant="h5"
          color="#295768"
          data-aos="fade-up"
          fontWeight="500"
          maxWidth="50rem"
          sx={{
            pb: "1rem",
          }}
        >
          A leading provider of innovative and comprehensive app and web
          development solutions.
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box maxWidth="50rem">
          <Typography
            variant="body1"
            data-aos="fade-up"
            color="#484848"
            sx={{
              paddingBottom: "0.5rem",
              textAlign: { xs: "center", md: "start" },
            }}
          >
            At Equitysoft, we are passionate about delivering cutting-edge
            technology solutions that help our clients stay ahead of the curve
            in today's fast-paced digital landscape. Our highly skilled and
            experienced developers work closely with clients to understand their
            specific needs and develop customized solutions that meet their
            goals and objectives.
          </Typography>

          {/* <Typography
            variant="body1"
            data-aos="fade-up"
            color="#484848"
            sx={{
              paddingBottom: "0.5rem",
              textAlign: { xs: "center", md: "start" },
            }}
          >
            We are your best technology outsourcing company. We specialize in
            providing top-notch app and web development digital marketing and
            cloud computing solutions. Our goal is to help you thrive in the
            digital world by delivering tailored technology solutions that
            perfectly fit your needs. With our team of skilled developers, we
            ensure that every project is executed with precision and care to
            achieve your objectives.
          </Typography>

          <Typography
            variant="body1"
            data-aos="fade-up"
            color="#484848"
            sx={{
              paddingBottom: "0.5rem",
              textAlign: { xs: "center", md: "start" },
            }}
          >
            Whether you're looking to create a user-friendly mobile app or a
            dynamic website, Equitysoft has the expertise and resources to bring
            your vision to life. As a web,mobile app and Software development
            agency, we always try to prioritize collaboration, communication,
            and quality to ensure that your project exceeds expectations. Let us
            help you transform your ideas into reality and achieve your business
            objectives.
          </Typography> */}
        </Box>
      </Box>
    </Box>
  );
}
