import { Box } from "@mui/material";
import MongoDB1 from "../../../Image/MongoDB.png";
import ChildBanner from "../../CommonSection/ChildBanner";


const MongoDB = () => {
  return (
    <Box>
    <ChildBanner
      value={"Database Management"}
      title={"MongoDB"}
      subtitle={
        "Explore the world of NoSQL with MongoDB, a flexible, document-based database. Our MongoDB solutions enable you to store and manage unstructured data with ease. "
      
      }
      subtitle1={
        "Experience the agility to adapt to changing requirements and build applications that scale effortlessly. MongoDB empowers your data-driven initiatives, facilitating real-time analytics and enhanced user experiences."
      }
     
      image={MongoDB1}
      i={"row-reverse"}
      none1={"none"}
      ex={{pl:{xs:"0",md:4,xl:6}}}
      aos={"fade-down-right"}
    />
    </Box>
  )
}

export default MongoDB



  