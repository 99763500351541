import { Box} from "@mui/material";
import Native from "../../../Image/Native.png";
import ChildBanner from "../../CommonSection/ChildBanner";

const Cloudnative = () => {
  return (
    <Box>
      <ChildBanner
        value={"App modernization and migration "}
        title={"Cloud-Native Architecture."}
        subtitle={
          "Cloud-native archite cture is a software design and development approach optimised for deployment and operation on cloud computing platforms. It is based on a set of principles that assist in building and running scalable, resilient, and agile applications."
        }
        subtitle1={
          "The cloud-native architecture enables organisations to build and operate applications that quickly adapt to changing business needs and market conditions. It also allows them to take advantage of cloud computing platforms' scalability, cost-effectiveness, and flexibility."
        }
        none1={"none"}
        image={Native}
        ex={{pr:{xs:"0",md:4,xl:6}}}
        width="150%"
        i={"row"}
        aos={"fade-down-left"}
      />
    </Box>
  )
}

export default Cloudnative

