import ChildBanner from '../../CommonSection/ChildBanner'
import { Box } from '@mui/material'

import reactapp from "../../../Image/reactapp.png";
import Swift from "../../../Image/Swift.png";
import Apple1 from "../../../Image/Apple1.png";
import React from "../../../Image/React.png";
 
 const Reactnativeapp = () => {
   return (
    <Box>
    <ChildBanner
      value={"Hybrid App Development"}
      title={"React Native App Development"}
      subtitle={
        "Looking to turn your app vision into a reality? Discover React Native App Development. It's the fusion of native performance and a unified codebase.  "
      }
      subtitle1={
        "With React Native, we offer an affordable, speedy way to launch your app on both iOS and Android. Imagine a stunning, feature-rich app that works seamlessly across devices, without the hassle of maintaining separate codebase. React Native empowers you to make it happen, making your app journey brighter than ever."
      }
      media={Apple1}
      media1={Swift}
      media2={React}
      image={reactapp}
      none2={"none"}
      i={"row-reverse"}
      none={"none"}
      ex={{pl:{xs:"0",md:4,xl:6}}}
      width="150%"
      aos={"fade-down-right"}
    />
    </Box>
   )
 }
 
 export default Reactnativeapp