import { Box } from '@mui/material'
import React from 'react'
import HeaderChild from '../../CommonSection/HeaderChild'

const Database = () => {
  return (
    <Box>
    <HeaderChild
      none={"none"}
      value={"Database management"}
      subtitle={"WHAT WE DO"}
      title={
        "Crafting Custom AI Solutions for Your Business Growth"
      }
      title1={
        "Unlock the potential of AI with our tailored solutions. We develop AI applications that drive efficiency, automation, and growth."
      }
    />
  </Box>
  )
}

export default Database