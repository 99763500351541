import React, { useEffect, useState } from "react";

import copy from "clipboard-copy";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";

// import Send from "../../Image/Send.svg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";

import { getAllBlog, updateBlogViewCount } from "../../../api/Blog";
import { API_URL } from "../../../api/config";
import Typographyview from "../../CommonSectionweb/Typographyview";
import { Blogview } from "../../../common/BlogView";

const Blog = () => {
  const navigator = useNavigate("");
  const { id } = useParams();
  const [BlogList, setBlogList] = useState([]);
  const [loading, Setloading] = useState("");
  const getBlog = async () => {
    Setloading(true);
    const data = await getAllBlog(id);

    if (data?.s) setBlogList(data?.r);
    Setloading(false);
  };

  useEffect(() => {
    getBlog();
  }, []);

  return (
    <Container
      sx={{ maxWidth: { xs: "xs", sm: "md", md: "md", lg: "lg", xl: "xl" } }}
    >
      <Box id="blog" sx={{ marginTop: { xs: "4rem", md: "5rem" } }}>
        <Box>
          <Typographyview
            value="Our Blog"
            subtitle="We write about technology, web and mobile app trends, UIUX design, and more"
          />
        </Box>
        <Box>
          <Grid
            container
            spacing={{
              xs: 3,
              sm: 2,
              md: 5,
              lg: 3,
              xl: 4,
            }}
          >
            {loading
              ? [1, 2, 3, 4, 5, 6].map((r) => (
                  <Gridview key={r}>
                    <Skeleton
                      variant="rounded"
                      animation="wave"
                      width="100%"
                      sx={{
                        borderRadius: "1rem",
                        height: {
                          xs: "300px",
                          lg: "400px",
                        },
                      }}
                    />
                  </Gridview>
                ))
              : BlogList?.splice(0, 6).map((n, i) => {
                  return (
                    <Gridview key={i}>
                      <Blogview data={n} />
                    </Gridview>
                  );
                })}
          </Grid>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2rem",
          }}
        >
          <Button
            onClick={() => navigator("/blog")}
            sx={{
              color: "#fff",
              backgroundColor: "#295768",
              borderRadius: "50px",
              textTransform: "none",
              border: "2px solid #295768",
              fontWeight: "500",
              fontSize: { xs: "12px", sm: "15px" },
              px: { xs: 4, sm: 4.5 },
              py: { xs: 1, sm: 1 },
              "&:hover": {
                border: "2px solid rgba(41, 87, 104, 0.6)",
                color: "#295768",
              },
            }}
          >
            View Blog
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Blog;

function Gridview({ children }) {
  return (
    <Grid item xs={12} sm={6} lg={4}>
      {children}
    </Grid>
  );
}
