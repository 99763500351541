import { Box } from "@mui/material";
import webdesign from "../../../Image/web design.png";
import Express from "../../../Image/Express.png";
import Laravel from "../../../Image/Laravel.png";
import Firebase from "../../../Image/Firebase.png";
import Js from "../../../Image/Js.png";
import ChildBanner from "../../CommonSection/ChildBanner";


const Webdesigning = () => {
  return (
    <Box>
    <ChildBanner
      value={"Web Development "}
      title={"Web Designing"}
      subtitle={
        "Your website's first impression matters. Our dedicated web designers focus on creating captivating, user-friendly interfaces that leave a lasting impact."
      
      }
      subtitle1={
        "We understand the importance of a visually appealing website and strive to make every visitor's experience memorable."
      }
      media={Express}
      media1={Js}
      media2={Laravel}
      media3={Firebase}
      image={webdesign}
      i={"row-reverse"}
      none2={"none"}
      ex={{pl:{xs:"0",md:4,xl:6}}}
      aos={"fade-down-right"}
    />
    </Box>
  )
}

export default Webdesigning



  