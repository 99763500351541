import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import Time from '../AbountScreen/Time'
import Achievement from '../../Home/Screen/Achievement'
import Testimonials from '../../Home/Screen/Testimonials'
import WhyChoose from '../../Home/Screen/WhyChoose'
import AbountScreen from '../AbountScreen/AbountScreen'
import Creative from '../AbountScreen/Creative'
import Longway from '../AbountScreen/Longway.jsx'
import Photos from '../AbountScreen/Photos'
import Visionaries from '../AbountScreen/Visionaries'
import Welcome from '../AbountScreen/Welcome'
import Who from '../AbountScreen/Who'


const About = () => {
  useEffect(() => window.document.scrollingElement?.scrollTo(0, 0), [])
  return (
   <Box>
    <AbountScreen/>
    <Welcome/>
    <Who/>
    <Longway/>
    <Time/>
    <Creative/>
    <Visionaries/>
    <WhyChoose/>
    <Testimonials/>
    <Achievement/>
    <Photos/>

   </Box>
  )
}

export default About