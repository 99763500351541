import React from "react";
import { Box } from "@mui/material";
import HeaderView from "../../CommonSectionweb/HeaderView";

const AbountScreen = () => {
  return (
    <Box>
      <HeaderView
        value="We provide development solutions that propel organizations to the pinnacle."
        subtitle="From design to development, Equitysoft delivers everything to help businesses soar high & high."
      />
    </Box>
  );
};

export default AbountScreen;
