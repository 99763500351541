import { API_GET, API_URL } from "./config";

export const getAllCategory = () => {
  const data = API_GET(API_URL.getALlCategory);
  return data;
};
export const getAllcasestudycategory = () => {
  const data = API_GET(API_URL.getcasestudycategory);
  return data;
};
export const getAllportfoliocategory = () => {
  const data = API_GET(API_URL.getportfoliocategory);
  return data;
};
