import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import Contact from '../../Service/ServiceScreen/Contact'
import YourProject from '../Screen/AppPage/YourProject'
import OurProcess from '../Screen/UIUXPage/OurProcess'
import OurWorkWeb from '../Screen/WebPage/OurWorkWeb'

import Webdesigning from '../Screen/WebPage/Webdesigning'
import Pythonweb from '../Screen/WebPage/Pythonweb'
import NodeNestExpressjs from '../Screen/WebPage/NodeNestExpressjs'
import Database from '../Screen/DatabasePage/Database'
import Architecture from '../Screen/DatabasePage/Architecture'
import Hosting from '../Screen/DatabasePage/Hosting'
import Mysql from '../Screen/DatabasePage/Mysql'
import MongoDB from '../Screen/DatabasePage/MongoDB'
import Nosql from '../Screen/DatabasePage/Nosql'
import Firebase from '../Screen/DatabasePage/Firebase'
import PostgreSQL from '../Screen/DatabasePage/PostgreSQL'
import OurWorkdata from '../Screen/DatabasePage/OurWorkdata'

const Databasepage = () => {
  useEffect(() => window.document.scrollingElement?.scrollTo(0, 0), [])
  return (
    <Box>
        <Database/>
        <YourProject/>
        <Architecture/>
        <Hosting/>
        <Mysql/>
        <MongoDB/>
        <Nosql/>
        <PostgreSQL/>
        <Firebase/>
        <OurWorkdata/>
       
        <Contact/>
    </Box>
  )
}

export default Databasepage