import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD50vi1ZywTENVQR-jGq2B8o-fPf8PEBlo",
  authDomain: "equitysoft-web.firebaseapp.com",
  projectId: "equitysoft-web",
  storageBucket: "equitysoft-web.appspot.com",
  messagingSenderId: "409362375431",
  appId: "1:409362375431:web:34abe9b1894a832dc69ba2",
  measurementId: "G-J9QRFZQYVQ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
