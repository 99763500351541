import React, { useEffect, useState } from "react";

import { LazyLoadImage } from "react-lazy-load-image-component";

import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Skeleton,
  Typography,
} from "@mui/material";

// import {  } from "../../../../utils/Constent/ChildPage/CasestudyConstent";
import { getAllCaseStudy } from "../../../../api/CaseStudy";
import { API_URL } from "../../../../api/config";
import HeaderChild from "../../CommonSection/HeaderChild";
import "react-lazy-load-image-component/src/effects/blur.css"; // Import the blur effect CSS
import { getAllcasestudycategory } from "../../../../api/Category";

export const PortfolioCasestudy = () => {
  const [Select_cate, setSelect_cate] = useState("");

  // category list

  const [CategortList, setCategortList] = useState([]);
  useEffect(() => {
    const getcategoty = async () => {
      const data = await getAllcasestudycategory();

      if (data?.s) setCategortList(data?.r);
    };

    getcategoty();
  }, []);

  const [CaseStudyList, setCaseStudyList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const data = await getAllCaseStudy(Select_cate);

      if (data?.s) {
        setIsLoading(false);
        setCaseStudyList(data?.r);
      }
    };

    getData();
  }, [Select_cate]);

  return (
    <Container
      sx={{ maxWidth: { xs: "xs", sm: "md", md: "md", lg: "lg", xl: "xl" } }}
    >
      <Box>
        <Box>
          <HeaderChild
            none1={"none"}
            value={"Case Study"}
            title={
              "Transform your services with the expertise of a committed developer."
            }
            none={"none"}
          />
        </Box>

        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            justifyContent: "center",
            flexWrap: "wrap",
            mt: 3,
            mb: 5,
            gap: "10px",
          }}
        >
          <Box>
            <Buttonview value="All" onClick={() => setSelect_cate("")} />
          </Box>
          {CategortList?.map((item) => (
            <Box key={item?.id}>
              <Buttonview
                value={item?.name}
                onClick={() => setSelect_cate(item.id)}
              />
            </Box>
          ))}
        </Box>

        <Box
          sx={{
            display: { xs: "flex", md: "none" },
            mt: 2,
            mb: 3,
          }}
        >
          <FormControl sx={{ minWidth: 180, backgroundColor: "#E0F6F9" }}>
            <Select
              sx={{
                height: "45px",
                color: "#0E3347",

                "& .MuiOutlinedInput-notchedOutline": {
                  color: "#0E3347",
                  border: "1px solid #E0F6F9",
                },
                "& .MuiSvgIcon-root": {
                  color: "#0E3347;",
                },
              }}
              value={Select_cate}
              onChange={(e) => setSelect_cate(e.target.value)}
              displayEmpty
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              {CategortList?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>

      <Box>
        <Box
          sx={{
            mt: { sm: "1rem", md: "1rem" },
          }}
        >
          <Grid
            container
            spacing={{
              xs: 3,
              sm: 2,
              md: 5,
              lg: 3,
              xl: 5,
            }}
          >
            {isLoading
              ? [0, 1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
                  <Gridview key={i}>
                    <Skeleton
                      variant="rounded"
                      animation="wave"
                      width="100%"
                      sx={{
                        borderRadius: "1rem",
                        height: {
                          xs: "240px",
                          lg: "300px",
                        },
                      }}
                    />
                  </Gridview>
                ))
              : CaseStudyList.map((n) => {
                  return (
                    <Gridview key={n?.id}>
                      <Portfolioview data={n} />
                    </Gridview>
                  );
                })}
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

function Portfolioview({ data, url }) {
  // const [imageLoaded, setImageLoaded] = useState(false);

  // useEffect(() => {
  //   const image = new Image();
  //   image.src = API_URL.casestudyImages + data?.cover_photo;
  //   image.onload = () => {
  //     setImageLoaded(true);
  //   };
  // }, [data?.image]);
  const navigator = () => {
    window.open(url);
  };
  return (
    <Box className="container1">
      <Box
        sx={{
          position: "relative",
          cursor: "alias",
          borderRadius: "15px",
        }}
      >
        <Box
          sx={{
            maxHeight: "769px",
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LazyLoadImage
            src={API_URL.casestudyImages + data?.cover_photo}
            alt=""
            effect="blur"
            width="100%"
            height="100%"
            style={{
              borderRadius: "15px",
              // boxShadow: "0 0 8px 8px #c0c0c0 inset",
              aspectRatio: "1280 / 769",
            }}
          />
        </Box>
        <Box
          sx={{
            background:
              "linear-gradient(180deg, rgba(0, 0, 0, 0) 71.35%, rgba(0, 0, 0, 0.7) 100%)",
            position: "absolute",
            filter: "drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.2))",
            bottom: "0",
            mb: "0.3rem",
            width: "100%",
            height: "100%",
            zIndex: "1",
            borderRadius: "13px",
          }}
        ></Box>
        <Box
          sx={{
            mr: 2,
            position: "absolute",

            top: { xs: "82%", sm: "84%" },
            bottom: "0",
            left: "6%",
            right: "0",
            zIndex: "1",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <Typography
            variant="h2"
            color="#fff"
            sx={{
              fontWeight: 700,
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {data?.title}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Box>
          <Box className="overlay1">
            <Box className="text1">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 3,
                }}
              >
                <Button
                  onClick={() =>
                    navigator(
                      (url = `/case-study/${data?.id}/${data?.title.replace(
                        /\s+/g,
                        "-"
                      )}`)
                    )
                  }
                  sx={{
                    color: "#0E3347",
                    backgroundColor: "#fff",
                    borderRadius: "50px",
                    textTransform: "none",
                    border: "1px solid #fff",
                    fontSize: { xs: "12px", sm: "15px" },
                    fontWeight: "800",
                    px: { xs: 3, sm: 3, lg: 4.5 },
                    py: { xs: 1, sm: 1 },
                    "&:hover": {
                      backgroundColor: "transparent",
                      color: "#fff",
                      border: "1px solid #fff",
                    },
                  }}
                >
                  Know More
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function Gridview({ children }) {
  return (
    <Grid item xs={12} sm={6} lg={4}>
      {children}
    </Grid>
  );
}

function Buttonview({ value, onClick }) {
  return (
    <>
      <Button
        onClick={onClick}
        sx={{
          backgroundColor: "#E0F6F9",
          color: "#0E3347",
          textTransform: "none",
          borderRadius: "50px",
          fontWeight: "800",
          fontSize: { xs: "12px", sm: "16px" },
          px: { xs: 4, sm: 4 },
          "&:hover": {
            color: "#fff",
            backgroundColor: "#0E3347",
          },
        }}
      >
        {value}
      </Button>
    </>
  );
}
