import React, { useEffect, useState } from "react";

import { LazyLoadImage } from "react-lazy-load-image-component";

import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import "react-lazy-load-image-component/src/effects/blur.css"; // Import the blur effect CSS

import { API_URL } from "../../../api/config";
import { getAllPortfolio } from "../../../api/Portfolio";
import Typographyview1 from "../../CommonSectionweb/Typographyview1";
import { getAllportfoliocategory } from "../../../api/Category";

const Ourpalette = () => {
  const [Select_cate, setSelect_cate] = useState("");

  // category list

  const [CategortList, setCategortList] = useState([]);
  useEffect(() => {
    const getcategoty = async () => {
      const data = await getAllportfoliocategory();
      if (data?.s) setCategortList(data?.r);
    };

    getcategoty();
  }, []);

  // portfolio list
  const [PortfoioList, setPortfoioList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getPortfolio = async () => {
      setIsLoading(true);
      const data = await getAllPortfolio(Select_cate);
      if (data?.s) {
        setPortfoioList(data?.r);
        setIsLoading(false);
      } else {
        setPortfoioList([]);
        setIsLoading(false);
      }
    };
    getPortfolio();
  }, [Select_cate]);

  return (
    <Container
      sx={{ maxWidth: { xs: "xs", sm: "md", md: "md", lg: "lg", xl: "xl" } }}
    >
      <Box id="Ourpalette" sx={{ marginTop: { xs: "4rem", sm: "5rem" } }}>
        <Box>
          <Typographyview1
            value="Our Palette"
            subtitle="Some of our colors that you can have a stroll…"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "start", sm: "start", md: "start" },
            alignItems: "center",
          }}
        >
          {/* category section start */}
          <Box>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                flexWrap: "wrap",
                justifyContent: "center",
                mt: 0,
                mb: 0,
                gap: "10px",
              }}
            >
              <Box>
                <Buttonview value="All" onClick={() => setSelect_cate("")} />
              </Box>
              {CategortList?.map((item) => (
                <Box key={item?.id}>
                  <Buttonview
                    value={item?.name}
                    onClick={() => setSelect_cate(item.id)}
                  />
                </Box>
              ))}
            </Box>

            <Box
              sx={{
                display: { xs: "flex", md: "none" },
                mt: 1,
                mb: 2,
              }}
            >
              <FormControl sx={{ minWidth: 180, backgroundColor: "#E0F6F9" }}>
                <Select
                  sx={{
                    height: "45px",
                    color: "#0E3347",

                    "& .MuiOutlinedInput-notchedOutline": {
                      color: "#0E3347",
                      border: "1px solid #E0F6F9",
                    },
                    "& .MuiSvgIcon-root": {
                      color: "#0E3347;",
                    },
                  }}
                  value={Select_cate}
                  onChange={(e) => setSelect_cate(e.target.value)}
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>All</em>
                  </MenuItem>
                  {CategortList?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          {/* category section end */}
        </Box>
        <Box
          sx={{
            mt: { xs: "1rem", md: "2.5rem", lg: "2.5rem" },
          }}
        >
          {/* Portfoilio list */}
          <Grid
            container
            spacing={{
              xs: 3,
              sm: 2,
              md: 5,
              lg: 3,
              xl: 4,
            }}
          >
            {isLoading
              ? [1, 2, 3, 4, 5, 6, 7, 8, 9].map((a) => (
                  <Gridview key={a}>
                    <Skeleton
                      variant="rounded"
                      animation="wave"
                      width="100%"
                      sx={{
                        borderRadius: "1rem",
                        height: {
                          xs: "240px",
                          lg: "300px",
                        },
                      }}
                    />
                  </Gridview>
                ))
              : PortfoioList?.map((e) => {
                  return (
                    <Gridview key={e.id}>
                      <Paletteview data={e} />
                    </Gridview>
                  );
                })}
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default Ourpalette;

function Buttonview({ value, onClick }) {
  return (
    <Box sx={{}}>
      <Button
        onClick={onClick}
        sx={{
          backgroundColor: "#E0F6F9",
          color: "#0E3347",
          textTransform: "none",
          borderRadius: "50px",
          fontWeight: "800",
          fontSize: { xs: "12px", sm: "16px" },
          px: { xs: 4, sm: 4 },
          "&:hover": {
            color: "#fff",
            backgroundColor: "#0E3347",
          },
        }}
      >
        {value}
      </Button>
    </Box>
  );
}

function Paletteview({ data, url }) {
  const ButtonData = [
    {
      value: "UI UX",
    },
    {
      value: "App",
      mx: { xs: 0.5, md: 1, lg: 0.2, xl: 1.5 },
    },
    {
      value: "Web",
    },
  ];

  const navigator = () => {
    window.open(url);
  };

  return (
    <Card
      className="hovereffect"
      sx={{
        position: "relative",
        transition: "all 0.5s ease 0s",
        boxShadow: 0,
        maxHeight: "769px",
      }}
    >
      <Box
        onClick={() =>
          navigator(
            (url = `/projectview/${data?.id}/${data?.title.replace(
              /\s+/g,
              "-"
            )}`)
          )
        }
        sx={{
          position: "relative",
          cursor: "pointer",
          borderRadius: "15px",
        }}
      >
        <Box
          sx={{
            maxHeight: "769px",

            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LazyLoadImage
            src={API_URL.portfolioImages + data?.image}
            placeholderSrc="https://placehold.co/1280x769"
            prefix="https://placehold.co/1280x769"
            width="100%"
            height="100%"
            effect="blur"
            style={{
              aspectRatio: "1280 / 769",
              borderRadius: "15px",
            }}
          />
        </Box>
        <Box
          sx={{
            background:
              "linear-gradient(180deg, rgba(0, 0, 0, 0) 71.35%, rgba(0, 0, 0, 0.7) 100%)",
            position: "absolute",

            bottom: "0",
            overflow: "hidden",

            mb: "0.3rem",
            width: "100%",
            height: "100%",
            zIndex: "1",
            borderRadius: "13px",
          }}
        ></Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                bottom: { xs: "24%", sm: "24%", md: "7%" },
                left: { xs: "3%", md: "3%" },
                zIndex: "1",
              }}
            >
              <Typography variant="body3" color="#fff" fontWeight="700">
                {data?.title}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              pl: { xs: 1, md: 0 },
              position: "absolute",
              bottom: "5%",
              right: { xs: "none", md: "3%" },
              zIndex: "1",
              display: "flex",
            }}
          >
            {ButtonData.map((item, i) => {
              return (
                <Box key={i}>
                  <ButtonGroup {...item} />
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

function Gridview({ children }) {
  return (
    <Grid item xs={12} sm={6} lg={4}>
      {children}
    </Grid>
  );
}

function ButtonGroup({ value, mx }) {
  return (
    <Button
      sx={{
        mx: mx,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        px: 2,
        py: { xs: 0.3, md: 0.3, lg: 0.2, xl: 0.2 },
        borderRadius: "50px",
        textTransform: "none",
        fontWeight: "400",
        fontSize: "14px",
        "&:hover": {
          color: "#fff",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
      }}
    >
      {value}
    </Button>
  );
}
