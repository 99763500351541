import React from 'react';

import { AnimationOnScroll } from 'react-animation-on-scroll';

import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
} from '@mui/material';

import { creativeData } from '../../../utils/Constent/About/AboutConstent';

const Creative = () => {
  return (
    <Box
      sx={{
        textAlign: "center",
        marginTop: { xs: "4rem", sm: "5rem", lg: "5rem" },
      }}
    >
      <Box>
        <AnimationOnScroll animateIn="animate__fadeInDown" delay={100}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <Box
              className="Typographyview"
              sx={{
                display: "flex",
                cursor: "pointer",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box>
                <Textview value="Creative" />
              </Box>
              <Box
                sx={{
                  paddingInline: "0.5rem",
                }}
              >
                <Textview value="Code" />
              </Box>
              <Box>
                <Textview value="Culture" />
              </Box>
            </Box>
          </Box>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInUp" delay={100}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              maxWidth="50rem"
              variant="body1"
              color="#484848"
              sx={{
                paddingTop: "1.5rem",
                px: { xs: 1, sm: 2, md: 0 },
              }}
            >
              Through the brewing of innovative development solutions, we create
              incredibly delightful customer experiences in the technology
              space.
            </Typography>
          </Box>
        </AnimationOnScroll>
      </Box>
      <Container sx={{maxWidth:{xs:"xs",sm:"md",md:"md",lg:"lg",xl:"xl"}}}>
      <Box
        sx={{
         
          mt: { xs: "3rem", sm: "4rem" },
        }}
      >
        <Grid
          container
          spacing={{
            xs: 3,
            sm: 2,
            md: 5,
            lg: 3,
            xl: 4,
          }}
        >
          {creativeData.map((e) => {
            return (
              <GrideView key={e.value}>
                <Creativeview value={e.value} subtitle={e.subtitle} />
              </GrideView>
            );
          })}
        </Grid>
      </Box>
      </Container>
    </Box>
  );
};

export default Creative;
function Textview({ value }) {
  return (
    <Box>
      <Box>
        <Typography
          variant="h1"
          color="#0E3347"
          textAlign="center"
          fontWeight="700"
          sx={{
            pt: "1rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {value}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              borderRadius: "100%",
              marginInline: { xs: "0.1rem", sm: "0.3rem" },
              width: { xs: "6px", sm: "8px" },
              height: { xs: "6px", sm: "8px" },
              background: "#20B69F",
              mt: { xs: 0.2, sm: 1 },
            }}
          ></Box>
        </Typography>
      </Box>
    </Box>
  );
}

function Creativeview({ value, subtitle }) {
  return (
    <Box sx={{}}>
      <Paper elevation={0}>
        <Box
          className="item"
          sx={{
            height: "150px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #20B69F",
            borderRadius: "10px",
            px: 1,
            "&:hover": {
              border: "none",
              borderRadius: "0px",
            },
          }}
        >
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <Typography
              variant="body1"
              color="#295768"
              sx={{
                fontWeight: "600",
              }}
            >
              {value}
            </Typography>
            <Typography
              variant="body2"
              color="#484848"
              sx={{
                paddingTop: "0.5rem",
              }}
            >
              {subtitle}
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
function GrideView({ children }) {
  return (
    <Grid item xs={12} sm={6} lg={4}>
      {children}
    </Grid>
  );
}
