import Ads from "../../../Component/Image/Ads.png";
import Android from "../../../Component/Image/Android.png";
import Apple1 from "../../../Component/Image/Apple1.png";
import Aws from "../../../Component/Image/Aws.png";
import Dart from "../../../Component/Image/Dart.png";
import Design from "../../../Component/Image/Design.png";
import Developmentteam from "../../../Component/Image/Developmentteam.png";
import Developmentteamover from "../../../Component/Image/Developmentteamover.png";
import Digital from "../../../Component/Image/Digital.png";
import Express from "../../../Component/Image/Express.png";
import Figma from "../../../Component/Image/Figma.png";
import Flexible from "../../../Component/Image/Flexible.png";
import Flexibleover from "../../../Component/Image/Flexibleover.png";
import Flutter from "../../../Component/Image/Flutter.png";
import Improve from "../../../Component/Image/Improve.png";
import Improveover from "../../../Component/Image/Improveover.png";
import Js from "../../../Component/Image/Js.png";
import Kubernetes from "../../../Component/Image/Kubernetes.png";
import Laravel from "../../../Component/Image/Laravel.png";
import MediumLogo from "../../../Component/Image/MediumLogo.png";
import Meta from "../../../Component/Image/Meta.png";
import MobileApp from "../../../Component/Image/MobileApp.png";
import Model from "../../../Component/Image/Model.png";
import Modelover from "../../../Component/Image/Modelover.png";
import Next from "../../../Component/Image/Next.png";
import Qe from "../../../Component/Image/Qe.png";
import React from "../../../Component/Image/React.png";
import Reddit from "../../../Component/Image/Reddit.png";
import Sketch from "../../../Component/Image/Sketch.png";
import Skill from "../../../Component/Image/Skill.png";
import Skillover from "../../../Component/Image/Skillover.png";
import Swift from "../../../Component/Image/Swift.png";
import TeamExtension from "../../../Component/Image/TeamExtension.png";
import TeamExtensionover from "../../../Component/Image/TeamExtensionover.png";
import Vs from "../../../Component/Image/VS.png";
import WebDev from "../../../Component/Image/WebDev.png";
import Xd from "../../../Component/Image/XD.png";

//BannerData
export const bannerData = [
  {
    icon: { xs: "none", md: "none" },
    id: "UIUX",
    none: { xs: "none", md: "none" },
    flex: { xs: "none", md: "flex" },
    background1: "linear-gradient(98.56deg, #FFF5EE -0.36%, #EDCDFC 100%)",
    value: "UI UX Design",
    logo1: Xd,
    logo2: Figma,
    logo3: Sketch,
    subtitle1: "Full cycle UI UX",
    subtitle2: "UX for startups",
    subtitle3: "Web & Mobile App Design",
    subtitle4: "Front End Development",
    subtitle5: "Prototyping & Interaction",

    media: Design,
    navigatorPage: "/ui-ux-design-agency",
  },
  {
    none: { xs: "none", md: "none" },

    id: "App",
    flex: { xs: "none", md: "flex" },
    background1: "linear-gradient(98.33deg, #FAFDD4 0%, #C1FBFF 100%)",
    value: "Mobile App Development",
    logo1: Flutter,
    logo2: Dart,
    logo3: Android,
    logo4: Apple1,
    logo5: Swift,
    logo6: React,
    subtitle1: "Android App Development",
    subtitle2: "IOS App Development",
    subtitle3: "Flutter App Development",
    subtitle4: "React Native App Development",
    subtitle5: "App Migration & Support",

    media: MobileApp,
    navigatorPage: "/app-development-services",
  },
  {
    icon: { xs: "none", md: "none" },
    id: "Web",
    none: { xs: "none", md: "none" },
    flex: { xs: "none", md: "flex" },
    background1: "linear-gradient(98.33deg, #E3E0FF 0%, #83DAFF 100%)",
    value: "Web Development",
    logo1: React,
    logo2: Next,
    logo3: Js,
    logo4: Express,
    logo5: Laravel,
    subtitle1: "ReactJS Development",
    subtitle2: "VueJS Development",
    subtitle3: "Angular Development",
    subtitle4: "Node.js Development",
    subtitle5: "JavaScript Development",

    media: WebDev,
    navigatorPage: "/web-development-services",
  },
];

//Computing & Marketing data

export const marketingData = [
  {
    mr: { xs: 0, sm: "0rem", md: "1rem", lg: "1rem", xl: "1rem" },
    icon: { xs: "none", md: "none" },
    id: "marketing",
    height: { xs: "100%", md: "460px" },
    flex: { xs: "none", md: "none" },
    background1: "linear-gradient(98.33deg, #F1FFE0 0%, #EACEA5 100%)",
    value: "Cloud Computing",
    logo1: Aws,
    logo2: Vs,
    logo3: Kubernetes,
    logo4: Digital,
    subtitle1: "Cloud App Development",
    subtitle2: "Cloud Configuration",
    subtitle3: "Cloud Integration",
    subtitle4: "Cloud Security",
    subtitle5: "Cloud Server Solutions",
    subtitle6: "Cloud Migration",

    navigatorPage: "/cloud-computing-services",
  },
  {
    ml: { xs: 0, sm: "0rem", md: "1rem", lg: "1rem", xl: "1rem" },
    icon: { xs: "none", md: "none" },
    height: { xs: "100%", md: "460px" },
    flex: { xs: "none", md: "none" },
    background1:
      "linear-gradient(98.33deg, #E3E0FF 0%, #FFE0E0 0.01%, #DABCFF 99.99%)",
    value: "Digital Marketing",
    logo1: Meta,
    logo2: Ads,
    logo3: MediumLogo,
    logo4: Qe,
    logo5: Reddit,
    subtitle1: "Search Engine Optimization (SEO)",
    subtitle2: "Social Media Marketing (SMM)",
    subtitle3: "Content Writing",
    subtitle4: "Content Marketing",
    subtitle5: "Pay Per Click (PPC/CPC) Ads",
    subtitle6: "App Store Optimization (ASO)",

    navigatorPage: "/digital-marketing-agency",
  },
];

// Hire DedicatedData

export const dedicatedData = [
  {
    value: "UI UX Designer",
    subtitle: `"Hire a dedicated UI/UX designer to have their undivided attention and expertise focused solely on improving the design and user experience of your product.
    "`,
  },
  {
    value: "Application Developer",
    subtitle: `"Hire a dedicated app developer to tailor your app development to your specific needs and requirements, ensuring that your vision is brought to life."`,
  },
  {
    value: "Web Developer",
    subtitle: `"Hire our web app developers who build responsive and progressive web apps and mobile-first web apps that are scalable, robust and secure.
    "`,
  },
  {
    value: "Digital Marketing ",
    subtitle: `"A dedicated person will cater all your needs related to digital marketing and will help you bring finite results."`,
  },
];

//TeamData

export const teamData = [
  {
    media: Developmentteam,
    value: "Development team",

    //Overview Hover data
    hovercolor: "#34004E",
    bgcolor: "#F6E4FF",
    image: Developmentteamover,
    subtitle: "Development team",
  },
  {
    media: Model,
    value: "Project-based Model",

    //Overview Hover data
    hovercolor: "#003534",
    bgcolor: " #C7FBFA",
    image: Modelover,
    subtitle: "Project-based Model",
  },
  {
    media: Flexible,
    value: "Flexible Hiring Models",

    //Overview Hover data

    hovercolor: "#3D0014",
    bgcolor: "#FFE4ED",
    image: Flexibleover,
    subtitle: "Flexible Hiring Models",
  },

  {
    media: TeamExtension,
    value: "Team Extension",

    //Overview Hover data
    hovercolor: "#371D00",
    bgcolor: " #FFF4E8",
    image: TeamExtensionover,
    subtitle: "Team Extension",
  },

  {
    media: Skill,
    value: "Multiple skill set",

    //Overview Hover data
    hovercolor: "#002234",
    bgcolor: "#C5EBFF",
    image: Skillover,
    subtitle: "Multiple skill set",
  },
  {
    media: Improve,
    value: "Improve productivity",

    //Overview Hover data
    hovercolor: "#222700",
    bgcolor: "#FAFFDA",
    image: Improveover,
    subtitle: "Improve productivity",
  },
];

//What we offerData

export const OfferData = [
  {
    value: "UI UX Design",
    subtitle: "We create splendid and user-friendly customer experiences.",

    panel: "panel1",
  },
  {
    value: "UX Analysis",
    subtitle:
      "We design user experiences that eventually convert users into committed customers.",

    panel: "panel2",
  },
  {
    value: "WEB Development",
    subtitle:
      "For all kinds of businesses, we provide easy-to-scale custom web application development.",

    panel: "panel3",
  },
  {
    value: "APP Development",
    subtitle:
      "Our expertise in developing mobile apps has been demonstrated, ensuring value-added services for your mobile operations.",

    panel: "panel4",
  },
  {
    value: "Hybrid APP Development",
    subtitle:
      "The cutting-edge technologies we employ to create business hybrid applications enable you to achieve a laser-focus on organizational objectives.",

    panel: "panel5",
  },
  {
    value: "SEO And Digital Marketing",
    subtitle:
      "We build a strong digital presence of your business through various digital channels.",

    panel: "panel6",
  },
];
