import { Box } from "@mui/material";
import React from "react";
import Ourwork from "../../CommonSection/Ourwork";
import Work from "../../../Image/Work.png";

const OurWorkApp = () => {
  return (
    <Box>
    <Ourwork media={Work} i="row-reverse" ex={{pl:{xs:"0",md:4,xl:6}}}/>
    </Box>
  );
};

export default OurWorkApp;

