import { Box } from "@mui/material";
import React, { useEffect } from "react";
import Blog from "../../Home/Screen/Blog";
import Contact from "../../Service/ServiceScreen/Contact";
import PortfolioScreen from "../OurPortfolioScreen/PortfolioScreen";
import Ourpalette from "../OurPortfolioScreen/Ourpalette";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../Firebase";

const Ourportfolio = () => {
  useEffect(() => {
    logEvent(analytics, "page_view", {
      page_location: window.location.href + window.location.search,
      page_path: window.location.href + window.location.search,
      page_title: "Our Portfolio Page",
    });
  }, []);
  useEffect(() => window.document.scrollingElement?.scrollTo(0, 0), []);
  return (
    <Box>
      <PortfolioScreen />
      <Ourpalette />
      <Blog />
      <Contact />
    </Box>
  );
};

export default Ourportfolio;
