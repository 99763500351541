import { Box } from "@mui/material";
import React, { useEffect } from "react";
import Careerscreen from "../CareerScreen/Careerscreen";
import Interviewprocess from "../CareerScreen/Interviewprocess";
import Lookingfor from "../CareerScreen/Lookingfor";
import Positions from "../CareerScreen/Positions";
import Expect from "../CareerScreen/Expect";
import Whyjoin from "../CareerScreen/Whyjoin";
import Photos from "../../About/AbountScreen/Photos";
import CareerForm from "../CareerScreen/CareerForm";

const Career = () => {
  useEffect(() => window.document.scrollingElement?.scrollTo(0, 0), []);
  return (
    <Box>
      <Careerscreen />
      <Positions />
      <Interviewprocess />
      <Lookingfor />
      <Expect />
      <Whyjoin />
      <CareerForm />
      <Photos />
    </Box>
  );
};

export default Career;
