import { Box } from "@mui/material";
import React from "react";
import HeaderChild from "../../CommonSection/HeaderChild";

const AIDevelopment = () => {
  return (
    <Box>
      <HeaderChild
        none={"none"}
        value={"AI Development"}
        subtitle={"WHAT WE DO"}
        title={
          "Crafting Custom AI Solutions for Your Business Growth"
        }
        title1={
          "Unlock the potential of AI with our tailored solutions. We develop AI applications that drive efficiency, automation, and growth."
        }
      />
    </Box>
  );
};

export default AIDevelopment;
