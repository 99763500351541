import { API_URL, API_POST } from "./config";

export const postSubscribe = (inputs) => {
  const f = new FormData();

  f.append("email", inputs);

  const data = API_POST(API_URL.createSubscribe, f);
  return data;
};

export const createConntact = (inputs) => {
  const f = new FormData();
  f.append("name", inputs.name);
  f.append("email", inputs.email);
  f.append("mobile_no", inputs.number);
  f.append("country", inputs.country);
  f.append("company_name", inputs.company);
  f.append("message", inputs.message);
  f.append("media", inputs.media);

  const data = API_POST(API_URL.createContact, f);
  return data;
};
