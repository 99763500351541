import React from "react";
import { Box } from "@mui/material";
import Creative from "../../../Image/Creative.png";
import Figma1 from "../../../Image/Figma1.png";
import Sketch1 from "../../../Image/Sketch1.png";
import Toolkit from "../../../Image/Toolkit.png";
import Protopie from "../../../Image/Protopie.png";
import Axure from "../../../Image/Axure.png";
import XD from "../../../Image/XD.png";
import ChildBanner from "../../CommonSection/ChildBanner";

const CreativeUi = () => {
  return (
    <Box>
      <ChildBanner
        value={"UI UX Design "}
        title={"Hire a Dedicated UI UX Designer"}
        subtitle={
          "We can help you achieve a dynamic and engaging design for your business solutions by providing a dedicated UI/UX designer. Our team has the skills to deliver high-quality app and website designs, and our designers have experience across various industries."
        }
        subtitle1={
          "Consider hiring a UI UX developer if you want to create unique company solutions with the best design service. A well-designed website can attract visitors, leading to increased sales and revenue for your business. Boost your company's profits with high-quality design solutions by hiring a UX UI designer from Equitysoft. "
        }
        media={Figma1}
        media1={XD}
        media2={Sketch1}
        media3={Toolkit}
        media4={Protopie}
        media5={Axure}
        none3={"none"}
        image={Creative}
        ex={{pr:{xs:"0",md:4,xl:6}}}
        i={"row"}
        aos={"fade-down-left"}
        
      />
    </Box>
  );
};

export default CreativeUi;

