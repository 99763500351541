import React, {
  useEffect,
  useState,
} from 'react';

import { AnimationOnScroll } from 'react-animation-on-scroll';

import {
  Box,
  Container,
  Typography,
} from '@mui/material';

import { getAllServices } from '../../../api/Services';
import {
  bannerData,
  marketingData,
} from '../../../utils/Constent/Service/ServiceConstent';
import rightlogo from '../../Image/ArrowRight.svg';

const Banner = () => {
  const [ServicesList, setServicesList] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const data = await getAllServices();
      if (data?.s) setServicesList(data?.r);
    };
    getData();
  }, []);

  return (
    <Container
      sx={{ maxWidth: { xs: "xs", sm: "md", md: "md", lg: "lg", xl: "xl" } }}
    >
      <Box
        sx={{
          marginTop: { xs: "3rem", sm: "3rem" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: { xs: "100%", md: "100%" } }}>
            {bannerData.map((v, n) => {
              return (
                <Box key={n}>
                  <Bannerview {...v} />
                </Box>
              );
            })}
          </Box>
        </Box>

        {/* Computing & Marketing data */}

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          {marketingData.map((v, i) => {
            return (
              <Box
                key={i}
                sx={{
                  width: { xs: "100%", lg: "100%", xl: "100%" },
                }}
              >
                <Bannerview {...v} />
              </Box>
            );
          })}
        </Box>
      </Box>
    </Container>
  );
};

export default Banner;
function Bannerview({
  value,
  mr,
  ml,
  logo1,
  logo2,
  logo3,
  logo4,
  logo5,
  logo6,
  subtitle1,
  subtitle2,
  subtitle3,
  subtitle4,
  subtitle5,
  subtitle6,
  url,
  media,
  background1,
  flex,
  height,
  none,
  id,
  row,
  icon,
  rem,
  navigatorPage,
}) {
  const navigator = () =>{
    window.open(url)
  }
  return (
    <Box
      id={id}
      sx={{
        mr: mr,
        ml: ml,
        background: background1,
        display: "flex",
        justifyContent: "space-between",
        marginTop: { xs: "1rem", md: "2rem" },
        borderRadius: "35px",
        flexDirection: { xs: "column", md: "row" },
        height: height,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          px: { xs: 1.5, sm: 5 },
          py: { xs: 4, md: 4, lg: 0 },
        }}
      >
        <Box>
          <Box>
            <AnimationOnScroll animateIn="animate__flipInY">
              <Typography
                variant="h4"
                sx={{textTransform:"capitalize"}}
                color="#252525"
                fontWeight="700"
                data-aos="fade-down"
              >
                {value}
              </Typography>
            </AnimationOnScroll>
          </Box>
          <Box
            sx={{
              my: "1rem",
              display: "flex",
              flexDirection: row,
            }}
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Box sx={{ width: { xs: "35px", sm: "40px" } }}>
                  <img src={logo1} alt="" width="100%" />
                </Box>
                <Box
                  sx={{
                    marginInline: { xs: "0.5rem", sm: "1rem" },
                    width: { xs: "35px", sm: "40px" },
                  }}
                >
                  <img src={logo2} alt="" width="100%" />
                </Box>
                <Box
                  sx={{
                    pr: { xs: "0.5rem", sm: "1rem" },
                    width: { xs: "41px", sm: "55px" },
                  }}
                >
                  <img src={logo3} alt="" width="100%" />
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                marginTop: rem,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Box
                  sx={{
                    pr: { xs: "0.5rem", sm: "1rem" },
                    width: { xs: "41px", sm: "55px" },
                  }}
                >
                  <img src={logo4} alt="" width="100%" />
                </Box>
                <Box
                  sx={{
                    pr: { xs: "0.5rem", sm: "1rem" },
                    width: { xs: "40px", sm: "55px" },
                  }}
                >
                  <img src={logo5} alt="" width="100%" />
                </Box>
                <Box sx={{ display: icon, width: { xs: "35px", sm: "40px" } }}>
                  <img src={logo6} alt="" width="100%" />
                </Box>
              </Box>
            </Box>
          </Box>

          <Box component="ul" sx={{ px: 2 }}>
            <Typography
              variant="body1"
              color="#484848"
              fontWeight="700"
              component="li"
              sx={{
                paddingBottom: "0.5rem",
                textTransform:"capitalize"
              }}
            >
              {subtitle1}
            </Typography>
            <Typography
              variant="body1"
              color="#484848"
              fontWeight="700"
              component="li"
              sx={{
                paddingBottom: "0.5rem",
                textTransform:"capitalize"
              }}
            >
              {subtitle2}
            </Typography>
            <Typography
              variant="body1"
              color="#484848"
              fontWeight="700"
              component="li"
              sx={{
                paddingBottom: "0.5rem",
                textTransform:"capitalize"
              }}
            >
              {subtitle3}
            </Typography>
            <Typography
              variant="body1"
              color="#484848"
              fontWeight="700"
              component="li"
              sx={{
                paddingBottom: "0.5rem",
                textTransform:"capitalize"
              }}
            >
              {subtitle4}
            </Typography>
            <Typography
              variant="body1"
              color="#484848"
              fontWeight="700"
              component="li"
              sx={{
                paddingBottom: "0.5rem",
                textTransform:"capitalize"
              }}
            >
              {subtitle5}
            </Typography>
            <Typography
              sx={{
                display: none,
                textTransform:"capitalize"
              }}
              variant="body1"
              color="#484848"
              fontWeight="700"
              component="li"
            >
              {subtitle6}
            </Typography>
          </Box>

          <Box
            sx={{
              paddingTop: "1rem",
            }}
          >
            <img
              src={rightlogo}
              alt=""
              width="50px"
              style={{ cursor: "pointer" }}
              onClick={() => navigator( url =(navigatorPage))}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", md: "100%", lg: "600px" },
            display: flex,
          }}
        >
          <img
            src={media}
            alt=""
            width="100%"
            height="100%"
            style={{ borderRadius: "35px" }}
          />
        </Box>
      </Box>
    </Box>
  );
}
