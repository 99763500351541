import React from "react";

import { Box, Button, Container, Typography } from "@mui/material";

import Hbo from "../../../Image/Hbo.png";
import Netflix from "../../../Image/Netflix.png";
import Samsung from "../../../Image/Samsung.png";
import Uiux from "../../../Image/UiuxDesign.png";

const UiuxDesign = () => {
  return (
    <Container
      sx={{ maxWidth: { xs: "xs", sm: "md", md: "md", lg: "lg", xl: "xl" } }}
    >
      <Box
        sx={{
          marginTop: { xs: "8rem", sm: "8rem", lg: "8rem" },
          overflow: "hidden",
        }}
      >
        <Box>
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <Typography variant="h3" color="#295768" fontWeight="700">
              UI UX Design{" "}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "space-between" },
              alignItems: "center",
              mt: { xs: 3, md: "4rem" },
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Box>
              <Uiuxview />
            </Box>
            <Box
              data-aos="fade-down-left"
              data-aos-duration="2000"
              data-aos-offset="100"
            >
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    overflow: "hidden",
                  }}
                >
                  <img src={Uiux} alt="" width="100%" />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default UiuxDesign;
function Cradview({ media }) {
  return (
    <Box
      sx={{
        width: {
          xs: "100%",
          sm: "100%",
          md: "120px",
          lg: "180px",
          xl: "220px",
        },
      }}
    >
      <img src={media} alt="" width="100%" />
    </Box>
  );
}

function Uiuxview({ url }) {
  const navigator = () => {
    window.open(url);
  };
  return (
    <Box>
      <Box>
        <Box>
          <Typography
            variant="h5"
            color="#0E3347"
            fontWeight="700"
            sx={{
              pb: { xs: 3, md: 4 },
              textAlign: { xs: "center", md: "start" },
            }}
          >
            WHAT WE DO
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "start" },
            }}
          >
            <Typography
              variant="h1"
              color="#252525"
              fontWeight="400"
              sx={{
                width: { xs: "100%", sm: "100%", md: "80%" },
                textAlign: { xs: "center", md: "start" },
                pb: { xs: 3, md: 4 },
                lineHeight: 1.2,
              }}
            >
              We create visually appealing & user-centric solutions.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "start" },
          }}
        >
          <Typography
            variant="body2"
            color="#484848"
            maxWidth="50rem"
            fontWeight="400"
            sx={{
              pr: { xs: "0", md: 4, xl: 4 },
              pb: { xs: 3, md: 4 },
              textAlign: { xs: "center", md: "start" },
            }}
          >
            At Equitysoft, our designers produce designs to improve user
            engagement and increase user satisfaction, ultimately leading to
            better business results.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "start" },

            pb: { xs: 3, md: 4 },
          }}
        >
          <Button
            onClick={() => navigator((url = "/portfolio"))}
            sx={{
              color: "#fff",
              backgroundColor: "#0E3347",
              borderRadius: "50px",
              textTransform: "none",
              border: "2px solid #0E3347  ",
              fontWeight: "700",
              fontSize: { xs: "12px", sm: "15px" },
              px: { xs: 4, sm: 4.5 },

              "&:hover": {
                border: "2px solid #0E3347",

                color: "#295768",
              },
            }}
          >
            Portfolio
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            pb: { xs: 3, md: 0 },
            // justifyContent: "center",
          }}
        >
          <Cradview media={Netflix} />
          <Cradview media={Hbo} />
          <Cradview media={Samsung} />
        </Box>
      </Box>
    </Box>
  );
}
