

import { Box} from "@mui/material";
import MySQL from "../../../Image/MySQL.png";

import ChildBanner from "../../CommonSection/ChildBanner";

const Mysql = () => {
  return (
    <Box>
      <ChildBanner
        value={"Database Management"}
        title={"MySQL "}
        subtitle={
          "Harness the power of MySQL, a trusted relational database management system. Our MySQL solutions offer data consistency, reliability, and high performance, ensuring your applications run smoothly."
        }
        subtitle1={
          "We leverage MySQL's capabilities to create databases that meet your specific needs, helping you drive innovation and achieve your business objectives."
        }
        image={MySQL}
       
        none1={"none"}
        ex={{pr:{xs:"0",md:4,xl:6}}}
        aos={"fade-down-left"}
        i={"row"}
      />
    </Box>
  )
}

export default Mysql

