import React from "react";
import { Box, Button, Link } from "@mui/material";
import HeaderChild from "../../CommonSection/HeaderChild";

const Hiredeveloper = () => {
  return (
    <Box>
      <HeaderChild
        value={"Hire Dedicated developer"}
        subtitle={"List of Services "}
        title={
          "Transform your services with the expertise of a committed developer."
        }
        title1={
          "Hire developers on a full-time or part-time basis. Our dedicated developers will fully integrate into your team and work closely with you to achieve your goals."
        }
        value1={"UI UX Designer"}
        value2={"Application developer "}
        value3={"Web developer"}
        value4={"Android developer "}
        none2={"none"}
        
      />
      <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 1,
          }}
        >
          <Button
          
            
            sx={{
              
              color: "#fff",
              backgroundColor: "#0E3347",
              borderRadius: "50px",
              textTransform: "none",
              border: "2px solid #0E3347  ",
              fontWeight: "700",
              fontSize: { xs: "12px", sm: "15px" },
              px: { xs: 4, sm: 4.5 },

              "&:hover": {
                border: "2px solid #0E3347",
                backgroundColor: "#0E3347",
                color: "#295768",
              },
            }}
          >
            <Link href="skype:live:.cid.24dd4a6e3e1d2c33?chat" target={"_blank"} sx={{textDecoration:"none"}}>Contact Us</Link>
          </Button>
        </Box>
    </Box>
  );
};

export default Hiredeveloper;
