import { Box } from '@mui/material'
import React from 'react'
import Logo2 from "../Image/Logo2.png"

const Loader = () => {
  return (
    <Box className="page-loader" sx={{background:"#fff"}}>
    <div className="spinner">
      <img src={Logo2} alt="" width="100px" />
    </div>
    </Box>
  )
}

export default Loader