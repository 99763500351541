import React, { useEffect } from 'react';

import { Box } from '@mui/system';

import Contact from '../../Service/ServiceScreen/Contact';
import YourProject from '../Screen/AppPage/YourProject';
import CloudComputing from '../Screen/CloudComputingPage/CloudComputing';
import Cloudnative from '../Screen/CloudComputingPage/Cloudnative';
import NativeApp from '../Screen/CloudComputingPage/NativeApp';
import OurWorkCloud from '../Screen/CloudComputingPage/OurWorkCloud';
import Scalable from '../Screen/CloudComputingPage/Scalable';

const CloudComputingPage = () => {
  useEffect(() => window.document.scrollingElement?.scrollTo(0, 0), [])
  return (
   <Box>
   <CloudComputing/>
   <YourProject/>
   <Cloudnative/>
   <NativeApp/>
   <Scalable/>
   <OurWorkCloud/>
   <Contact/>
   </Box>
  )
}

export default CloudComputingPage