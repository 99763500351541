import React from 'react';

import { Box } from '@mui/material';

import Typographyview from '../../CommonSectionweb/Typographyview';

const Longway = () => {
  return (
    <Box id="Ourjourney" sx={{ marginTop: { xs: "4rem", md: "5rem" }, }}>

      <Box sx={{mb:{xs:0,sm:"19.5rem"}}}>
        <Typographyview value=" We've come a long way" none="none"/>
      </Box>
    </Box>
  )
}

export default Longway