import App1 from "../../../Component/Image/App-1.png";
import App from "../../../Component/Image/App.png";
import AppDeveloper from "../../../Component/Image/AppDeveloper.png";
import BackgroundApp from "../../../Component/Image/Background-App.png";
import BackgroundWeb from "../../../Component/Image/Background-Web.png";
import Background from "../../../Component/Image/Background.png";
import BDE from "../../../Component/Image/BDE.png";
import Behance from "../../../Component/Image/Behance.png";
import Casestudy from "../../../Component/Image/Casestudy.svg";
import checklists from "../../../Component/Image/checklists.png";
import checklists1 from "../../../Component/Image/checklists1.png";
import Cloud from "../../../Component/Image/Cloud.svg";
import Clutch from "../../../Component/Image/Clutch.png";
import code from "../../../Component/Image/code.png";
import code1 from "../../../Component/Image/code1.png";
import coding from "../../../Component/Image/coding.png";
import coding1 from "../../../Component/Image/coding1.png";
import companybg from "../../../Component/Image/companybg.png";
import computer from "../../../Component/Image/computer.png";
import computer1 from "../../../Component/Image/computer1.png";
import Dribbble from "../../../Component/Image/Dribbble.png";
import FlutterDeveloper from "../../../Component/Image/FlutterDeveloper.png";
import GymLogo from "../../../Component/Image/Gym-Logo.png";
import Interview from "../../../Component/Image/Interview.svg";
import lookingfor from "../../../Component/Image/lookingfor.svg";
import Medium from "../../../Component/Image/Medium.png";
import ontime from "../../../Component/Image/ontime.png";
import ontime1 from "../../../Component/Image/ontime1.png";
import OpenPosition from "../../../Component/Image/OpenPosition.svg";
import Ourachievement from "../../../Component/Image/Ourachievement.svg";
import Ourjourney from "../../../Component/Image/Ourjourney.svg";
import Ourwork from "../../../Component/Image/Ourwork.svg";
import prototyping from "../../../Component/Image/prototyping.png";
import prototyping1 from "../../../Component/Image/prototyping1.png";
import servicesbg from "../../../Component/Image/servicesbg.png";
import solutionbg from "../../../Component/Image/solutionbg.png";
import support from "../../../Component/Image/support.png";
import support1 from "../../../Component/Image/support1.png";
import technologybg from "../../../Component/Image/technologybg.png";
import Testimonial from "../../../Component/Image/Testimonial.svg";
import TimerLogo from "../../../Component/Image/Timer-Logo.png";
import ui from "../../../Component/Image/ui.png";
import ui1 from "../../../Component/Image/ui1.png";
import uiux1 from "../../../Component/Image/UIUX-1.png";
import uiux from "../../../Component/Image/UIUX.png";
import UIUXDesigner from "../../../Component/Image/UIUXDesigner.png";
import Web1 from "../../../Component/Image/Web-1.png";
import Web from "../../../Component/Image/Web.png";
import Webdeveloper from "../../../Component/Image/Webdeveloper.png";
import Welcome from "../../../Component/Image/Welcome.svg";
import whatyouexpect from "../../../Component/Image/whatyouexpect.svg";
import WhoWeare from "../../../Component/Image/WhoWeare.svg";
import WhyEquitysoft from "../../../Component/Image/WhyEquitysoft.svg";
import Whyjoinequitysoft from "../../../Component/Image/Whyjoinequitysoft.svg";
import Wishlogo from "../../../Component/Image/Wish-logo.png";
import ISOLOGO from "../../../Component/Image/iso.png";
import GLASSDOOR from "../../../Component/Image/glassDoor.png";
import GOOGLE_ACCOUNT from "../../../Component/Image/google-review.png";
import AMBITION_BOX from "../../../Component/Image/ambitbox.png";
//whatsapp Data

//Navbar Data
export const NavbarData = [
  {
    value: "Home",
    navigate: "/",
    none: "none",
    url1: "home",
  },

  {
    value: "Service",
    navigate: "service",
    image: technologybg,
    url1: "/service",
    backgroundSize: "100%",

    data: [
      {
        icon: AppDeveloper,
        subtitle: "Mobile App Development ",
        navigate: "/app-development-services",
      },
      {
        icon: Webdeveloper,
        subtitle: "Web development ",
        navigate: "/web-development-services",
      },
      {
        icon: UIUXDesigner,
        subtitle: "UI UX Design",
        navigate: "/ui-ux-design-agency",
      },

      {
        icon: Casestudy,
        subtitle: "AI Development",
        navigate: "/ai-development-services",
      },

      {
        icon: Ourwork,
        subtitle: "Database Management",
        navigate: "database-management-services",
      },

      {
        icon: Cloud,
        subtitle: "Cloud Computing",
        navigate: "/cloud-computing-services",
      },
      {
        icon: FlutterDeveloper,
        subtitle: "Digital Marketing",
        navigate: "/digital-marketing-agency",
      },
      {
        icon: BDE,
        subtitle: "Hire Dedicated Developers",
        navigate: "/hire-software-developers",
      },
    ],
  },
  {
    value: "Our Portfolio",
    image: servicesbg,
    navigate: "portfolio",
    url1: "portfolio",
    backgroundSize: "80%",

    data: [
      {
        icon: Ourwork,
        subtitle: "Our Work",
        navigate: "/portfolio",
        href: "#Ourpalette",
      },
      { icon: Casestudy, subtitle: "Case Study ", navigate: "/case-study" },
    ],
  },
  {
    value: "Career",
    navigate: "career",
    image: solutionbg,
    url1: "career",
    backgroundSize: "60%",

    data: [
      { icon: OpenPosition, subtitle: "Open Positions", href: "#Positions" },
      {
        icon: Interview,
        subtitle: "Interview Process",
        href: "#Interviewprocess",
      },
      {
        icon: lookingfor,
        subtitle: "Who We Are Looking For?",
        href: "#Lookingfor",
      },
      {
        icon: whatyouexpect,
        subtitle: "What You Expect",
        href: "#WhatYouCanExpect@Equitysoft",
      },
      {
        icon: Whyjoinequitysoft,
        subtitle: "Why Join Equitysoft",
        href: "#WhyJoinEquitysoft",
      },
    ],
  },
  {
    value: "Blog",
    navigate: "blog",
    none: "none",
    url1: "blog",
  },
  {
    value: "About",
    navigate: "about",
    image: companybg,
    url1: "/about",
    backgroundSize: "60%",
    data: [
      { icon: Welcome, subtitle: "Welcome", href: "#Welcome" },
      { icon: Ourachievement, subtitle: "Who We Are", href: "#Who" },
      { icon: WhyEquitysoft, subtitle: "Our Journey", href: "#Ourjourney" },
      { icon: Ourjourney, subtitle: "Why Equitysoft", href: "#WhyChoose" },
      {
        icon: Testimonial,
        subtitle: "Our Testimonials",
        href: "#Testimonials",
      },
      { icon: WhoWeare, subtitle: "Our Achievement", href: "#Achievement" },
    ],
  },
];
//OurServicE Data

export const NavbarDropData = [
  {
    value: "Home",
    none: "none",
    navigate: "/",
  },

  {
    value: "Service",
    none1: "none",
    navigate: "service",
    panel: "panel2",
    data: [
      { subtitle: "UI UX Design", navigat: "/ui-ux-design-agency" },
      {
        subtitle: "Mobile App Development ",
        navigat: "/app-development-services",
      },
      {
        subtitle: "Web development ",
        navigat: "/web-development-services",
      },
      { subtitle: "Cloud Computing", navigat: "/cloud-computing-services" },
      {
        subtitle: "Digital Marketing",
        navigat: "/digital-marketing-agency",
      },
      {
        subtitle: "Hire Dedicated Developers",
        navigat: "/hire-software-developers",
      },
    ],
  },
  {
    value: "Our portfolio",
    none1: "none",
    navigate: "portfolio",
    panel: "panel3",
    data: [
      { subtitle: "Our Work", navigate: "/portfolio", href: "#Ourpalette" },
      { subtitle: "Case Study ", navigat: "/case-study" },
    ],
  },
  {
    value: "Career",
    none1: "none",
    navigate: "career",
    panel: "panel4",
    data: [
      { subtitle: "Open Positions", href: "#Positions" },
      { subtitle: "Interview Process", href: "#Interviewprocess" },
      { subtitle: "Who We Are Looking For?", href: "#Lookingfor" },
      { subtitle: "What You Expect", href: "#WhatYouCanExpect@Equitysoft" },
      { subtitle: "Why Join Equitysoft", href: "#WhyJoinEquitysoft" },
    ],
  },
  {
    value: "Blog",
    none: "none",
    navigate: "blog",
  },
  {
    value: "About",
    none1: "none",
    navigate: "about",
    panel: "panel1",
    data: [
      { subtitle: "Welcome", href: "#Welcome" },
      { subtitle: "Who We are", href: "#Who" },
      { subtitle: "Our Journey", href: "#Ourjourney" },
      { subtitle: "Why Equitysoft", href: "#WhyChoose" },
      { subtitle: "Our Testimonials", href: "#Testimonials" },
      { subtitle: "Our ACHIEVEMENT", href: "#Achievement" },
    ],
  },
];

export const ourserviceData = [
  {
    image: App,
    value: "App Development",
    navigation: "/app-development-services",
    // subtitle:
    //   "To meet demands for mobile apps, about any industry, we develop every kind of mobile application.",
    subtitle:
      "We are your trusted app development agency, dedicated to turning your mobile ideas into intuitive experiences.",

    //hover Data
    img: App1,
    media: BackgroundApp,
  },
  {
    image: Web,
    navigation: "/web-development-services",
    value: "Web Development ",
    // subtitle:
    //   "Our experts can build, host, and maintain any type of business website you require.",
    subtitle:
      "We master in creating web experiences by developing websites that resonate with your audience and propel your business forward.",

    //hover Data
    img: Web1,
    media: BackgroundWeb,
  },
  {
    image: uiux1,
    value: "UI UX Development",
    navigation: "/ui-ux-design-agency",
    // subtitle:
    //   "From research to wireframing, prototyping, and visual designing, we offer end-to-end UX/UI services.",
    subtitle:
      "At Equity Soft, we specialize in UI/UX development to propel your digital transformation journey. ",

    //hover Data
    img: uiux,
    media: Background,
  },
];

//Portfolio Data

export const portfolioData = [
  {
    value: "Ability reach",
  },
  {
    value: "Dreamy",
  },
  {
    value: "Anime",
  },
  {
    value: "Wish it",
  },
  {
    value: "Lmd",
  },
  {
    value: "Pcg",
  },
];

//OurClients

export const ourclientData = [
  {
    image: Wishlogo,
  },
  {
    image: TimerLogo,
  },
  {
    image: GymLogo,
  },
  {
    image: Wishlogo,
  },
  {
    image: TimerLogo,
  },
  {
    image: GymLogo,
  },
  {
    image: Wishlogo,
  },
  {
    image: TimerLogo,
  },
];

//WHY Choose to Equitysoft?

export const equitysoftData = [
  {
    image: checklists,
    title: "Requirement",
    subtitle: "Gathering",
    onHover: checklists1,
  },
  {
    image: prototyping,
    title: "Wireframing",
    subtitle: "Prototyping",
    onHover: prototyping1,
  },
  {
    image: ui,
    title: "UI UX Design",
    subtitle: "Strategy",
    onHover: ui1,
  },
  {
    image: support,
    title: "Effective",
    subtitle: "Collaboration",
    onHover: support1,
  },
  {
    image: code,
    title: "Quality and Automated",
    subtitle: "code",
    onHover: code1,
  },
  {
    image: coding,
    title: "Continuous",
    subtitle: "deployment",
    onHover: coding1,
  },
  {
    image: computer,
    title: "Continuous",
    subtitle: "integrated",
    onHover: computer1,
  },
  {
    image: ontime,
    title: "On time Delivery",
    onHover: ontime1,
  },
];

// ACHIEVEMENT

export const achievementData = [
  {
    image: Behance,
    value: "BEHANCE",
    href: "https://www.behance.net/equitysoft",
    subtitle: "Top creator in year of 2021",
  },
  {
    image: Dribbble,
    value: "DRIBBBLE",
    href: "https://dribbble.com/equitysoft",
    subtitle: "Top creator in year of 2021",
  },
  {
    image: Medium,
    value: "MEDIUM",
    href: "https://medium.com/@equitysoft",
    subtitle: "Top creator in year of 2021",
  },
  {
    // image: ISOLOGO,
    image: "/ios.png",
    value: "ISO",
    href: "https://docs.google.com/document/u/0/d/e/2PACX-1vTIgI65Xft_hxnwKIIEom5KPsPV1MLdoPZ2Znf66Q6GTmG83fYjisWjI8AwwyIR887CjmRwcpW8WZwj/pub?pli=1",
    // subtitle: "Top creator in year of 2021",
  },
  {
    // image: ISOLOGO,
    image: GLASSDOOR,
    value: "GLASSDOOR",
    href: "https://www.glassdoor.co.in/Overview/Working-at-Equitysoft-Technologies-EI_IE2217585.11,34.htm",
    // subtitle: "Top creator in year of 2021",
    rating: "5.0",
  },
  {
    // image: ISOLOGO,
    image: GOOGLE_ACCOUNT,
    value: "GOOGLE",
    href: "https://www.google.com/maps/place/Equitysoft+Technologies+Pvt.+Ltd./@23.1140372,72.540843,15z/data=!4m6!3m5!1s0x395e83174e8dff25:0x9986b25f59688893!8m2!3d23.1140372!4d72.540843!16s%2Fg%2F11c8450_tp?en",
    // subtitle: "Top creator in year of 2021",
    rating: "4.9",
  },
  {
    // image: ISOLOGO,
    image: AMBITION_BOX,
    value: "AMBITIONBOX",
    href: "https://www.ambitionbox.com/reviews/equitysoft-technologies-reviews",
    // subtitle: "Top creator in year of 2021",
    rating: "5.0",
  },
  {
    image: Clutch,

    href: "  https://clutch.co/profile/equitysoft-technologies?",
    value: "CLUTCH",
    subtitle: "Top creator in year of 2021",
    rating: "5.0",
  },
];

// Footer Data

export const LastData = [
  {
    value: "Equitysoft Technologies",
  },
  {
    value: "All Rights Reserved.",
  },
];
