import { Box } from "@mui/material";
import React from "react";
import Ourwork from "../../CommonSection/Ourwork";
import aiourwork from "../../../Image/aiourwork.png";

const OurWorkAi = () => {
  return (
    <Box>
    <Ourwork media={aiourwork} i="row-reverse" ex={{pl:{xs:"0",md:4,xl:6}}}/>
    </Box>
  );
};

export default OurWorkAi;

