import { Box } from '@mui/material'
import React from 'react'
import Contact from '../Service/ServiceScreen/Contact'
import { useEffect } from 'react'

export const Quote = () => {
  useEffect(() => window.document.scrollingElement?.scrollTo(0, 0), [])

  return (
    <Box sx={{ background: "#0E3347", pb: 4, pt: 8 }}>
      <Contact />
      {/* <a
        href="https://salesiq.zohopublic.in/signaturesupport.ls?widgetcode=siq7ab02fc2b9962730cc58ab0731cbba66e970367ebce97003feb503f09a8f1b72&e=siq4f1d32163cb9d10f6c6f63cf6e897b0263c7a84112037bf1742ee89b2f04fbeb"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src="https://salesiq.zohopublic.in/visitor/v2/channels/emailsignature/sticker?widgetcode=siq7ab02fc2b9962730cc58ab0731cbba66e970367ebce97003feb503f09a8f1b72&enc_agent_mail=siq4f1d32163cb9d10f6c6f63cf6e897b0263c7a84112037bf1742ee89b2f04fbeb" />
      </a> */}
    </Box>
  );
}
