import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import Contact from '../../Service/ServiceScreen/Contact'
import OurProcess from '../Screen/UIUXPage/OurProcess'
import OurWorkUiUx from '../Screen/UIUXPage/OurWorkUiUx'
import UIDesign from '../Screen/UIUXPage/UIDesign'
import UiuxDesign from '../Screen/UIUXPage/UiuxDesign'
import UXDesign from '../Screen/UIUXPage/UXDesign'
import YourProject from '../Screen/AppPage/YourProject'

const UiuxPage = () => {
  useEffect(() => window.document.scrollingElement?.scrollTo(0, 0), [])

  // const {id} = useParams();
  // console.log(id)
  return (
  
   <Box>
    <UiuxDesign/>
    <YourProject/>
    <UXDesign/>
    <UIDesign/>
    <OurWorkUiUx/>
    <OurProcess/>
    <Contact/>
  
   </Box>
   
  )
}

export default UiuxPage