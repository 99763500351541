import Mail from "../../../Component/Image/Mail.svg";
import Phone from "../../../Component/Image/Phone.svg";
import Location from "../../../Component/Image/Location.svg";

//company information
export const Companyinfodata = [
  {
    title: "Hr Inquiry",
    href: "mailto:hr@equitysoft.in",
    image: Mail,
    value: "hr@equitysoft.in",
    media: Phone,
    numbertext: "+91 7984336071",
    href1: "tel:+91 7984336071",
  },
  {
    title: "Sales Inquiry",
    href: "mailto:business@equitysoft.in",
    image: Mail,
    value: "business@equitysoft.in",
    media: Phone,
    numbertext: "+91 94082 38343",
    href1: "tel:+919408238343",
    numbertext1: "+91 97263 40081",

    href2: "tel:+919726340081",
  },
  {
    title: "Locate Us",
    image: Location,
    value:
      "C-304-405 Ganesh Glory 11, SG Highway, Jagatpur Road, Ahmedabad, GJ India 382470",
    href: "https://www.google.com/maps/dir//Equitysoft+Technologies+Pvt.+Ltd.,+C+405,+Ganesh+Glory+11+Sarkhej+-+Gandhinagar+Highway,+Jagatpur+Rd,+Ahmedabad,+Gujarat+382470/@23.114166,72.5381015,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x395e83174e8dff25:0x9986b25f59688893!2m2!1d72.5402493!2d23.11408?entry=ttu",
  },
];

//company service

export const Companyservicedata = [
  {
    heading: "Help",
    data: [
      {
        value: "Contact us",
        navigate: "/contact-us",
      },
      {
        value: "Contact us",
        navigate: "/contact-us",
      },
      {
        value: "Contact us",
        navigate: "/contact-us",
      },
    ],
  },
];

/* {data?.map(() => (
          <Box onClick={() => navigator(navigate)}>
            <Typography
              component={Link}
              href={href}
              // target={blank}
              variant="body1"
              color="#DDDDDD"
              fontWeight="300"
              sx={{
                cursor: "pointer",
                textDecoration: "none",
                mb: 3,
                "&:hover": {
                  color: "#7AC0DA",
                },
              }}
            >
              {value}
            </Typography>
          </Box>
        ))} */
