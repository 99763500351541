import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import Contact from '../../Service/ServiceScreen/Contact'
import OurProcess from '../Screen/UIUXPage/OurProcess'
import Android from '../Screen/AppPage/Android'
import AppDevelopment from '../Screen/AppPage/AppDevelopment'
import Flutter from '../Screen/AppPage/Flutter'
import IOS from '../Screen/AppPage/IOS'
import OurWorkApp from '../Screen/AppPage/OurWorkApp'
import YourProject from '../Screen/AppPage/YourProject'
import Reactnativeapp from '../Screen/AppPage/Reactnativeapp'
import Pythonapp from '../Screen/AppPage/Pythonapp'

const ApppPage = () => {
  useEffect(() => window.document.scrollingElement?.scrollTo(0, 0), [])
  return (
   <Box>
    <AppDevelopment/>
    <YourProject/>
    <Flutter/>
    <IOS/>
    <Android/>
    <Reactnativeapp/>
    <Pythonapp/>

    
    <OurWorkApp/>
    <OurProcess/>
    <Contact/>

   </Box>
  )
}

export default ApppPage