import { Box, Container, Grid, Skeleton } from "@mui/material";
import React, { useState } from "react";
import Details from "../Screen/BlogDetailPage/Details";
import { SocialMedia } from "../Screen/BlogDetailPage/SocialMedia";
import Description from "../Screen/BlogDetailPage/Description";
import { useEffect } from "react";
import Contact from "../../Service/ServiceScreen/Contact";
import { useParams } from "react-router-dom";
import { getBlogById } from "../../../api/Blog";
import { API_URL } from "../../../api/config";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../Firebase";
import "react-lazy-load-image-component/src/effects/blur.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

export const BlogDetail = () => {
  // const [imageLoaded, setImageLoaded] = useState(false);

  // const handleImageLoad = () => {
  //   setImageLoaded(true);
  // };

  useEffect(() => {
    logEvent(analytics, "page_view", {
      page_location: window.location.href + window.location.search,
      page_path: window.location.href + window.location.search,
      page_title: "Blog Details Page",
    });
  }, []);

  useEffect(() => window.document.scrollingElement?.scrollTo(0, 0), []);

  const { id } = useParams();
  const [BlogData, setBlogData] = useState();
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    const getBlog = async () => {
      setisLoading(true);
      const data = await getBlogById(id);
      if (data?.s) setBlogData(data?.r);
      setisLoading(false);
    };

    getBlog();
  }, []);

  return (
    <>
      <Box>
        <Details blog={BlogData} />
        <Container
          sx={{
            maxWidth: { xs: "xs", sm: "md", md: "md", lg: "lg", xl: "lg" },
          }}
        >
          <Box
            sx={{
              mb: "2rem",
              height: "100%",
              width: "100%",
            }}
          >
            {isLoading ? (
              <Skeleton
                variant="rect"
                animation="wave"
                width="100%"
                height={800}
                sx={{ borderRadius: "30px" }}
              />
            ) : (
              <LazyLoadImage
                src={API_URL.blogImage + BlogData?.media}
                alt=""
                width="100%"
                loading="lazy"
                effect="blur"
                style={{ borderRadius: "30px", minHeight: 800, height: "100%" }}
              />
            )}
          </Box>
          {/* <img
              src={API_URL.blogImage + BlogData?.media}
              alt=""
              width="100%"
              height="100%"
            /> */}
          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "100%" }}>
              {" "}
              <Description blog={BlogData} />
            </Box>
            <Box item xs={1} md={2}>
              {" "}
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  position: "sticky",
                  top: "25%",
                  width: "100%",
                  pl: 3,
                }}
              >
                <SocialMedia />
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
      <Container
        sx={{ maxWidth: { xs: "xs", sm: "md", md: "md", lg: "lg", xl: "lg" } }}
      ></Container>
      <Contact />
    </>
  );
};

export default BlogDetail;
