import line01 from '../../../Component/Image/01.png';
import line02 from '../../../Component/Image/02.png';
import line03 from '../../../Component/Image/03.png';
import line04 from '../../../Component/Image/04.png';
import line05 from '../../../Component/Image/05.png';
import line11 from '../../../Component/Image/11.png';
import line12 from '../../../Component/Image/12.png';
import line13 from '../../../Component/Image/13.png';
import line14 from '../../../Component/Image/14.png';
import line15 from '../../../Component/Image/15.png';
import Photo from '../../../Component/Image/hardik sir.png';
import Photo2 from '../../../Component/Image/Mehulsir.png';
import Photo1 from '../../../Component/Image/parthsir 1 1.png';


//creative Data
export const creativeData = [
  {
    value: "Quality Delivery",
    subtitle:
      "With an expert panel of developers & designers, we want you to expect nothing less from Equitysoft.",
  },
  {
    value: "Transparency",
    subtitle:
      "Equitysoft adheres to a strict transparent process that enables our customers to watch their dreams shape in real time.",
  },
  {
    value: "Days Free Support",
    subtitle:
      "Following the deployment of the entire project build, Equitysoft guarantees you days of free support.",
  },
];

//TimeLine Data

export const TimelineData = [
  {
    number: "01",
    subtitle:
      "A humble beginning with just 3 dedicated individuals as we commenced operations",
    media: line01,
    value: "2016",
    top1: "0",
    left1: "-1%",
    Direction: "column",
    mb: 16,
  },
  {
    number: "02",
    subtitle:
      "A significant milestone was achieved as we successfully completed 50 projects.",
    media: line02,
    value: "2019",
    top2: "92%",
    left1: "-2%",
    top1: "60%",

    Direction: "column-reverse",
    mt: 14,
  },
  {
    number: "03",
    subtitle:
      " Embracing new technologies, our dynamic team expanded to 15 skilled individuals.",
    media: line03,
    value: "2021",
    top1: "0",
    left1: "-2%",
    Direction: "column",
    mb: 16,
  },
  {
    number: "04",
    subtitle:
      "Proudly inaugurated our second office, a significant step forward in our journey.",
    media: line04,
    value: "2022",
    top2: "92%",
    left1: "-2%",
    top1: "60%",
    Direction: "column-reverse",
    mt: 14,
  },
  {
    number: "05",
    subtitle:
      "Excitement soared as we crossed over 25 top-country clients, completing successful projects with each one.",
    media: line05,
    value: "2023",
    top1: "0",
    left1: "-2%",
    mb: 16,
    Direction: "column",
  },

];

//TimeLine Data ResponsiveView

export const TimelineResponsiveData = [
  {
    number: "01",
    subtitle:
      "A humble beginning with just 3 dedicated individuals as we commenced operations",
    media: line11,
    value: "2016",
    top1: "30%",
    left1: "56%",
    pl: 4.2,
    width: "250px",
    right:0
  },
  {
    number: "02",
    subtitle:
      "A significant milestone was achieved as we successfully completed 50 projects.",
    media: line12,
    value: "2019",
    top1: "35%",
    left1: "-1%",
    mr: 3.5,
    width: "100%",
    mt: 6,
    
  },
  {
    number: "03",
    subtitle:
      "Embracing new technologies, our dynamic team expanded to 15 skilled individuals.",
    media: line13,
    value: "2021",
    top1: "30%",
    left1: "56%",
    pl: 4.2,
    width: "250px",
    mt: 6,
    right:0
  },

  {
    number: "04",
    subtitle:
      "Proudly inaugurated our second office, a significant step forward in our journey.",
    media: line14,
    value: "2022",
    top1: "35%",
    left1: "-1%",
    mr: 3.5,
    width: "100%",
    mt: 6,
  },
  {
    number: "05",
    subtitle:
      "Excitement soared as we crossed over 25 top-country clients, completing successful projects with each one.",
    media: line15,
    value: "2023",
    top1: "30%",
    right:0,
    left1: "56%",
    pl: 4.2,
    width: "250px",
    mt: 6,
  },

];

//VisionariesData
export const visionariesData = [
  {
    image: Photo,
    value: "Hardik Rupareliya",
    subtitle: "Founder & MD of Equitysoft ",

    //Overview Hover data

    detail: `"We have been serving our unparalleled service for the last years. For vertical industries, we have built the solutions. We do not only guarantee results but satisfaction too! Our commitment to excellence and progress will guide us toward a brighter future, and we are excited to connect with new people on this journey. As a team, we are devoted to excellence and continuous improvement.”`,

    name: "Hardik Rupareliya",
  },
  {
    image: Photo1,
    value: "Parth Rajani",
    subtitle: "Founder & MD of Equitysoft",

    //Overview Hover data

    detail: `“We found Equitysoft to shape the future by empowering people through innovative technology. We believe in creating solutions that simplify lives, bring communities together, and positively impact the world. As a team, we strive to challenge ourselves, embrace change, and continuously push the boundaries of what is possible. Together, we will create a brighter tomorrow.”`,

    name: "Parth Rajani",
  },
  {
    image: Photo2,
    value: "Mehul rupavatiya",
    subtitle: "Founder & MD of Equitysoft",

    //Overview Hover data

    detail: `“At Equitysoft, we aim to catalyze progress and growth by delivering exceptional products and services that enrich people's lives. We are driven by a passion for excellence and a commitment to our customers, employees, and communities. With determination and a focus on innovation, we will continue to be a leader in our industry, creating opportunities for growth and positively impacting the world.”`,

    name: "Mehul rupavatiya",
  },
];
