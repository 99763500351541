import { API_GET, API_URL } from "./config";

export const getAllCaseStudy = (category) => {
  const q = `?category_id=${category}`;
  const data = API_GET(API_URL.getAllCasestudy + q);
  return data;
};

export const getCaseStudyById = (id) => {
  const data = API_GET(API_URL.getCasestudyById + id);

  return data;
};
export const gethighlights = (id) => {
  const data = API_GET(API_URL.gethighlightsAll + id);
  return data;
};
