// import copy from "clipboard-copy";
import moment from "moment";
// import { useNavigate, useParams } from "react-router-dom";

// import Send from "../../Image/Send.svg";
// import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
// import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Box, Button, Card, Typography } from "@mui/material";
import { updateBlogViewCount } from "../api/Blog";
import { API_URL } from "../api/config";

export function Blogview({ data, url, id }) {
  // const [copied, setCopied] = useState(false);

  // const handleCopy = async (id) => {
  //   // await copy(window.location.href);
  //   await copy(`https://equitysoft.in/blog-details/${id}`);
  //   setCopied(true);
  // };
  const navigator = () => {
    window.open(url);
  };
  // const hash_tags = data?.hash_tags && data?.hash_tags.split(",");
  // const [open, setOpen] = React.useState(false);

  // const handleTooltipClose = () => {
  //   setOpen(false);
  // };

  // const handleTooltipOpen = () => {
  //   setOpen(true);
  // };

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transition: "all 0.5s ease 0s",
        height: "100%",
        ":hover": {
          scale: "0.99",
        },
      }}
    >
      <Card
        className="view"
        sx={{
          borderRadius: "12px",
          height: "100%",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "5%",
            right: "0px",
            zIndex: "2",
          }}
        >
          <Button
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "center",
              backgroundColor: "#fff",
              color: "#000",
              textTransform: "none",
              borderRadius: "8px 0px 0px 8px",
              boxShadow: 6,
              px: 1.5,
              py: 0.4,
              "&:hover": {
                backgroundColor: "#fff",
              },
            }}
          >
            <RemoveRedEyeIcon fontSize="small" color="#484848" />

            {data?.view_count}
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={async () => {
            await updateBlogViewCount(data?.id);
            navigator((url = `/blog-details/${data?.id}`));
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",

              zIndex: 1,
            }}
          >
            <img
              src={API_URL.blogImage + data?.media}
              alt=""
              style={{
                width: "100%",
                objectPosition: "top",
                objectFit: "cover",
                height: "200px",
              }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            py: 1,
            px: 2,
            my: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              gap: 3,
              mb: 2,
            }}
          >
            <Typography
              sx={{
                border: "1px solid #20B69F",
                px: 1.5,
                width: "fit-content",
                borderRadius: "16px",
                fontSize: "14px !important",
                fontWeight: "bold",
                color: "#20B69F",
                marginTop: "auto",
              }}
            >
              {data?.category_name}
            </Typography>
            <Typography
              sx={{
                fontSize: "16px !important",
                fontWeight: "bold",
                color: "#20B69F",
                opacity: "0.7",
              }}
            >
              {moment(data.created_at).format("MMM YYYY")}
            </Typography>
          </Box>
          <Typography
            color="#252525"
            sx={{
              fontWeight: "900",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textTransform: "capitalize",
              fontSize: "18px !important",
              transition: "all 0.3s",
              cursor: "pointer",
              mb: 2,
              ":hover": {
                color: "#20B69F",
              },
            }}
            onClick={async () => {
              await updateBlogViewCount(data?.id);
              navigator((url = `/blog-details/${data?.id}`));
            }}
          >
            {data?.title && data?.title.slice(0, 70)}
            {data?.title.length >= 70 && "..."}
          </Typography>

          <Typography
            color="#484848"
            fontWeight="500"
            sx={{
              fontSize: "16px !important",
            }}
          >
            {data?.description && data?.description.slice(0, 80)}
            {data?.description.length >= 80 && "..."}
          </Typography>
        </Box>
      </Card>
    </Box>

    // <Box
    //   className="hovereffect"
    //   sx={{
    //     position: "relative",
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //     transition: "all 0.5s ease 0s",
    //     height: "100%",
    //   }}
    // >
    //   <Card
    //     className="view"
    //     sx={{
    //       borderRadius: "12px",
    //       height: "100%",
    //       "&:hover": {
    //         backgroundColor: "#E0F6F9",
    //       },
    //     }}
    //   >
    //     <Box
    //       sx={{
    //         position: "absolute",
    //         top: "8%",
    //         right: "5%",
    //         zIndex: "2",
    //       }}
    //     >
    //       <Button
    //         sx={{
    //           backgroundColor: "#fff",
    //           color: "#000",
    //           textTransform: "none",
    //           borderRadius: "50px",
    //           px: 1.5,
    //           py: 0.4,
    //           "&:hover": {
    //             backgroundColor: "#fff",
    //           },
    //         }}
    //       >
    //         {/* June-22 */}
    //         {moment(data?.create_at).format("MMM-YY")}
    //       </Button>
    //     </Box>
    //     <Box
    //       sx={{
    //         display: "flex",
    //         justifyContent: "center",
    //         width: "100%",
    //       }}
    //     >
    //       <Box
    //         sx={{
    //           width: "100%",
    //           height: "100%",

    //           zIndex: 1,
    //         }}
    //       >
    //         <img
    //           src={API_URL.blogImage + data?.media}
    //           alt=""
    //           style={{
    //             width: "100%",
    //             aspectRatio: "540 /400",
    //             objectPosition: "top",
    //             objectFit: "cover",
    //           }}
    //           height="auto"
    //         />
    //         <Box
    //           title="Copy Link"
    //           sx={{
    //             position: "absolute",
    //             mt: "-1.5rem",
    //             right: "5%",
    //             // zIndex: "-1",
    //             cursor: "pointer",
    //           }}
    //         >
    //           <Tooltip
    //             componentsProps={{
    //               tooltip: {
    //                 sx: {
    //                   backgroundColor: "#295768",
    //                   padding: "8px",
    //                   fontSize: "0.8rem",
    //                   "& .MuiTooltip-arrow": {
    //                     color: "#295768",
    //                   },
    //                 },
    //               },
    //             }}
    //             PopperProps={{
    //               disablePortal: true,
    //             }}
    //             onMouseLeave={handleTooltipClose}
    //             open={open}
    //             disableFocusListener
    //             disableHoverListener
    //             disableTouchListener
    //             title={copied ? "Copied!" : "Copy URL"}
    //             placement="left"
    //             arrow
    //           >
    //             <Box
    //               onClick={handleTooltipOpen}
    //               sx={{
    //                 backgroundColor: "#fff",
    //                 p: 1.1,
    //                 borderRadius: "100px",
    //                 display: "flex",
    //                 alignItems: "center",
    //                 boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.35);",
    //               }}
    //             >
    //               <FileCopyOutlinedIcon
    //                 color="five"
    //                 onClick={() => handleCopy(data.id)}
    //               />
    //             </Box>
    //           </Tooltip>
    //         </Box>
    //       </Box>
    //     </Box>

    //     <Box
    //       sx={{
    //         p: 1,
    //         mb: 7,
    //       }}
    //     >
    //       <Typography
    //         variant="body1"
    //         color="#252525"
    //         component="h1"
    //         sx={{
    //           fontWeight: "900",
    //           overflow: "hidden",
    //           textOverflow: "ellipsis",
    //           textTransform: "capitalize",
    //         }}
    //       >
    //         {/* {data?.title} */}
    //         {data?.title && data?.title.slice(0, 32)}
    //         {data?.title.length >= 32 && "..."}
    //       </Typography>
    //       {/* <Typography variant="body1" color="#484848" fontWeight="500">
    //         {hash_tags && hash_tags.map((item) => `#${item} `)}
    //       </Typography> */}
    //       <Typography variant="body1" color="#484848" fontWeight="500">
    //         {data?.description && data?.description.slice(0, 32)}
    //         {data?.description.length >= 32 && "..."}
    //       </Typography>
    //     </Box>

    //     <Box
    //       sx={{
    //         position: "absolute",
    //         bottom: "0",
    //         mb: "1.2rem",
    //         display: "flex",
    //         width: "100%",
    //         justifyContent: "space-between",
    //         zIndex: "3",
    //         alignSelf: "center",
    //       }}
    //     >
    //       <Box
    //         sx={{
    //           display: "flex",
    //           alignItems: "center",
    //           verticalAlign: "bottom",
    //         }}
    //       >
    //         <Box
    //           component="i"
    //           sx={{
    //             color: "#484848",
    //             px: 1,
    //             display: "flex",
    //             alignItems: "center",
    //           }}
    //         >
    //           {/* <img src={View} alt="" /> */}
    //           <VisibilityOutlinedIcon fontSize="small" />
    //         </Box>

    //         <Typography variant="body2" color="#484848" component="h6">
    //           {data?.view_count}
    //         </Typography>
    //       </Box>

    //       <Box
    //         onClick={async () => {
    //           await updateBlogViewCount(data?.id);
    //           navigator((url = `/blog-details/${data?.id}`));
    //         }}
    //         sx={{
    //           display: "flex",
    //           alignItems: "center",
    //           justifyContent: "center",
    //           cursor: "pointer",
    //         }}
    //       >
    //         <Typography
    //           variant="body2"
    //           color="#0E3347"
    //           component="h6"
    //           fontWeight="700"
    //         >
    //           Read More
    //         </Typography>
    //         <Box
    //           component="i"
    //           sx={{
    //             color: "#0E3347",
    //             px: 1,
    //             display: "flex",
    //             alignItems: "end",
    //           }}
    //         >
    //           <ArrowForwardIcon fontSize="small" />
    //         </Box>
    //       </Box>
    //     </Box>
    //   </Card>
    // </Box>
  );
}
