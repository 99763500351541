import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import Contact from '../../Service/ServiceScreen/Contact'
import YourProject from '../Screen/AppPage/YourProject'
import Digitalmarketing from '../Screen/DigitalmarketingPage/Digitalmarketing'
import GoogleMarketing from '../Screen/DigitalmarketingPage/GoogleMarketing'
import SearchEngine from '../Screen/DigitalmarketingPage/SearchEngine'
import Socialmedia from '../Screen/DigitalmarketingPage/Socialmedia'
import OurWorkDigital from '../Screen/DigitalmarketingPage/OurWorkDigital'

const DigitalmarketingPage = () => {
  useEffect(() => window.document.scrollingElement?.scrollTo(0, 0), [])
  return (
   <Box>
    <Digitalmarketing/>
    <YourProject/>
    <Socialmedia/>
    <GoogleMarketing/>
    <SearchEngine/>
    <OurWorkDigital/>
    <Contact/>
   </Box>
  )
}

export default DigitalmarketingPage

