import { Box} from "@mui/material";
import FIREBASE1 from "../../../Image/FIREBASE1.png";


import ChildBanner from "../../CommonSection/ChildBanner";

const Firebase = () => {
  return (
    <Box>
      <ChildBanner
        value={"Database Management"}
        title={"Firebase"}
        subtitle={
          "Embrace Firebase as your backend platform, providing real-time data synchronization and effortless scalability. Our Firebase solutions enable you to build feature-rich applications quickly and efficiently."
        }
        subtitle1={
          "With its authentication, cloud storage, and hosting capabilities, you can focus on delivering engaging user experiences while we handle the backend intricacies."
        }
        image={FIREBASE1}
      
        none1={"none"}
        ex={{pr:{xs:"0",md:4,xl:6}}}
        aos={"fade-down-left"}
        i={"row"}
      />
    </Box>
  )
}

export default Firebase

