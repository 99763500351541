
import { Box} from "@mui/material";
import DATAArchitecture from "../../../Image/DATAArchitecture.png";

import React from "../../../Image/React.png";
import ChildBanner from "../../CommonSection/ChildBanner";

const Architecture = () => {
  return (
    <Box>
      <ChildBanner
        value={"Database Architecture"}
        title={"Database Architecture "}
        subtitle={
          "Unlock the foundation of a robust digital presence with our expert database architecture. We design data structures that power your applications efficiently. Our solutions are tailored to your unique needs, ensuring scalability, security, and high performance."
        }
        subtitle1={
          "With our database architecture, your data becomes a strategic asset, enhancing your ability to make informed decisions and provide exceptional user experiences."
        }
        image={DATAArchitecture}
        none1={"none"}
        ex={{pr:{xs:"0",md:4,xl:6}}}
        aos={"fade-down-left"}
        i={"row"}
      />
    </Box>
  )
}

export default Architecture

